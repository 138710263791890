import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FilterTableHead from '../../common/FilterTableHead';
import classnames from 'classnames';
import { formatCurrency, snakeCase } from '../../../helpers/helpers';
import { StyledTableSelector } from '../../common/StyledTableSelector';
import { Checkbox } from '@increase/typed-components';

function Row({
  rowData,
  disabled,
  selected,
  fieldNames,
  onSelectChange,
  showConciliatedDateColumn,
}) {
  const isConciliated = !!rowData.conciliationDate;
  const onChange = (e) => {
    if (!isConciliated && !disabled) {
      onSelectChange(rowData, e.target.checked);
    }
  };
  return (
    <tr
      className={classnames({
        disabled,
        selected,
        conciliated: isConciliated,
      })}
    >
      <td>
        <Checkbox checked={disabled || selected} onChange={onChange} />
      </td>
      {fieldNames.map((fieldName) => {
        if (fieldName === 'totalEarn') {
          return (
            <td key={fieldName}>
              {formatCurrency(
                rowData[fieldName].amount / 100,
                rowData[fieldName].currency
              )}
            </td>
          );
        }
        return <td key={fieldName}>{rowData[fieldName]}</td>;
      })}
      {showConciliatedDateColumn && <td>{rowData.conciliationDate}</td>}
    </tr>
  );
}

Row.propTypes = {
  disabled: PropTypes.bool,
  fieldNames: PropTypes.arrayOf(PropTypes.string),
  onSelectChange: PropTypes.func,
  rowData: PropTypes.object,
  selected: PropTypes.bool,
  showConciliatedDateColumn: PropTypes.bool,
};

function PendingRecordsTable({
  data,
  loading,
  filters,
  headers,
  matchedItems,
  onFilterChange,
  onSelectChange,
  selectedItems,
  showConciliatedDateColumn,
  tableType,
  vsCard,
}) {
  const headerKeys = Object.keys(headers);
  const allChecked =
    data.length > 0 &&
    data.every((r) => selectedItems.find((si) => si.id === r.id));
  const { t } = useTranslation();
  const getTranslation = (headerKey) => {
    if (tableType === 'file') {
      return vsCard
        ? t(`constants:increasecard_fields.${headers[headerKey]}`)
        : headers[headerKey];
    }
    return t(`constants:increasecard_fields.${snakeCase(headerKey)}`);
  };
  return (
    <StyledTableSelector loading={loading}>
      <thead>
        <tr>
          <th>
            <Checkbox
              checked={allChecked}
              onChange={(e) => onSelectChange(data, e.target.checked)}
            />
          </th>
          {headerKeys.map((headerKey, i) => {
            if (headerKey) {
              return (
                <FilterTableHead
                  filterValue={filters[headerKey]}
                  key={i}
                  onFilter={(value) => onFilterChange(headerKey, value)}
                >
                  {getTranslation(headerKey)}
                </FilterTableHead>
              );
            }
            return <th key={i}> {headers[headerKey]} </th>;
          })}
          {showConciliatedDateColumn && <th>{t('conciliation_date')}</th>}
        </tr>
      </thead>

      <tbody>
        {data.map((row, i) => {
          return (
            <Row
              disabled={!!matchedItems.find((i) => i.id === row.id)}
              fieldNames={headerKeys}
              key={i}
              onSelectChange={onSelectChange}
              rowData={row}
              selected={!!selectedItems.find((i) => i.id === row.id)}
              showConciliatedDateColumn={showConciliatedDateColumn}
            />
          );
        })}
      </tbody>
    </StyledTableSelector>
  );
}

PendingRecordsTable.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.object,
  headers: PropTypes.object,
  matchedItems: PropTypes.array,
  onFilterChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  selectedItems: PropTypes.array,
  showConciliatedDateColumn: PropTypes.bool,
  tableType: PropTypes.oneOf(['file', 'movements', 'payments']),
  vsCard: PropTypes.bool,
};

export default PendingRecordsTable;
