import { getUploadedFiles } from '../services/uploadedFilesService';

const INITIAL_STATE = {
  filters: {
    filename: '',
  },
  uploadedFiles: [],
  currentPage: 1,
  pages: 1,
  fetching: false,
};

export function fetchUploadedFiles() {
  return (dispatch, getState) => {
    dispatch({ type: 'UPLOADED_FILES_REQUEST' });
    const { currentPage, filters } = getState().uploadedFiles;
    const profileId = getState().profiles.profileId;

    return getUploadedFiles(currentPage, profileId, filters).then(
      ({ uploadedFiles, pages }) => {
        dispatch({
          type: 'UPLOADED_FILES_FETCHED',
          uploadedFiles: uploadedFiles,
          pages: pages,
        });
      }
    );
  };
}

const uploadedFilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, currentPage: action.page };
    case 'SET_FILTERS':
      return { ...state, filters: action.filters };
    case 'UPLOADED_FILES_REQUEST':
      return { ...state, fetching: true };
    case 'UPLOADED_FILES_FETCHED':
      return {
        ...state,
        uploadedFiles: action.uploadedFiles,
        fetching: false,
        pages: action.pages,
      };
    default:
      return state;
  }
};

export default uploadedFilesReducer;
