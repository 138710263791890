import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import MultiStep from './MultiStep';
import { FloatNotification } from '../../common/Notification';
import InitialConfig from './InitialConfig';
import MatchFields from './MatchFields';
import DataPoolConfig from './DataPoolConfig';
import MatchRules from './MatchRules';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import {
  selectProfile,
  profileSave,
  profileCreate,
} from '../../../reducers/profilesReducer';
import { STRUCTURE_KEYS } from '../../../reducers/structuresReducer';
import { DATA_POOL_KEYS } from '../../../reducers/dataPoolsReducer';

class ProfileCreator extends React.Component {
  state = {
    saveFailed: false,
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (!this.isNewProfile) {
      dispatch(selectProfile(Number(match.params.profileId)));
    }
    return dispatch(fetchStructureMetadata());
  }

  isNewProfile = this.props.match.path.includes('new');

  submitForm() {
    const { dispatch, match, history } = this.props;
    let savePromise;
    if (match.path === '/profiles/new') {
      savePromise = dispatch(profileCreate()).then((profile) => {
        history.push(`/profiles/${profile.id}/newconciliation`);
      });
    } else {
      savePromise = dispatch(profileSave()).then((profile) => {
        history.push(`/profiles/${profile.id}/newconciliation`);
      });
    }

    savePromise.catch(() => {
      this.setState({ saveFailed: true });
    });
  }

  handleExit() {
    const { dispatch, match } = this.props;
    if (this.isNewProfile) {
      this.props.history.goBack();
    } else {
      dispatch(selectProfile(Number(match.params.profileId), true));
      this.props.history.goBack();
    }
  }

  render() {
    const {
      dataPoolAName,
      dataPoolBName,
      fetching,
      onFinish,
      onStepChange,
      page,
      valid,
      t,
      vsCard,
    } = this.props;

    return (
      <React.Fragment>
        <MultiStep
          closeLabel={t('exit')}
          finishForm={onFinish}
          loading={fetching}
          onClose={() => this.handleExit()}
          onStepChange={onStepChange}
          page={page}
          stepList={[
            {
              label: `${t('step')} 1`,
              text: t('profile_creator.initial_configuration.header'),
            },
            {
              label: `${t('step')} 2`,
              text: t('profile_creator.select_fields'),
            },
            {
              label: `${t('step')} 3`,
              text: t('profile_creator.data_pool_config', {
                dataPoolName: dataPoolAName || t('data_pool_a'),
              }),
            },
            {
              label: `${t('step')} 4`,
              text: t('profile_creator.data_pool_config', {
                dataPoolName: dataPoolBName || t('data_pool_b'),
              }),
              disabled: vsCard,
            },
            {
              label: `${t('step')} 5`,
              text: t('profile_creator.define_match_rules'),
            },
          ]}
          submitForm={this.submitForm.bind(this)}
          t={t}
          valid={valid}
        >
          <MultiStep.Page>
            <InitialConfig />
          </MultiStep.Page>
          <MultiStep.Page>
            <MatchFields />
          </MultiStep.Page>
          <MultiStep.Page>
            <DataPoolConfig
              dataPoolKey={DATA_POOL_KEYS.DATA_POOL_A}
              structuresKey={STRUCTURE_KEYS.STRUCTURES_A}
            />
          </MultiStep.Page>
          <MultiStep.Page>
            <DataPoolConfig
              dataPoolKey={DATA_POOL_KEYS.DATA_POOL_B}
              structuresKey={STRUCTURE_KEYS.STRUCTURES_B}
            />
          </MultiStep.Page>
          <MultiStep.Page>
            <MatchRules />
          </MultiStep.Page>
        </MultiStep>
        <FloatNotification
          message={t('errors.interno')}
          onClose={() => this.setState({ saveFailed: false })}
          placement={{ left: '50%', bottom: 'calc(90px + 1rem)' }}
          type="error"
          visible={this.state.saveFailed}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onFinish: () => {
    return ownProps.history.push('/profiles');
  },
  onValidateError: () => {
    return dispatch({ type: 'VALIDATE_ERROR' });
  },
});

function mapStateToProps(fullState) {
  const state = fullState.profiles;
  const user = fullState.auth.user;
  const {
    dataPoolA: { name: dataPoolAName },
    dataPoolB: { name: dataPoolBName },
  } = fullState.dataPools;

  return {
    fetching: state.fetching,
    vsCard: state.vsCard,
    user: user,
    dataPoolAName,
    dataPoolBName,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProfileCreator));
