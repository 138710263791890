import i18n from '../i18n';
import { addIfNumber, formatDate } from '../helpers/helpers';
import parseJSON from 'date-fns/parseJSON';
export default class Report {
  constructor({
    id,
    name,
    status,
    creationDate,
    lastDownloadAt,
    error,
    reconciliationType,
    masqueraded,
    conciliationResults = {},
    reportFileList = [],
    filesErrorsList = [],
    deleted,
    deletedAt,
  }) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.creationDate = formatDate(parseJSON(creationDate));
    this.lastDownloadAt = lastDownloadAt;
    this.error = error;
    this.conciliationResults = conciliationResults;
    this.reconciliationType = reconciliationType;
    this.masqueraded = masqueraded;
    this.reportFileList = reportFileList;
    this.filesErrorsList = filesErrorsList;
    this.deleted = deleted;
    this.deletedAt = deletedAt;
  }

  static STATUS = {
    PENDING: 0,
    CONCILIATED: 1,
    FAILED: 2,
  };

  static RECONCILIATION_TYPES = {
    SIMULATED: 'simulated',
    RECONCILIATION: 'reconciliation',
    RECONCILIATION_RETRY: 'reconciliation_retry',
    DEPRECATION: 'deprecation',
    MANUAL_CONCILIATED: 'manual_conciliated',
  };

  static fromAPI({
    id,
    name,
    status,
    error,
    conciliation_type,
    simulated,
    masqueraded,
    creation_date: creationDate,
    last_download_at: lastDownloadAt,
    results,
    a_report_details: reportDetailA,
    b_report_details: reportDetailB,
    a_files_errors: errorsA,
    b_files_errors: errorsB,
    deleted_at: deletedAt,
  }) {
    const conciliationResults = conciliationResultsFromAPI(status, results);
    const reportError = reportErrorFromAPI(error);
    const reportFileList = reportDetailFromAPI(reportDetailA, reportDetailB);
    const filesErrorsList = errorsDetailsFromApi(errorsA, errorsB);
    const reconciliationType = simulated ? 'simulated' : conciliation_type;
    const deleted = !!deletedAt;
    const parsedName = name.replace(id, '').replace('[]', '').trim();
    return new Report({
      id,
      name: parsedName,
      status,
      creationDate,
      lastDownloadAt,
      reconciliationType,
      masqueraded,
      error: reportError,
      conciliationResults,
      reportFileList,
      filesErrorsList,
      deleted,
      deletedAt,
    });

    function computeConcilationResults(datapoolResults) {
      if (!datapoolResults) return null;
      const {
        deprecated,
        pending,
        conciliated,
        scannings,
        structures,
      } = datapoolResults;

      const totalLines = conciliated + deprecated + pending;
      return {
        totalLines,
        conciliated,
        scannings: scanningsFromAPI(scannings, totalLines),
        deprecated,
        pending,
        nonConciliated: deprecated + pending,
        conciliatedPercentage: ((conciliated * 100) / totalLines).toFixed(1),

        structures,
      };
    }

    function conciliationResultsFromAPI(status, { data_pool_a, data_pool_b }) {
      if (status === Report.STATUS.CONCILIATED) {
        return {
          dataPoolA: computeConcilationResults(data_pool_a),
          dataPoolB: computeConcilationResults(data_pool_b),
        };
      }
      return {
        processedLines: 0,
        scannings: [],
        deprecatedLines: 0,
      };
    }

    function reportDetailFromAPI(reportsA = [], reportsB = []) {
      return [
        ...reportsA.map((r) => {
          return {
            dataPool: 'data_pool_a',
            id: r.id,
            name: r.name,
            lastDownloadAt: r.last_download_at
              ? formatDate(parseJSON(r.last_download_at))
              : null,
          };
        }),
        ...reportsB.map((r) => {
          return {
            dataPool: 'data_pool_b',
            id: r.id,
            name: r.name,
            lastDownloadAt: r.last_download_at
              ? formatDate(parseJSON(r.last_download_at))
              : null,
          };
        }),
      ];
    }

    function errorsDetailsFromApi(errorsA = [], errorsB = []) {
      return [
        ...errorsA.map((e) => {
          return {
            dataPool: 'data_pool_a',
            id: e.id,
            name: e.name,
            structureId: e.structure_id,
            structureName: e.structure_name,
            error: e.error,
          };
        }),
        ...errorsB.map((e) => {
          return {
            dataPool: 'data_pool_b',
            id: e.id,
            name: e.name,
            structureId: e.structure_id,
            structureName: e.structure_name,
            error: e.error,
          };
        }),
      ];
    }

    function scanningsFromAPI(scannings, totalLines) {
      return Object.entries(scannings).map(
        ([scanningNumber, reconciliedLines]) => {
          return {
            scanningNumber,
            reconciliedLines,
            conciliatedPercentage: (
              (reconciliedLines * 100) /
              totalLines
            ).toFixed(1),
          };
        }
      );
    }

    function reportErrorFromAPI(error) {
      if (error && error.type === 'structure') {
        error.location = addIfNumber(error.location, 1);
        error.line = addIfNumber(error.line, 1);
        return i18n.t(`errors.structure.${error.code}`, { error: error });
      } else if (error) {
        return i18n.t('errors.structure.generic');
      }
      return error;
    }
  }

  finished() {
    return this.status === Report.STATUS.CONCILIATED;
  }

  failed() {
    return this.status === Report.STATUS.FAILED;
  }

  pending() {
    return (
      this.status === Report.STATUS.PENDING ||
      this.status === Report.STATUS.RECONCILING
    );
  }
}
