import React, { useState, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import UploadDragZone from '../../common/FileUpload/UploadDragZone';
import { H1 } from '../../common/Typography';
import { DATA_POOL_KEYS } from './../../../reducers/dataPoolsReducer';
import { EmptyState } from '../../common/EmptyState';
import CruceImg from '../../../img/cruce.svg';
import {
  clearFileUpload,
  uploadFile,
  retryUpload,
  fileTypeError,
  removeFile,
  setFileStructure,
  notifyUpload,
} from './../../../reducers/fileUploaderReducer';

import {
  selectProfile,
  fetchProfile,
  checkConciliationStatus,
} from './../../../reducers/profilesReducer';
import { Checkbox, Modal, Button } from '@increase/typed-components';
import { EVENTS } from '../../../actionTracker';
import { useEventTracker } from '@increasecard/increase-core';
import { FilesTable } from './FilesTable';

const FixedWrapper = styled.div`
  position: fixed;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  & > div:first-child {
    width: calc(100% - 260px);
  }
`;

const Sidebar = styled.div`
  margin: 3.5rem 1rem;
`;

const Simulate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  .info {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 19px;
    width: 19px;
    height: 19px;
    margin-left: 0.5rem;
    text-align: center;
    border-radius: 100%;
    background-color: #d8d8d8;
  }
`;

function NewConciliation(props) {
  const [simulated, setSimulated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const eventTracker = useEventTracker();
  const {
    clearState,
    match: {
      params: { profileId },
    },
    onProfileSelection,
    onCheckConciliationStatus,
  } = props;

  useEffect(() => {
    clearState();
    onProfileSelection(Number(profileId));
    onCheckConciliationStatus(Number(profileId));
  }, [profileId, clearState, onProfileSelection, onCheckConciliationStatus]);

  const isConciliationDisabled = () => {
    const { files } = props;
    // At least one datapool should be filled
    const areFilesConfiguired = files.every((f) => f.structureId && f.s3Key);
    return !areFilesConfiguired || props.uploadedFilesCount === 0;
  };

  const startConciliation = () => {
    props.onConciliation(simulated).then(() => setShowModal(true));
  };

  const okModal = () => {
    const { history, match, clearState } = props;
    eventTracker.track(EVENTS.RECONCILIATION, { 'is Simulated': simulated });
    setShowModal(false);
    // TODO: redirect AFTER modal hides
    clearState();
    history.push(`/profiles/${match.params.profileId}/reports`);
  };

  const hideModal = () => setShowModal(false);

  const {
    fetching,
    files,
    lockedSimulate,
    onDropAccepted,
    onDropRejected,
    onFileRemove,
    onRetry,
    onSetStructure,
    structureList,
    dataPoolList,
    vsCard,
    isProfileMatching,
    t,
  } = props;
  return (
    <React.Fragment>
      {isProfileMatching && (
        <EmptyState description={t('matching_on_course')}>
          <img alt="icono" src={CruceImg} />
        </EmptyState>
      )}
      <H1>{t('conciliate_files')}</H1>
      <FlexContainer>
        <UploadDragZone
          files={files}
          minHeight={400}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          showLoader={fetching}
        >
          <FilesTable
            dataPoolList={dataPoolList}
            fetching={fetching}
            files={files}
            onFileRemove={onFileRemove}
            onRetry={onRetry}
            onSetStructure={onSetStructure}
            structureList={structureList}
            vsCard={vsCard}
          />
        </UploadDragZone>
        <Sidebar>
          <FixedWrapper>
            <Button
              disabled={isConciliationDisabled()}
              onClick={startConciliation}
            >
              {t('start_conciliation')}
            </Button>
            <Simulate>
              <Checkbox
                checked={simulated}
                disabled={lockedSimulate}
                id="simulated-checkbox"
                label={t('perform_simulated_conciliation')}
                onChange={(e) => setSimulated(e.target.checked)}
              />
              <span className="info" data-tip={t('simulated_tooltip')}>
                ?
              </span>
            </Simulate>
          </FixedWrapper>
        </Sidebar>
      </FlexContainer>
      <Modal
        customFooter={<Button onClick={okModal}>{t('ok')}</Button>}
        description={t('conciliating_description')}
        fullWidthActionButtons
        headerText={t('conciliating')}
        onClose={hideModal}
        visible={showModal}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  clearState: () => {
    dispatch(clearFileUpload());
    return dispatch({ type: 'SET_SIMULATE', simulate: false });
  },
  onRetry: (file) => dispatch(retryUpload(file)),
  onDropAccepted: (files) => {
    files.forEach((file) => {
      dispatch(uploadFile(file));
    });
  },
  onDropRejected: (files) => {
    files.forEach((file) => {
      dispatch(fileTypeError(file));
    });
  },
  onFileRemove: (fileName) => {
    return dispatch(removeFile(fileName));
  },
  onProfileSelection: (profileId) => {
    dispatch(clearFileUpload());
    return dispatch(selectProfile(profileId));
  },
  onSetStructure: (fileName, dataPool, structureId) => {
    return dispatch(setFileStructure(fileName, dataPool, structureId));
  },
  onConciliation: (simulated) => {
    return dispatch(notifyUpload(simulated));
  },
  onFetchProfile(profileId) {
    return dispatch(fetchProfile(profileId));
  },
  onCheckConciliationStatus(profileId) {
    return dispatch(checkConciliationStatus(profileId));
  },
});

function mapStateToProps(fullState) {
  const { files, uploadedFilesCount } = fullState.fileUploader;
  const { dataPoolA, dataPoolB } = fullState.dataPools;
  const { structuresA, structuresB } = fullState.structures;
  const {
    profileId,
    profile,
    disabledPage,
    fetching,
    vsCard,
    isProfileMatching,
  } = fullState.profiles;
  const dataPoolList = [
    { name: dataPoolA.name, value: DATA_POOL_KEYS.DATA_POOL_A },
    { name: dataPoolB.name, value: DATA_POOL_KEYS.DATA_POOL_B },
  ];
  const structureList = {
    [DATA_POOL_KEYS.DATA_POOL_A]: structuresA.map(({ name, id: value }) => ({
      name,
      value,
    })),
    [DATA_POOL_KEYS.DATA_POOL_B]: structuresB.map(({ name, id: value }) => ({
      name,
      value,
    })),
  };

  return {
    files: Object.values(files),
    structureList,
    dataPoolList,
    uploadedFilesCount,
    profileId: profileId,
    currentProfile: profile,
    disabledPage: disabledPage,
    vsCard,
    fetching,
    isProfileMatching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NewConciliation));
