import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputLabel } from './Typography';

const Wrapper = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '218px')};
`;

const StyledInput = styled.input`
  font-family: Inter;
  font-size: 0.825rem;
  line-height: 16px;
  letter-spacing: -0.003em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  /* IncreaseBlack */
  color: #182026;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 11px 3px 11px 7px;
  outline: none;
  width: 100%;
  margin-top: ${({ hasLabel }) => (hasLabel ? '6px' : '0')};
  :focus {
    border: 1px solid #182026;
  }
`;

class TextInput extends React.Component {
  handleChange(event) {
    const value = event.target.value;
    this.props.onChange(value);
  }

  render() {
    const { disabled, fullWidth, label, placeholder, value } = this.props;
    return (
      <Wrapper fullWidth={fullWidth}>
        {label && <InputLabel>{label}</InputLabel>}
        <StyledInput
          disabled={disabled}
          hasLabel={!!label}
          onChange={this.handleChange.bind(this)}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      </Wrapper>
    );
  }
}

TextInput.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextInput;
