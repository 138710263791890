import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from './NumberInput';

function PercentageInput({ dataType, threshold, onChange, disabled }) {
  switch (dataType) {
    case 'money':
      return (
        <NumberInput
          className="range money"
          disabled={disabled}
          fixed={2}
          max={100}
          min={0}
          onChange={(newValue) => onChange('threshold', newValue)}
          prefix="%"
          showButtons={!disabled}
          step={1}
          value={threshold || 0}
        />
      );
    case 'integer':
    case 'long':
      return (
        <NumberInput
          className="range money"
          disabled={disabled}
          fixed={0}
          min={0}
          onChange={(newValue) => onChange('threshold', newValue)}
          prefix="%"
          showButtons={!disabled}
          step={1}
          value={threshold || 0}
        />
      );
    default:
      return null;
  }
}

PercentageInput.propTypes = {
  dataType: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  threshold: PropTypes.number,
};

export default PercentageInput;
