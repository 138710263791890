export default class Scanning {
  constructor({ id, number, scanningFields = [] }) {
    this.id = id;
    this.number = number;
    this.scanningFields = scanningFields;
  }

  static fromAPI(
    { id, number, scanning_fields: scanningFields },
    abstractFieldsA
  ) {
    const fields = scanningsFieldsFromAPI(scanningFields, abstractFieldsA);
    return {
      id,
      number,
      scanningFields: fields,
    };

    //Build scannings fields from DataPoolA abstract fields
    function scanningsFieldsFromAPI(scanningFields, abstractFields) {
      const scannFieldsObj = {};

      scanningFields.forEach((scannField) => {
        const absField = Object.entries(abstractFields).find(
          (abstractField) => {
            return abstractField[1].id === scannField.field_a.id;
          }
        );
        scannFieldsObj[absField[0]] = buildScanningField(scannField);
      });
      return scannFieldsObj;
    }

    function buildScanningField(scannField) {
      const threshold =
        scannField.comparation_type === 'range' &&
        scannField.field_a.data_type === 'money'
          ? scannField.threshold / 100
          : scannField.threshold;
      return {
        id: scannField.id,
        fieldA: scannField.field_a,
        fieldB: scannField.field_b,
        comparationType: scannField.comparation_type,
        threshold,
        value: scannField.value,
      };
    }
  }

  static toAPI(scanning, abstractFields) {
    return {
      id: scanning.id,
      number: scanning.number,
      scanning_fields: Object.values(scanning.scanningFields).map(
        (scanningField) => {
          const absField = abstractFields.find(
            (af) => af.name === scanningField.name
          );
          const threshold =
            scanningField.comparationType === 'range' &&
            absField.dataType === 'money'
              ? Math.round(scanningField.threshold * 100)
              : scanningField.threshold;
          return {
            id: scanningField.id,
            comparation_type: scanningField.comparationType,
            threshold,
            value: scanningField.value,
            field_a: { name: scanningField.name },
            field_b: { name: scanningField.name },
          };
        }
      ),
    };
  }
}
