import React, { useState, useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { H2, Paragraph, Label } from '../../common/Typography';
import {
  Button,
  Switch,
  Modal,
  ModalContent,
  ModalFooter,
  Pagination,
  InputText,
} from '@increase/typed-components';
import DetailsModal from './DetailsModal';
import { useEventTracker } from '@increasecard/increase-core';
import {
  fetchReports,
  undoConciliation,
  deleteConciliation,
  cleanState,
} from '../../../reducers/reportsReducer';
import {
  getReportLink,
  notifyReportDownload,
} from '../../../services/reportsService';
import ReportsTable from './ReportsTable';
import { withTranslation } from 'react-i18next';
import { FullScreenEmptyState } from '../../common/EmptyState';
import { Report } from 'styled-icons/boxicons-solid/';
import ReportModel from '../../../models/Report';
import { EVENTS } from '../../../actionTracker';

import NoDataLogo from '../../../img/no-data.svg';

const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
  margin: 1rem 0;
  padding: 1rem;
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 9px;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .show-deleted {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
    }
  }
`;

const NoData = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: '2rem',
      }}
    >
      <img alt="no data" src={NoDataLogo} />
      <Label style={{ color: '#b1b1b1' }}>{children}</Label>
    </div>
  );
};

function Reports({ dispatch, match, profileId, ...rest }) {
  const eventTracker = useEventTracker();
  const [modalState, setModalState] = useState({
    showDeleteModal: false,
    showDetailsModal: false,
    selectedResults: null,
    conciliationIndex: null,
  });

  useEffect(() => {
    const selectedProfileId = profileId || match.params.profileId;
    dispatch(cleanState());
    dispatch(fetchReports(selectedProfileId));
    // Pass false to only show the loader on first load or page change
    const timer = setInterval(() => {
      dispatch(fetchReports(selectedProfileId, false));
    }, 20000);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch, profileId, match]);

  const onDownloadClicked = (reportId, reportFileId) => {
    const downloadWindow = window.open('_blank');
    downloadWindow.document.write('Waiting for file to be ready');
    eventTracker.track(EVENTS.DOWNLOAD_REPORT);
    getReportLink(reportId, reportFileId)
      .then((link) => (downloadWindow.location.href = link))
      .then(notifyReportDownload(reportId, reportFileId));
  };

  const changeFilter = (filter, value) => {
    const { onFilterChange, filters } = rest;
    const currentProfileId = profileId || match.params.profileId;
    onFilterChange(currentProfileId, { ...filters, [filter]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const debouncedChangeFilter = debounce(changeFilter, 300);

  const onDetailClicked = (report, e) => {
    e.stopPropagation();
    if (report.finished()) {
      setModalState({
        selectedResults: report.conciliationResults,
        showDetailsModal: true,
      });
    }
  };

  const handleCloseDetailsModal = () => {
    setModalState({ showDetailsModal: false });
  };

  const handleShowDeleteModal = (conciliationIndex) => {
    setModalState({
      showDeleteModal: true,
      conciliationIndex,
    });
  };

  const handleDelete = () => {
    const { onDeleteConciliation, onUndoConciliation, reports } = rest;
    const conciliation = reports[modalState.conciliationIndex];
    if (
      [
        ReportModel.RECONCILIATION_TYPES.RECONCILIATION,
        ReportModel.RECONCILIATION_TYPES.RECONCILIATION_RETRY,
        ReportModel.RECONCILIATION_TYPES.MANUAL_CONCILIATED,
      ].includes(conciliation.reconciliationType)
    ) {
      onUndoConciliation(modalState.conciliationIndex);
    } else {
      onDeleteConciliation(modalState.conciliationIndex);
    }
    setModalState({
      showDeleteModal: false,
      conciliationIndex: null,
    });
  };

  const handleCancelOperation = () => {
    setModalState({
      showDeleteModal: false,
      conciliationIndex: null,
    });
  };

  const {
    disabledPage,
    fetching,
    filters,
    masquerading,
    showHiddenFeatures,
    reports,
    t,
    dataPoolNames,
  } = rest;
  return (
    <React.Fragment>
      {disabledPage && (
        <FullScreenEmptyState description={t('empty_states.reports')}>
          <Report size="72" />
        </FullScreenEmptyState>
      )}

      <DetailsModal
        conciliationResults={modalState.selectedResults}
        dataPoolNames={dataPoolNames}
        isVisible={modalState.showDetailsModal}
        onClose={handleCloseDetailsModal}
      />

      <FiltersContainer>
        <InputText
          label={t('search_report')}
          onChange={(e) => changeFilter('name', e.target.value)}
          placeholder={t('report_name')}
          value={filters.name}
          width="300px"
        />
        {masquerading && (
          <div className="show-deleted">
            <Switch
              checked={filters.showDeleted}
              label={t('show_deleted')}
              onChange={(e) => changeFilter('showDeleted', e.target.checked)}
            />
          </div>
        )}
      </FiltersContainer>
      <TableContainer>
        <ReportsTable
          data={reports}
          loading={fetching}
          masquerading={masquerading}
          onDeleteConciliation={handleShowDeleteModal}
          onDetailClicked={onDetailClicked}
          onDownloadClicked={onDownloadClicked}
          showHiddenFeatures={showHiddenFeatures}
        />
        {reports.length === 0 && <NoData>{t('empty_data')}</NoData>}
      </TableContainer>
      <Pagination
        currentPage={rest.currentPage}
        onSelectPage={rest.onPageSelect}
        style={{ justifyContent: 'flex-end' }}
        totalPages={rest.pages}
      />
      <Modal
        align="center"
        onClose={handleCancelOperation}
        visible={modalState.showDeleteModal}
      >
        <H2 style={{ textAlign: 'center' }}>
          {t('confirm_delete_conciliation_title')}
        </H2>
        <ModalContent align="center">
          <Paragraph>{t('confirm_delete_conciliation_message')}</Paragraph>
        </ModalContent>
        <ModalFooter align="center">
          <Button buttonType="alert" key="delete" onClick={handleDelete}>
            {t('confirm_delete')}
          </Button>
          <Button
            buttonType="invisible"
            key="cancel"
            onClick={handleCancelOperation}
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

Reports.propTypes = {
  currentPage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOf(['movements', 'payments', '']),
  }).isRequired,
  masquerading: PropTypes.bool.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onPageSelect: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  reports: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onFilterChange: (profileId, filters) => {
    dispatch({ type: 'SET_FILTERS', filters });
    return dispatch(fetchReports(profileId));
  },
  onPageSelect: (page) => {
    dispatch({ type: 'SET_PAGE', page });
    return dispatch(fetchReports());
  },
  onUndoConciliation: (index) => {
    return dispatch(undoConciliation(index));
  },
  onDeleteConciliation: (index) => {
    return dispatch(deleteConciliation(index));
  },
});

function mapStateToProps(state) {
  const reportsState = state.reports;
  const { disabledPage, profileId } = state.profiles;
  const { dataPoolA, dataPoolB } = state.dataPools;
  return {
    showHiddenFeatures: state.auth.hiddenFeatures,
    masquerading: state.auth.user.masquerading,
    reports: reportsState.reports,
    currentPage: reportsState.currentPage,
    pages: reportsState.pages,
    filters: reportsState.filters,
    fetching: reportsState.fetching,
    disabledPage: disabledPage,
    profileId,
    dataPoolNames: {
      A: dataPoolA.name,
      B: dataPoolB.name,
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Reports));
