import client from './apiClient';
import { isBlank } from '../helpers/helpers';
import Report from '../models/Report';

export function getReports(profileId, page, { name, type, showDeleted }) {
  const params = { profile_id: profileId, page };

  if (!isBlank(name)) {
    params.name = name;
  }
  if (type) {
    params.data_type = type;
  }
  params.with_deleted = showDeleted || null;

  return client
    .get('/conciliations', { params })
    .then(extractData)
    .then(mapData);
}

function extractData(res) {
  return res.data;
}

function mapData(data) {
  return {
    pages: data.pages,
    reports: data.reports.map((report) => Report.fromAPI(report)),
  };
}

export function getReportLink(id, report_detail_id) {
  return client
    .post(`/conciliations/${id}/download_link`, {
      report_detail_id: report_detail_id,
    })
    .then((res) => res.data['download_link']);
}

export function notifyReportDownload(id, report_detail_id) {
  return client.post(`/conciliations/${id}/notify_download`, {
    report_detail_id: report_detail_id,
  });
}
