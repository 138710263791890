import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H1, H3, Paragraph } from '../../common/Typography';
import Scannings from '../../common/Scannings';
import { RadioButton } from '@increase/typed-components';
import { CellText } from '../../common/Typography';
import {
  addScanning,
  changeRule,
  deleteScanning,
  toggleRule,
} from '../../../reducers/scanningsReducer';
import { mapComparationTypesForSelect } from '../../../helpers/profileHelpers';
import {
  setBillingDataPool,
  DATA_POOL_KEYS,
} from '../../../reducers/dataPoolsReducer';

const RadioButtonContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flexdirection: 'column';
`;

const MatchRules = ({
  comparationTypes,
  fields,
  scannings,
  onAddScanning,
  onDeleteScanning,
  onToggleRule,
  onRuleChange,
  billingDataPool,
  onBillingDatapoolChange,
  dataPoolAName,
  dataPoolBName,
  vsCard,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <H1>{t('profile_creator.match_rules_config')}</H1>
      <H3>{t('profile_creator.billing_datapool_description')}</H3>
      <RadioButtonContainer>
        <RadioButton
          checked={billingDataPool === DATA_POOL_KEYS.DATA_POOL_A}
          disabled={false}
          name="billingDatapool"
          onChange={(e) => onBillingDatapoolChange(e.target.value)}
          value={DATA_POOL_KEYS.DATA_POOL_A}
        />
        <CellText>{dataPoolAName}</CellText>
      </RadioButtonContainer>
      <RadioButtonContainer>
        <RadioButton
          checked={billingDataPool === DATA_POOL_KEYS.DATA_POOL_B}
          disabled={false}
          name="billingDatapool"
          onChange={(e) => onBillingDatapoolChange(e.target.value)}
          value={DATA_POOL_KEYS.DATA_POOL_B}
        />
        <CellText>{dataPoolBName}</CellText>
      </RadioButtonContainer>
      <Paragraph>
        {t('profile_creator.match_rules_config_description')}
      </Paragraph>
      <Scannings
        comparationTypes={comparationTypes}
        editMode={true}
        fields={fields}
        onAddScanning={onAddScanning}
        onDeleteScanning={onDeleteScanning}
        onRuleChange={onRuleChange}
        onToggleRule={onToggleRule}
        scannings={scannings}
        t={t}
        vsCard={vsCard}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onAddScanning: () => dispatch(addScanning()),
  onDeleteScanning: (idx) => dispatch(deleteScanning(idx)),
  onToggleRule: (scanning, referenceId, selected) =>
    dispatch(toggleRule(scanning, referenceId, selected)),
  onRuleChange: (scanning, referenceId, changes) =>
    dispatch(changeRule(scanning, referenceId, changes)),
  onBillingDatapoolChange: (dataPoolKey) =>
    dispatch(setBillingDataPool(dataPoolKey)),
});

function mapStateToProps(fullState) {
  const { vsCard } = fullState.profiles;
  const { abstractFields, billingDataPool } = fullState.dataPools;
  const { comparationTypes } = fullState.fileStructure;
  const scannings = Object.values(fullState.scannings);
  const {
    dataPoolA: { name: dataPoolAName },
    dataPoolB: { name: dataPoolBName },
  } = fullState.dataPools;
  return {
    comparationTypes: mapComparationTypesForSelect(comparationTypes),
    scannings,
    fields: abstractFields,
    vsCard,
    billingDataPool,
    dataPoolAName,
    dataPoolBName,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchRules);
