import React from 'react';
import styled from 'styled-components';
import { Link } from '@increase/typed-components';
import WizardHeader from './../../common/Wizard/WizardHeader';
import { withTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LinkStyled = styled(Link)`
  font-size: 1rem;
  margin-left: 0.5rem;
`;

const Welcome = (props) => {
  const { username, t } = props;
  return (
    <>
      <Container>
        <WizardHeader
          text={t('onboarding.welcome.description')}
          title={t('onboarding.welcome.title', { username: username })}
        />
        <p>{t('onboarding.welcome.text1')}</p>
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          height="350"
          src="https://www.youtube.com/embed/FVCNexMx3Gg"
          title="title"
          width="600"
        />
        <p>
          {t('onboarding.welcome.any_doubt_question')}
          <LinkStyled
            href="https://soporte.increasecard.com/hc/es"
            target="blank"
          >
            {t('onboarding.welcome.ask_for_help')}
          </LinkStyled>
        </p>
      </Container>
    </>
  );
};

export default withTranslation()(Welcome);
