import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { withRouter, Link as RouterLink } from 'react-router-dom';

import { Button, color, typography } from '@increase/typed-components';
import { Layout } from '@increasecard/increase-core';
import { useTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';

import ProfilesNav from './ProfilesNav';
import MaintenanceScreen from '../screens/maintenance/MaintenanceScreen';
import DefaultScreen from '../screens/defaultScreen/DefaultScreen';
import { GenericErrorModal } from '../common/GenericErrorModal';
import { hideGenericError } from '../../reducers/genericErrorReducer';

const Container = styled.div`
  display: flex;
  height: calc(100vh - 112px);
  overflow: hidden;
  ${(props) => props.disabledPage && 'filter: blur(5px);'}
`;

const ContentWrapper = styled.div`
  width: ${({ expand }) =>
    expand
      ? 'calc(100% - 40px)'
      : 'calc(100% - 244px)'}; /* 244 left bar width */
  transition: width 200ms ease-in-out;
  /* NotSoWhite */
  background-color: #fbfbfb;
`;

const DescriptionBar = styled.div`
  ${typography('cell')}
  background: ${color('brandBlack', 'light')};
  color: ${color('brandWhite', 'regular')};
  height: 56px;
  width: 244px;
  flex-shrink: 0;
  padding: 12px 24px;
  & .title {
    /* Caption text */
    /* TODO(santi698): Ask @pz-increase if we need to define this in the theme */
    font-size: 10px;
    line-height: 14px;
    color: ${color('brandWhite', 'bold')};
  }
  & .profile-name {
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const ScrollableContent = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const tabs = (t, history, match) => [
  {
    active: history.location.pathname.indexOf('/reports') !== -1 ? 'true' : '',
    as: 'button',
    name: t('results'),
    onClick: () => history.push(`/profiles/${match.params.profileId}/reports`),
  },
  {
    active: history.location.pathname.indexOf('/records') !== -1 ? 'true' : '',
    as: 'button',
    name: t('records.title'),
    onClick: () => history.push(`/profiles/${match.params.profileId}/records`),
  },
  {
    active: history.location.pathname.indexOf('/details') !== -1 ? 'true' : '',
    as: 'button',
    name: t('see_model'),
    onClick: () => history.push(`/profiles/${match.params.profileId}/details`),
  },
  {
    active: history.location.pathname.indexOf('/files') !== -1 ? 'true' : '',
    as: 'button',
    name: t('see_uploaded_files'),
    onClick: () => history.push(`/profiles/${match.params.profileId}/files`),
  },
];

function AppLayout({
  children,
  disabledPage,
  sidebarExpanded,
  masquerading,
  profileName,
  maintenanceMode,
  history,
  match,
  hideGenericError,
  genericError,
}) {
  const { t } = useTranslation();
  return (
    <Layout
      leftContext={
        <DescriptionBar>
          <div className="title">{t('selected_profile')}</div>
          <div className="profile-name">{profileName || t('match_model')}</div>
        </DescriptionBar>
      }
      rightContext={
        <div style={{ padding: '0 26px' }}>
          <Button
            active={
              history.location.pathname.indexOf('/newconciliation') !== -1
                ? 'true'
                : ''
            }
            as={RouterLink}
            to={`/profiles/${match.params.profileId}/newconciliation`}
          >
            {t('new_conciliation')}
          </Button>
        </div>
      }
      tabs={tabs(t, history, match, masquerading)}
    >
      {maintenanceMode && !masquerading ? (
        <MaintenanceScreen />
      ) : (
        <Container disabledPage={disabledPage}>
          {disabledPage && <DefaultScreen />}
          <ProfilesNav />
          <ContentWrapper expand={!sidebarExpanded}>
            <ScrollableContent>{children}</ScrollableContent>
          </ContentWrapper>
        </Container>
      )}
      <GenericErrorModal
        isVisible={genericError.showModal}
        onClose={() => hideGenericError()}
      />
    </Layout>
  );
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array,
  ]),
};

function mapStateToProps(fullState) {
  const { profiles, auth, genericError } = fullState;
  return {
    user: auth.user,
    masquerading: auth.user ? auth.user.masquerading : false,
    disabledPage: profiles.disabledPage,
    profileName: profiles.profile && profiles.profile.name,
    sidebarExpanded: profiles.profilesNavShow,
    maintenanceMode: auth.maintenanceMode,
    genericError,
  };
}

const mapDispatchToProps = {
  hideGenericError,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout)
);
