import styled from 'styled-components';

const BottomNavigation = styled.div`
  background-color: white;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: left;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.15);
  button:first-child {
    margin-right: 1rem;
  }
`;

export default BottomNavigation;
