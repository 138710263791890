import styled from 'styled-components';
import {
  H1,
  H2,
  H3,
  Paragraph,
  CellText,
  Label,
  InputLabel,
} from '@increase/typed-components';

const H1Styled = styled(H1)`
  margin: 0.5em 0;
`;

const H2Styled = styled(H2)`
  margin: 0.5em 0;
`;

const H3Styled = styled(H3)`
  margin: 0.5em 0;
`;

export {
  H1Styled as H1,
  H2Styled as H2,
  H3Styled as H3,
  Paragraph,
  CellText,
  Label,
  InputLabel,
};
