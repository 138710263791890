import { combineReducers } from 'redux';
import authReducer from './authReducer';
import reportsReducer from './reportsReducer';
import recordsReducer from './recordsReducer';
import profilesReducer from './profilesReducer';
import fileStructureReducer from './fileStructureReducer';
import fileUploaderReducer from './fileUploaderReducer';
import structuresReducer from './structuresReducer';
import onboardingReducer from './onboardingReducer';
import uploadedFilesReducer from './uploadedFilesReducer';
import dataPoolsReducer from './dataPoolsReducer';
import scanningsReducer from './scanningsReducer';
import genericErrorReducer from './genericErrorReducer';

export default combineReducers({
  auth: authReducer,
  dataPools: dataPoolsReducer,
  fileUploader: fileUploaderReducer,
  profiles: profilesReducer,
  records: recordsReducer,
  reports: reportsReducer,
  scannings: scanningsReducer,
  structures: structuresReducer,
  uploadedFiles: uploadedFilesReducer,
  onboarding: onboardingReducer,
  fileStructure: fileStructureReducer,
  genericError: genericErrorReducer,
});
