import client from './apiClient';

function submitForm(files, description) {
  return client.post('/profile_requests', {
    profile_request: {
      files,
      description: description,
    },
  });
}

export default {
  submitForm,
};
