import React, { useState } from 'react';
import styled, { css as CSS } from 'styled-components';
import { useTranslation } from 'react-i18next';
import Table from '../../common/Table';
import Select from '../../common/Select';
import ProgressBar from '../../common/ProgressBar';
import { InfoOutline as InfoIcon } from 'styled-icons/material/InfoOutline';
import LinkButton from '../../common/LinkButton';
import UploadStatus from '../../common/FileUpload/UploadStatus';
import { DATA_POOL_KEYS } from '../../../reducers/dataPoolsReducer';

const {
  EMPTY,
  UPLOADING,
  COMPLETED,
  UPLOAD_ERROR,
  FILE_TYPE_ERROR,
} = UploadStatus;

const RemoveButton = styled(LinkButton)`
  /* Alerta */
  ${({ type }) =>
    type === 'error' &&
    CSS`
      color: #e71321;
    `}
`;

const ErrorMessage = styled.div`
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.09em;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  .file-name-message {
    margin-left: 0.5rem;
  }
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function Action({ file, onRetry, onRemove, t }) {
  switch (file.status) {
    case UPLOADING:
      return <ProgressBar progress={file.progress} />;
    case UPLOAD_ERROR:
      return (
        <LinkButton onClick={() => onRetry(file)}>{t('retry')}</LinkButton>
      );
    case FILE_TYPE_ERROR:
      return (
        <RemoveButton onClick={() => onRemove(file)} type="error">
          {t('delete')}
        </RemoveButton>
      );
    case COMPLETED:
    case EMPTY:
    default:
      return (
        <RemoveButton onClick={() => onRemove(file)}>
          {t('delete')}
        </RemoveButton>
      );
  }
}

function FileRow({
  file,
  onRetry,
  onRemove,
  onStructureSelect,
  vsCard,
  dataPoolList,
  structureList,
  t,
}) {
  const fileTypeError = file.status === FILE_TYPE_ERROR;
  const uploadError = file.status === UPLOAD_ERROR;

  const [selectedDataPool, setSelectedDataPool] = useState(
    vsCard ? DATA_POOL_KEYS.DATA_POOL_A : null
  );

  if (
    !file.structureId &&
    selectedDataPool &&
    structureList[selectedDataPool] &&
    structureList[selectedDataPool].length === 1
  ) {
    onStructureSelect(
      file.name,
      selectedDataPool,
      structureList[selectedDataPool][0].value
    );
  }
  return (
    <tr className={fileTypeError || uploadError ? 'error' : ''} key={file.name}>
      <td title={file.name}>
        <NameWrapper>
          <span>
            {(fileTypeError || uploadError) && <InfoIcon size="20" />}
          </span>
          <div className="file-name-message">
            {file.name}
            {fileTypeError && (
              <ErrorMessage>
                {t('file_upload_invalid_file_type_short')}
              </ErrorMessage>
            )}
            {uploadError && (
              <ErrorMessage>{t('file_upload_error')}</ErrorMessage>
            )}
          </div>
        </NameWrapper>
      </td>
      <td>
        {vsCard ? (
          DATA_POOL_KEYS.DATA_POOL_A
        ) : (
          <Select
            disabled={fileTypeError || uploadError}
            onSelect={(value) => {
              onStructureSelect(file.name, selectedDataPool, null);
              setSelectedDataPool(value);
            }}
            options={dataPoolList}
            placeholder={t('select_datapool')}
            selected={selectedDataPool}
          />
        )}
      </td>
      <td>
        <Select
          disabled={!selectedDataPool || fileTypeError || uploadError}
          onSelect={(value) =>
            onStructureSelect(file.name, selectedDataPool, value)
          }
          options={selectedDataPool ? structureList[selectedDataPool] : []}
          placeholder={t('select_structure')}
          selected={file.structureId}
        />
      </td>
      <td className="actions">
        <Action file={file} onRemove={onRemove} onRetry={onRetry} t={t} />
      </td>
    </tr>
  );
}

const CustomTable = styled(Table)`
  table-layout: fixed;
  background-color: none;

  tr.error td {
    /* Alerta */
    color: #e71321;
  }
  td.actions {
    text-align: right;
  }
  & .file-name {
    width: 30%;
  }
  & .data-pool {
    width: 25%;
  }
  & .structure {
    width: 30%;
  }
  & .actions {
    width: 15%;
  }
`;

export function FilesTable({
  fetching,
  files,
  dataPoolList,
  structureList,
  onFileRemove,
  onRetry,
  onSetStructure,
  vsCard,
}) {
  const { t } = useTranslation();
  return (
    <CustomTable>
      <colgroup>
        <col className="file-name" />
        <col className="data-pool" />
        <col className="structure" />
        <col className="actions" />
      </colgroup>
      <thead>
        <tr>
          <th>{t('filename')}</th>
          <th>{t('datapool')}</th>
          <th>{t('profile.file_structure')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!fetching &&
          files.map((file) => (
            <FileRow
              dataPoolList={dataPoolList}
              file={file}
              key={file.name}
              onRemove={onFileRemove}
              onRetry={onRetry}
              onStructureSelect={onSetStructure}
              structureList={structureList}
              t={t}
              vsCard={vsCard}
            />
          ))}
      </tbody>
    </CustomTable>
  );
}
