import cloneDeep from 'lodash/cloneDeep';
import { getReports } from '../services/reportsService';
import {
  rollbackConciliation,
  deleteConciliation as removeConciliation,
} from '../services/conciliationService';
const INITIAL_STATE = {
  reports: [],
  filters: {
    name: '',
    type: '',
    showDeleted: false,
  },
  currentPage: 1,
  pages: 1,
  fetching: false,
};

export function fetchReports(profileId, showLoader = true) {
  return (dispatch, getState) => {
    dispatch({ type: 'REPORTS_REQUEST', showLoader });
    const { filters, currentPage } = getState().reports;
    profileId = profileId || getState().profiles.profileId;
    return getReports(profileId, currentPage, filters)
      .then(({ reports, pages }) => {
        //TODO we need the total pages from the API
        dispatch({ type: 'REPORTS_FETCHED', reports, pages });
      })
      .catch((err) => console.log(err));
  };
}

export function deleteConciliation(conciliationIndex) {
  return (dispatch, getState) => {
    const conciliations = getState().reports.reports.slice();
    const conciliationToDelete = cloneDeep(conciliations[conciliationIndex]);
    conciliationToDelete.toDelete = true;
    conciliations.splice(conciliationIndex, 1, conciliationToDelete);
    dispatch({ type: 'DELETE_CONCILIATON', conciliations });
    return removeConciliation(conciliationToDelete.id).then(() => {
      const deletedConciliation = cloneDeep(conciliations[conciliationIndex]);
      deletedConciliation.deleted = true;
      conciliations.splice(conciliationIndex, 1, deletedConciliation);
      return dispatch({ type: 'DELETED_CONCILIATION', conciliations });
    });
  };
}

export function undoConciliation(conciliationIndex) {
  return (dispatch, getState) => {
    const conciliations = getState().reports.reports.slice();
    const conciliationToUndo = cloneDeep(conciliations[conciliationIndex]);
    conciliationToUndo.toDelete = true;
    conciliations.splice(conciliationIndex, 1, conciliationToUndo);
    dispatch({ type: 'UNDO_CONCILIATION', conciliations });
    return rollbackConciliation(conciliationToUndo.id).then(() => {
      const undoneConciliation = cloneDeep(conciliations[conciliationIndex]);
      undoneConciliation.deleted = true;
      conciliations.splice(conciliationIndex, 1, undoneConciliation);
      dispatch({ type: 'UNDONE_CONCILIATION', conciliations });
    });
  };
}

export function cleanState() {
  return (dispatch) => {
    return dispatch({ type: 'CLEAN_STATE' });
  };
}

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, currentPage: action.page };
    case 'SET_FILTERS':
      return { ...state, filters: action.filters };
    case 'REPORTS_REQUEST':
      return { ...state, fetching: action.showLoader };
    case 'REPORTS_FETCHED':
      return {
        ...state,
        reports: action.reports,
        fetching: false,
        pages: action.pages,
      };
    case 'DELETE_CONCILIATON':
    case 'DELETED_CONCILIATION':
    case 'UNDO_CONCILIATION':
    case 'UNDONE_CONCILIATION':
      return {
        ...state,
        reports: action.conciliations,
      };
    case 'CLEAN_STATE':
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reportsReducer;
