import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import LinkButton from '../../common/LinkButton';
import { H2, CellText, InputLabel, Paragraph } from '../../common/Typography';
import Table from '../../common/Table';
import { Modal, ModalContent } from '@increase/typed-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 549px;
  width: calc(50% - 0.5rem);
  min-height: 320px;
  padding: 1rem;
  background: #ffffff;
  /* IncreaseWhite Medium */
  border: 1px solid #d8d8d8;
  border-radius: 9px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const separators = {
  ',': 'Coma',
  ';': 'Punto y Coma',
  ' ': 'Tabulacion',
  '|': 'Palote',
};

const FieldsTable = styled(Table)`
  table-layout: fixed;
  background-color: none;
  margin: 1rem 0;
  th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }
  & td {
    white-space: normal;
  }
  & .name {
    width: 40%;
    white-space: normal;
  }
  & .type {
    width: 20%;
  }
  & .config {
    width: 25%;
  }
  & .column-number {
    width: 15%;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  min-height: 250px;
  margin-top: 1rem;
  .left-panel {
    width: 40%;
    padding-right: 2rem;
    /* IncreaseWhite Medium */
    border-right: 1px solid #d8d8d8;
  }
  .right-panel {
    width: 60%;
    padding-left: 2rem;
  }
  .informative-fields {
    margin-top: 2rem;
  }
`;

const IgnoreLinesTable = styled(Table)`
  table-layout: fixed;
  background-color: none;
  th,
  td {
    text-align: left;
  }
  & .column-number {
    width: 30%;
  }
  & .content {
    width: 70%;
  }
`;

const InformativeFieldsTable = styled.div`
  & div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
  }
  .field-row {
    padding-right: 1rem;
    /* IncreaseWhite Bold */
    border-bottom: 1px solid #b1b1b1;
  }
`;

const getFieldConfig = (field, t) => {
  const { dataType } = field;
  switch (dataType) {
    case 'date':
      return t('constants:date_format', { dateNotation: field.dateFormat });
    case 'money':
      return t('money_separators', {
        returnObjects: true,
      }).find((item) => item.value === field.centsSeparator).name;
    case 'last_n':
    case 'first_n':
      return field.stringOffset;
    default:
      return '';
  }
};

function FileStructure({ structure, vsCard, t }) {
  const [showAdvConfig, setShowAdvConfig] = useState(null);
  return (
    <Container>
      <div>
        <H2>{`${t('profile.file_structure')}: ${structure.name}`}</H2>
        <DetailsWrapper>
          <CellText>
            {`${t('profile.column_separator')}: `}
            <b>
              {`${separators[structure.delimiter] || ''} (${
                structure.delimiter
              })`}
            </b>
          </CellText>
          <CellText>
            {`${t('profile.header_lines')}: `}
            <b>{`${structure.headersCount || 0}`}</b>
          </CellText>
          <CellText>
            {`${t('profile.footer_lines')}: `}
            <b>{`${structure.footersCount || 0}`}</b>
          </CellText>
        </DetailsWrapper>
        <FieldsTable>
          <colgroup>
            <col className="name" />
            <col className="type" />
            <col className="config" />
            <col className="coliumn-number" />
          </colgroup>
          <thead>
            <tr>
              <th>{t('fields.name')}</th>
              <th>{t('fields.type')}</th>
              <th>{t('fields.configuration')}</th>
              <th>{t('fields.column')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(structure.fields).map((field) => {
              return (
                <tr key={field.id}>
                  <td>
                    {vsCard
                      ? t(`constants:increasecard_fields.${field.name}`)
                      : field.name}
                  </td>
                  <td>{t(`constants:data_types.${field.dataType}`)}</td>
                  <td>{getFieldConfig(field, t)}</td>
                  <td>{field.location}</td>
                </tr>
              );
            })}
          </tbody>
        </FieldsTable>
      </div>
      <div style={{ textAlign: 'right' }}>
        <LinkButton onClick={() => setShowAdvConfig(!showAdvConfig)}>
          {t('advanced_settings')}
        </LinkButton>
        <Modal
          onClose={() => setShowAdvConfig(!showAdvConfig)}
          visible={showAdvConfig}
          width={900}
        >
          <ModalContent>
            <H2 style={{ marginBottom: 0 }}>{t('advanced_settings')}</H2>
            <Paragraph>{`${structure.name}`}</Paragraph>
            <ModalContentWrapper>
              <div className="left-panel">
                <InputLabel style={{ marginBottom: '0.5em' }} weight="bold">
                  {t('profile.text_to_remove')}
                </InputLabel>
                <CellText inline={false}>
                  {structure.stringToRemove || t('not_apply')}
                </CellText>
                <div className="informative-fields">
                  <H2>{t('informative_columns')}</H2>
                  <InformativeFieldsTable>
                    <div>
                      <InputLabel>{t('name')}</InputLabel>
                      <InputLabel>{t('fields.column')}</InputLabel>
                    </div>
                    {Object.values(structure.informativeFields).map(
                      (iField, index) => {
                        return (
                          <div className="field-row" key={index}>
                            <CellText weight={'bold'}>{iField.field}</CellText>
                            <CellText>{iField.location}</CellText>
                          </div>
                        );
                      }
                    )}
                  </InformativeFieldsTable>
                </div>
              </div>
              <div className="right-panel">
                <IgnoreLinesTable>
                  <colgroup>
                    <col className="column-number" />
                    <col className="content" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{t('profile.ignore_lines_in')}</th>
                      <th>{t('content')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {structure.ignoredLines.map((line, index) => {
                      return (
                        <tr key={index}>
                          <td>{line.position}</td>
                          <td>{line.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </IgnoreLinesTable>
              </div>
            </ModalContentWrapper>
          </ModalContent>
        </Modal>
      </div>
    </Container>
  );
}

FileStructure.propTypes = {
  structure: PropTypes.object,
};

export default withTranslation()(FileStructure);
