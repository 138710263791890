import React, { useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import { useAuth, AuthStates } from '@increasecard/increase-core';
import { authenticateUser } from './services/authenticationService';
import { setUser } from './reducers/authReducer';
import i18n from './i18n';

const UserDispatcher = ({ storeUser, userLoading, dispatch, children }) => {
  const authState = useAuth({ requireAuth: true });
  useEffect(() => {
    if (authState.state === AuthStates.Authenticated) {
      authenticateUser().then((loggedUser) => {
        if (!storeUser) {
          i18n.changeLanguage(loggedUser.langCode);
          dispatch(setUser(loggedUser));
        } else if (storeUser.masquerading) {
          window.zE(() => {
            window.zE.hide();
          });
        }
      });
    }
  }, [authState.state, dispatch, storeUser]);
  return <>{!userLoading && children}</>;
};

function mapStateToProps(fullState) {
  return {
    storeUser: fullState.auth.user,
    userLoading: fullState.auth.loading,
  };
}

export default connect(mapStateToProps)(UserDispatcher);
