import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';

import { Paragraph } from '../Typography';
import uploadArrow from '../../../img/upload.svg';
import FullPageSpinner from '../FullPageSpinner';

const FileUploaderWrapper = styled.div`
  padding-top: 3rem;
  text-align: center;
`;

const Container = styled.div`
  /* IncreaseWhite */
  background: #ffffff;
  transition: all 200ms ease-out;
  border: 1px solid #d8d8d8;
  ${({ isDragActive }) =>
    isDragActive
      ? `
      /* sky */
      border: 3px solid #87B5FF;
      /* IncreaseWhite Light */
      opacity: .5;
      background-color: #f5f5f5;

      `
      : null}
  border-radius: 9px;
  padding: 1rem;
  outline: none;
  & > div,
  & > table {
    transition: margin 200ms ease-out;
    margin: ${(props) => (props.isDragActive ? '-2px' : '0px')};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => `${minHeight || 0}px`};
  max-height: 100%;
`;

const UploadImg = styled.img`
  width: 85px;
  display: block;
  margin: 10px auto;
  user-select: none;
`;

const StyledParagraph = styled(Paragraph)`
  text-decoration: underline;
  /* Sky */
  color: #0f6bff;
  cursor: pointer;
`;

const ActionMessage = styled.p`
  margin: 0;
  user-select: none;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: -0.011em;
`;

function UploadDragZone({
  children,
  minHeight,
  t,
  onDropAccepted,
  onDropRejected,
  onDragEnter,
  onDragLeave,
  showLoader,
}) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    accept: ['.csv', '.txt'],
    noClick: true,
  });
  return (
    <Container {...getRootProps({ isDragActive })}>
      <Wrapper minHeight={minHeight}>
        {children}
        {showLoader ? (
          <FullPageSpinner />
        ) : (
          <FileUploaderWrapper>
            <UploadImg alt="upload arrow" draggable="false" src={uploadArrow} />
            <div>
              <input {...getInputProps()} />
              <ActionMessage>{t('drag_file_to_conciliate')}</ActionMessage>
              <StyledParagraph onClick={open}>
                {t('step_1_click_here')}
              </StyledParagraph>
              <Paragraph>
                {t('accepted_formats', { acceptedFormats: 'TXT, CSV' })}
              </Paragraph>
            </div>
          </FileUploaderWrapper>
        )}

        <ReactTooltip effect="solid" place="bottom" type="info" />
      </Wrapper>
    </Container>
  );
}

UploadDragZone.propTypes = {
  minHeight: PropTypes.number,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDropAccepted: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UploadDragZone);
