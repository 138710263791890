import * as conciliationService from './../services/conciliationService';
import { snakeCase, formatDateRange } from '../helpers/helpers';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import sub from 'date-fns/sub';

const INITIAL_STATE = {
  profileId: null,
  pagesA: 1,
  pageA: 1,
  rowsA: [],
  fetchingA: false,
  columnsA: {},
  selectedA: [],
  filtersA: {},

  pagesB: 1,
  pageB: 1,
  rowsB: [],
  fetchingB: false,
  columnsB: {},
  selectedB: [],
  filtersB: {},

  matches: [],
  manualMatchingStarted: false,
  matchSessionName: '',
  generalFilters: {
    showAll: false,
    dateRange: [sub(new Date(), { weeks: 1 }), new Date()],
  },

  reports: [],
  fetchingReports: false,
  reportSuccess: false,

  horizontalLayout: false,
};

function addOrRemoveSelected(selected, rows, shouldAdd) {
  const newRows = Array.isArray(rows) ? rows : [rows];
  if (shouldAdd) {
    return uniq([...selected, ...newRows]);
  }
  return selected.filter(
    (row) => !newRows.find((newRow) => row.id === newRow.id)
  );
}

function formatFilters({
  filtersA,
  filtersB,
  columnsA,
  columnsB,
  generalFilters,
  movementsFilters,
  paymentsFilters,
}) {
  const allFilters = {
    ...filtersA,
    ...filtersB,
    ...generalFilters,
    ...movementsFilters,
    ...paymentsFilters,
  };
  const formattedFilters = {
    conciliated: allFilters.conciliated,
    daterange: formatDateRange(allFilters.dateRange),
  };

  Object.keys(columnsA || {}).forEach((filterName) => {
    if (filtersA[filterName]) {
      const underscoreFilterName = snakeCase(filterName);
      formattedFilters[underscoreFilterName] = filtersA[filterName];
    }
  });

  Object.keys(columnsB || {}).forEach((filterName) => {
    if (filtersB[filterName]) {
      const underscoreFilterName = snakeCase(filterName);
      formattedFilters[underscoreFilterName] = filtersB[filterName];
    }
  });

  return formattedFilters;
}

function createFiltersObject(columns) {
  return Object.fromEntries(Object.entries(columns).map(([key]) => [key, '']));
}

export function fetchPerndingRecordsReports(params) {
  return (dispatch) => {
    dispatch({ type: 'FETCH_PENDING_REPORTS' });
    return conciliationService.getReports(params).then(({ reports }) => {
      dispatch({ type: 'FETCH_PENDING_REPORTS_SUCCESS', reports });
    });
  };
}

export function generatePerndingRecordsReport(params) {
  return (dispatch) => {
    dispatch({ type: 'GENERATE_PENDING_REPORT' });
    return conciliationService.generateReport(params).then(() => {
      dispatch({ type: 'GENERATE_PENDING_REPORT_SUCCESS' });
    });
  };
}

export function resendPendingRecordsReport(params) {
  return (dispatch) => {
    dispatch({ type: 'GENERATE_PENDING_REPORT' });
    return conciliationService.resendReport(params).then(() => {
      dispatch({ type: 'GENERATE_PENDING_REPORT_SUCCESS' });
    });
  };
}

export function cleanPendingRecordsSate() {
  return { type: 'CLEAN_PENDING_REPORTS_STATE' };
}

export function fetchColumns() {
  return (dispatch, getState) => {
    const { dataPoolA, dataPoolB } = getState().dataPools;
    const { reconciliationType } = getState().profiles.profile;
    dispatch(fetchColumnsA(dataPoolA.id));
    return dispatch(fetchColumnsB(dataPoolB.id, reconciliationType));
  };
}

export function fetchColumnsA(datapoolId) {
  return (dispatch) => {
    dispatch({ type: 'FETCH_FILE_COLUMNS_A' });
    return conciliationService.getFileColumns(datapoolId).then((columnsA) => {
      dispatch({
        type: 'SET_FILTERS_A',
        filtersA: createFiltersObject(columnsA),
      });
      dispatch({ type: 'SET_COLUMNS_A', columnsA });
    });
  };
}

export function fetchColumnsB(dataPoolId, reconciliationType) {
  return (dispatch, getState) => {
    dispatch({ type: 'FETCH_FILE_COLUMNS_B' });
    const movementsFilters = getState().fileStructure
      .increasecardFiltersStructure.movements;
    const paymentsFilters = getState().fileStructure
      .increasecardFiltersStructure.payments;
    switch (reconciliationType) {
      case 'file':
        return dispatch(fetchFileColumnsB(dataPoolId));
      case 'movements':
        return dispatch(setFiltersColumnsB(movementsFilters));
      case 'payments':
        return dispatch(setFiltersColumnsB(paymentsFilters));
      default:
        throw new Error(
          `Unknown reconciliationType type: ${reconciliationType}`
        );
    }
  };
}

export function fetchFileColumnsB(dataPoolId) {
  return (dispatch) => {
    return conciliationService.getFileColumns(dataPoolId).then((columns) => {
      return dispatch(setFiltersColumnsB(columns));
    });
  };
}

export function setFiltersColumnsB(columnsB) {
  return (dispatch) => {
    dispatch({
      type: 'SET_FILTERS_B',
      filtersB: createFiltersObject(columnsB),
    });
    return dispatch({ type: 'SET_COLUMNS_B', columnsB });
  };
}
export function setRecordsData(profile) {
  return (dispatch) => {
    const { reconciliationType, id: profileId } = profile;
    dispatch(clearRecordsState());
    return dispatch({
      type: 'SET_RECORDS_PROFILE',
      profileId,
      reconciliationType,
    });
  };
}

export function filterAll(generalFilters) {
  return (dispatch) => {
    dispatch({ type: 'SET_GENERAL_FILTERS', generalFilters });
    dispatch(resetSelected());
    dispatch(resetPages());
    dispatch(fetchRowsA());
    return dispatch(fetchRowsB());
  };
}

export function resetColumns() {
  return (dispatch) => {
    dispatch({ type: 'SET_COLUMNS_A', columnsA: {} });
    return dispatch({ type: 'SET_COLUMNS_B', columnsB: {} });
  };
}

export function resetRows() {
  return (dispatch) => {
    dispatch({ type: 'SET_ROWS_A', rowsA: [] });
    return dispatch({ type: 'SET_ROWS_B', rowsB: [] });
  };
}

export function resetPages() {
  return (dispatch) => {
    dispatch({ type: 'SET_PAGE_A', pageA: 1 });
    return dispatch({ type: 'SET_PAGE_B', pageB: 1 });
  };
}

export function resetSelected() {
  return (dispatch) => {
    dispatch({ type: 'SELECT_ROW_A', newSelected: [] });
    return dispatch({ type: 'SELECT_ROW_B', newSelected: [] });
  };
}

export function selectRowA(rowA, shouldAdd) {
  return (dispatch, getState) => {
    const { selectedA } = getState().records;
    const newSelected = addOrRemoveSelected(selectedA, rowA, shouldAdd);
    return dispatch({ type: 'SELECT_ROW_A', newSelected });
  };
}

export function selectRowB(rowB, shouldAdd) {
  return (dispatch, getState) => {
    const { selectedB } = getState().records;
    const newSelected = addOrRemoveSelected(selectedB, rowB, shouldAdd);
    return dispatch({ type: 'SELECT_ROW_B', newSelected });
  };
}

export function filterRowsA(filtersA) {
  return (dispatch) => {
    dispatch({ type: 'SET_FILTERS_A', filtersA });
    return dispatch(fetchRowsA());
  };
}

export function filterRowsB(filtersB) {
  return (dispatch) => {
    dispatch({ type: 'SET_FILTERS_B', filtersB });
    return dispatch(fetchRowsB());
  };
}

export function startManualMatching(matchSessionName) {
  return (dispatch) => {
    return dispatch({ type: 'START_MANUAL_MATCHING', matchSessionName });
  };
}

export function finishManualMatching() {
  return (dispatch, getState) => {
    const {
      records: { matches, matchSessionName },
      profiles: {
        profile: { id: profileId, reconciliationType },
      },
      fileStructure: { rowsKeys },
    } = getState();
    const filteredMatches = matches.map(({ dataPoolA, dataPoolB }) => {
      return {
        dataPoolA: dataPoolA.map((row) => pick(row, rowsKeys.file)),
        dataPoolB: dataPoolB.map((row) =>
          pick(row, rowsKeys[reconciliationType])
        ),
      };
    });

    return conciliationService
      .conciliate(profileId, filteredMatches, matchSessionName)
      .then(() => {
        dispatch({
          type: 'SET_PROFILE_MATCHING_STATUS',
          isProfileMatching: true,
        });
        return dispatch({ type: 'FINISH_MANUAL_MATCHING' });
      });
  };
}

export function addCurrentMatch() {
  return (dispatch) => {
    return dispatch({ type: 'ADD_CURRENT_MATCH' });
  };
}

//TODO: NvsM
export function fetchRowsA() {
  return (dispatch, getState) => {
    const {
      filtersA,
      pageA,
      columnsA,
      generalFilters,
      movementsFilters,
      paymentsFilters,
      reconciliationType,
      pagesA,
    } = getState().records;
    const { dataPoolA } = getState().dataPools;
    const { dateRange } = getState().records.generalFilters;

    dispatch({ type: 'REQUEST_ROWS_A' });

    return conciliationService
      .getRows(
        'file',
        dataPoolA.id,
        dateRange,
        pageA,
        formatFilters({
          filtersA,
          columnsA,
          generalFilters,
          movementsFilters,
          paymentsFilters,
          reconciliationType,
        })
      )
      .then(({ fileRows, pages }) => {
        dispatch({
          type: 'SET_ROWS_A',
          rowsA: fileRows,
          pagesA: pages || pagesA,
        });
      });
  };
}

export function fetchRowsB() {
  return (dispatch, getState) => {
    const {
      filtersB,
      pageB,
      columnsB,
      generalFilters,
      movementsFilters,
      paymentsFilters,
      pagesB,
    } = getState().records;
    const { reconciliationType } = getState().profiles.profile;
    const { dateRange } = generalFilters;
    const { dataPoolB } = getState().dataPools;

    dispatch({ type: 'REQUEST_ROWS_B' });

    return conciliationService
      .getRows(
        reconciliationType,
        dataPoolB.id,
        dateRange,
        pageB,
        formatFilters({
          filtersB,
          columnsB,
          generalFilters,
          movementsFilters,
          paymentsFilters,
          reconciliationType,
        })
      )
      .then(({ rows, pages }) => {
        dispatch({ type: 'SET_ROWS_B', rowsB: rows, pagesB: pages || pagesB });
      });
  };
}

export function cleanFiltersA() {
  return (dispatch) => {
    return dispatch({ type: 'SET_FILTERS_A', filtersA: { filename: null } });
  };
}

export function cleanFiltersB() {
  return (dispatch) => {
    return dispatch({ type: 'SET_FILTERS_B', filtersB: { filename: null } });
  };
}

export function clearRecordsState() {
  return (dispatch) => {
    return dispatch({ type: 'CLEAR_STATE' });
  };
}

export function toggleTableLayout() {
  return (dispatch) => {
    return dispatch({ type: 'TOGGLE_TABLE_LAYOUT' });
  };
}

const recordsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_RECORDS_PROFILE':
      return {
        ...state,
        profileId: action.profileId,
      };
    case 'FETCH_FILE_COLUMNS_A':
    case 'REQUEST_ROWS_A':
      return { ...state, fetchingA: true };
    case 'FETCH_FILE_COLUMNS_B':
    case 'REQUEST_ROWS_B':
      return { ...state, fetchingB: true };
    case 'SET_GENERAL_FILTERS':
      return {
        ...state,
        generalFilters: { ...state.generalFilters, ...action.generalFilters },
      };
    case 'SET_FILTERS_A':
      return {
        ...state,
        filtersA: { ...state.filtersA, ...action.filtersA },
      };
    case 'SET_FILTERS_B':
      return {
        ...state,
        filtersB: { ...state.filtersB, ...action.filtersB },
      };
    case 'SET_COLUMNS_A':
      return { ...state, columnsA: action.columnsA };
    case 'SET_COLUMNS_B':
      return { ...state, columnsB: action.columnsB };
    case 'SET_ROWS_A':
      return {
        ...state,
        rowsA: action.rowsA,
        fetchingA: false,
        pagesA: action.pagesA,
      };
    case 'SET_ROWS_B':
      return {
        ...state,
        rowsB: action.rowsB,
        fetchingB: false,
        pagesB: action.pagesB,
      };
    case 'SET_PAGE_A':
      return { ...state, pageA: action.pageA };
    case 'SET_PAGE_B':
      return { ...state, pageB: action.pageB };
    case 'SELECT_ROW_A':
      return { ...state, selectedA: action.newSelected };
    case 'SELECT_ROW_B':
      return { ...state, selectedB: action.newSelected };
    case 'START_MANUAL_MATCHING':
      return {
        ...state,
        manualMatchingStarted: true,
        matchSessionName: action.matchSessionName,
      };
    case 'FINISH_MANUAL_MATCHING':
      return {
        ...state,
        manualMatchingStarted: false,
      };
    case 'ADD_CURRENT_MATCH':
      return {
        ...state,
        matches: [
          ...state.matches,
          {
            dataPoolA: [...state.selectedA],
            dataPoolB: [...state.selectedB],
          },
        ],
        selectedA: [],
        selectedB: [],
      };
    // This section may be need its own reducer
    case 'FETCH_PENDING_REPORTS':
      return {
        ...state,
        fetchingReports: true,
      };
    case 'FETCH_PENDING_REPORTS_SUCCESS':
      return {
        ...state,
        reports: action.reports,
        fetchingReports: false,
      };
    case 'GENERATE_PENDING_REPORT_SUCCESS':
      return {
        ...state,
        reportSuccess: true,
      };
    case 'CLEAN_PENDING_REPORTS_STATE':
      return {
        ...state,
        reportSuccess: false,
      };
    case 'TOGGLE_TABLE_LAYOUT':
      return { ...state, horizontal: !state.horizontal };
    case 'CLEAR_STATE':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default recordsReducer;
