import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { FullScreenEmptyState } from '../../common/EmptyState';
import CruceImg from '../../../img/cruce.svg';
function DefaultScreen({ disabledPage, showOnboarding, t }) {
  if (showOnboarding) {
    return <Redirect to="/onboarding" />;
  }

  return (
    <div>
      {disabledPage && (
        <FullScreenEmptyState
          buttonText={t('request_new_profile')}
          description={t('empty_states.default')}
          redirectTo="/onboarding"
        >
          <img alt="" src={CruceImg} />
        </FullScreenEmptyState>
      )}
    </div>
  );
}
function mapStateToProps(fullState) {
  const { disabledPage, showOnboarding } = fullState.profiles;
  return {
    disabledPage,
    showOnboarding,
  };
}
export default connect(mapStateToProps)(withTranslation()(DefaultScreen));
