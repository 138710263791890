import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';

import App from './App';
import rootReducer from './reducers';

import './index.css';
import thunkMiddleware from 'redux-thunk';

import { showGenericError } from './reducers/genericErrorReducer';

const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const crashReporter = () => (next) => (action) => {
  try {
    const result = next(action);
    if (result.catch) {
      result.catch((err) => {
        setTimeout(() => store.dispatch(showGenericError(err)), 500);
        throw err;
      });
    }

    return result;
  } catch (err) {
    alert(err);
    throw err;
  }
};

// Be sure to ONLY add this middleware in development!
/* const middleware =
  process.env.NODE_ENV !== 'production'
    ? [require('redux-immutable-state-invariant').default(), thunkMiddleware]
    : [thunkMiddleware];
 */
// Note passing middleware as the last argument to createStore requires redux@>=3.1.0
const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(crashReporter, thunkMiddleware))
);

ReactDOM.render(
  <App history={history} store={store} />,
  document.getElementById('root')
);
