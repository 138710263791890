import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@increase/typed-components';
import { BaseProvider } from '@increasecard/increase-core';
import { Routes } from './Routes';
import './i18n';
import UserDispatcher from './UserDispatcher';

const App = ({ store }) => (
  <BaseProvider
    settings={{
      environment: process.env.REACT_APP_STAGE,
      productName: 'concilliation',
      eventTracking: {
        enabled: true,
        mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
        debug: false,
      },
      errorTracking: {
        enabled: false,
      },
    }}
  >
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          {/* This component is meant to be a bridge between BaseProvider Auth and User model.
           * Further refactors on Match User model and the app in general should get rid of this component
           */}
          <UserDispatcher>
            <Routes />
          </UserDispatcher>
        </Router>
      </ThemeProvider>
    </Provider>
  </BaseProvider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
