import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import connect from 'react-redux/es/connect/connect';
import FullPageSpinner from '../common/FullPageSpinner';
import { withRouter } from 'react-router-dom';
import { H2, Paragraph } from '../common/Typography';
import {
  destroyProfile,
  fetchProfiles,
  fetchProfile,
  cloneProfile,
  generateProfileRetry,
  toggleSidebarShow,
  newProfile,
} from '../../reducers/profilesReducer';

import { withTranslation } from 'react-i18next';
import ProfileItem from './ProfileItem';
import IconButton from '../common/IconButton';

import { ChevronLeft } from 'styled-icons/material/ChevronLeft';
import { ChevronRight } from 'styled-icons/material/ChevronRight';

import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
} from '@increase/typed-components';

const NavStyled = styled.div`
  width: 244px;
  position: relative;
  overflow: auto;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  transition: margin-left 200ms ease-in-out;
  /* Black cast */
  box-shadow: 0px 3px 9px rgba(24, 32, 38, 0.18);
  &.closed {
    margin-left: -204px;
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  /* IncreaseWhite Bold */
  color: #b1b1b1;
  /* IncreaseWhite */
  background: #ffffff;
  height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  & .title {
    transition: all 150ms ease-in-out;
    opacity: 1;
    margin-left: 1rem;
  }
  .closed & .title {
    opacity: 0;
    visibility: hidden;
  }
`;

const ScrollableContent = styled.div`
  overflow: auto;
  flex: 1;
  opacity: 1;
  transition: all 150ms ease-in-out;
  .closed & {
    opacity: 0;
    visibility: hidden;
  }
`;

const NewProfileButton = styled(Button)`
  height: 50px;
  width: 100%;
  .closed & {
    opacity: 0;
    visibility: hidden;
  }
`;

const StyledChevronLeft = styled(ChevronLeft)`
  /* IncreaseWhite Bold */
  color: #b1b1b1;
  height: 2rem;
`;

const StyledChevronRight = styled(ChevronRight)`
  /* IncreaseWhite Bold */
  color: #b1b1b1;
  height: 2rem;
`;

const CollapseButton = styled(IconButton)`
  position: absolute;
  right: 0;
  background-color: transparent;
  height: 50px;
  width: 40px;
  margin: 0;
  &:hover svg {
    color: unset;
  }
  &:hover {
    /* IncreaseWhite Medium */
    background: #d8d8d8;
  }
`;

const propTypes = {
  dispatch: PropTypes.func,
  fetchingProfiles: PropTypes.bool,
  history: PropTypes.any,
  profiles: PropTypes.array,
  profilesNavShow: PropTypes.bool,
  user: PropTypes.object,
};

class ProfilesNav extends React.Component {
  state = {
    showDeleteModal: false,
    showRetryModal: false,
    selectedProfile: null,
  };
  componentDidMount() {
    const { onProfilesFetch, match } = this.props;
    onProfilesFetch().then((profiles) => {
      if (!match.params.profileId && !(match.path.indexOf('new') !== -1)) {
        this.selectDefault(profiles);
      }
    });
  }

  selectDefault(profiles) {
    if (profiles && profiles.length > 0) {
      const preselectedProfileId = profiles[0].id;
      this.props.history.replace(
        `/profiles/${preselectedProfileId}/newconciliation`
      );
    }
  }

  handleNewProfile() {
    const { history, user, dispatch } = this.props;
    if (user.masquerading) {
      dispatch(newProfile());
      history.push('/profiles/new');
    } else {
      history.push('/onboarding/files');
    }
  }

  handleProfileDetail(profileId) {
    const { dispatch } = this.props;
    dispatch(fetchProfile(profileId));
    this.props.history.push(`/${profileId}/edit`);
  }

  handleProfileDelete(event) {
    event.stopPropagation();
    const { onProfileDelete, onProfilesFetch, match } = this.props;
    const { selectedProfile } = this.state;
    this.hideModals();
    onProfileDelete(selectedProfile)
      .then(() => onProfilesFetch())
      .then((profiles) => {
        if (selectedProfile === Number(match.params.profileId)) {
          this.selectDefault(profiles);
        }
      })
      .catch((err) => {
        // TODO: Show some kind of notification on errors.
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  handleProfileClone(profileId, event) {
    event.stopPropagation();
    const { onProfileClone, history } = this.props;
    onProfileClone(profileId).then(() => {
      history.push('/profiles/new');
    });
  }

  handleProfileClick(profileId) {
    this.props.history.push(`/profiles/${profileId}/newconciliation`);
  }

  handleShowDeleteModal(profileId, event) {
    event.stopPropagation();
    this.setState({ showDeleteModal: true, selectedProfile: profileId });
  }

  handleShowRetryModal(profileId, event) {
    event.stopPropagation();
    this.setState({ showRetryModal: true, selectedProfile: profileId });
  }

  hideModals() {
    this.setState({
      showDeleteModal: false,
      showRetryModal: false,
      selectedProfile: null,
    });
  }

  handleRetry() {
    const { onGenerateRetry, history } = this.props;
    const { selectedProfile } = this.state;
    this.hideModals();
    onGenerateRetry(selectedProfile)
      .then(() => {
        history.push(`/profiles/${selectedProfile}/reports`);
      })
      .catch((err) => {
        // TODO: Show some kind of notification on errors.
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }

  render() {
    const {
      fetchingProfiles,
      user,
      match,
      onToggleSideBar,
      profiles,
      profilesNavShow,
      t,
    } = this.props;

    return (
      <NavStyled className={!profilesNavShow && 'closed'}>
        <TopBar>
          <span className="title">{t('match_profiles')}</span>
          <CollapseButton onClick={onToggleSideBar}>
            {profilesNavShow ? <StyledChevronLeft /> : <StyledChevronRight />}
          </CollapseButton>
        </TopBar>
        <ScrollableContent>
          {fetchingProfiles ? (
            <FullPageSpinner />
          ) : (
            profiles.map((item, i) => {
              return (
                <ProfileItem
                  id={item.id}
                  key={`${item.id}-${i}`}
                  lastConciliationDate={item.lastConciliationDate}
                  masquerading={user.masquerading}
                  onClick={this.handleProfileClick.bind(this, item.id)}
                  onClone={this.handleProfileClone.bind(this, item.id)}
                  onDestroy={this.handleShowDeleteModal.bind(this, item.id)}
                  onRetry={this.handleShowRetryModal.bind(this, item.id)}
                  profileName={item.name}
                  selected={Number(match.params.profileId) === item.id}
                  stable={item.stable}
                  t={t}
                />
              );
            })
          )}
        </ScrollableContent>
        <Modal
          align="center"
          onClose={this.hideModals.bind(this)}
          visible={this.state.showDeleteModal}
        >
          <H2 style={{ textAlign: 'center' }}>
            {t('confirm_delete_model_title')}
          </H2>
          <ModalContent align="center">
            <Paragraph>{t('confirm_delete_model_message')}</Paragraph>
          </ModalContent>
          <ModalFooter align="center">
            <Button
              buttonType="alert"
              key="delete"
              onClick={this.handleProfileDelete.bind(this)}
            >
              {t('confirm_delete')}
            </Button>
            <Button
              buttonType="invisible"
              key="cancel"
              onClick={this.hideModals.bind(this)}
            >
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          align="center"
          onClose={this.hideModals.bind(this)}
          visible={this.state.showRetryModal}
        >
          <H2 style={{ textAlign: 'center' }}>
            {t('confirm_retry_model_title')}
          </H2>
          <ModalContent align="center">
            <Paragraph>{t('confirm_retry_model_message')}</Paragraph>
          </ModalContent>
          <ModalFooter align="center">
            <Button key="retry" onClick={this.handleRetry.bind(this)}>
              {t('retry')}
            </Button>
            <Button
              buttonType="invisible"
              key="cancel"
              onClick={this.hideModals.bind(this)}
            >
              {t('cancel')}
            </Button>
          </ModalFooter>
        </Modal>

        <NewProfileButton
          buttonType="invisible"
          onClick={this.handleNewProfile.bind(this)}
        >
          {user.masquerading ? t('create_profile') : t('request_new_profile')}
        </NewProfileButton>
      </NavStyled>
    );
  }
}

ProfilesNav.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onProfilesFetch() {
    return dispatch(fetchProfiles());
  },
  onProfileClone(profileId) {
    return dispatch(cloneProfile(profileId));
  },
  onProfileDelete(profileId) {
    return dispatch(destroyProfile(profileId));
  },
  onGenerateRetry(profileId) {
    return dispatch(generateProfileRetry(profileId));
  },
  onToggleSideBar() {
    return dispatch(toggleSidebarShow());
  },
});

function mapStateToProps(fullState) {
  const state = fullState.profiles;

  return {
    fetchingProfiles: state.fetchingProfiles,
    user: fullState.auth.user,
    profiles: state.profiles,
    disabledPage: state.disabledPage,
    profilesNavShow: state.profilesNavShow,
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfilesNav))
);
