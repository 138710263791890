import client from './apiClient';
import Profile from '../models/Profile';
import Structure from '../models/Structure';

function extractData(res) {
  return res.data;
}

function mapData(data) {
  return data.map((profile) => Profile.fromAPI(profile));
}

export function getProfiles(params: {}) {
  return client
    .get('/reconciliation_profiles', params)
    .then(extractData)
    .then(mapData);
}

export function getProfile(profileId) {
  return client
    .get(`/reconciliation_profiles/${profileId}`)
    .then(extractData)
    .then((data) => Profile.fromAPI(data));
}

export function deleteProfile(profileId) {
  return client.delete(`/reconciliation_profiles/${profileId}`);
}

export function saveProfile(profile) {
  if (!profile.id) {
    return Promise.reject('Profile does not have an id');
  }
  return client
    .patch(`/reconciliation_profiles/${profile.id}`, {
      id: profile.id,
      reconciliation_profile: profile,
    })
    .then(extractData)
    .then(Profile.fromAPI);
}

export function createProfile(profile) {
  return client
    .post('/reconciliation_profiles', {
      reconciliation_profile: profile,
    })
    .then(extractData)
    .then(Profile.fromAPI);
}

export function validateProfile(fileKey, structure) {
  const apiStructure = new Structure(structure);
  return client
    .post('structures/validate', {
      file_key: fileKey,
      structure: apiStructure.mapToValidate(),
    })
    .then(extractData);
}

export function generateRetry(profileId) {
  return client
    .post(`/reconciliation_profiles/${profileId}/generate_retry`)
    .then(extractData);
}

export function isConciliating(profileId) {
  return client
    .get(`/reconciliation_profiles/${profileId}/reconciling`)
    .then(extractData);
}

export default { validateProfile };
