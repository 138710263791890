import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'styled-icons/fa-solid';

const StyledDiv = styled.div`
  /* IncreaseWhite Medium */
  border: 1px solid #D8D8D8;
  height: 40px;
  width: 60px;
  position: relative;
  padding-right: ${({ showButtons }) => (showButtons ? '15px' : '0')};
  border-radius: 4px;
  .number-input {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    font-family: Inter, sans-serif;
    /* IncreaseBlack */
    color: #182026;
    padding-left: ${({ prefix }) => (prefix ? '16px' : '7px')};
    outline: none;
    font-size: 0.825rem;
  }
  :focus {
    /* IncreaseBlack */
    border: 1px solid #182026;
  }
  :before {
    content: '${({ prefix }) => prefix && prefix}';
    font-size: 0.625rem;
    position: absolute;
    height: 100%;
    left: 7px;
    top: 13px;
  }
`;

const ArrowButton = styled.button`
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f5f5f5;
  position: absolute;
  width: 15px;
  height: 15px;
  padding: 0;
  outline: none;

  svg {
    width: 7px;
    height: 100%;
    /* IncreaseBlack Light */
    color: #44494d;
  }
  &:hover svg {
    /* Sky */
    color: #0f6bff;
  }
  &:active {
    /* IncreaseWhite Bold */
    background-color: #b1b1b1;
  }
  &.up {
    top: 3px;
    right: 3px;
  }
  &.down {
    bottom: 3px;
    right: 3px;
  }
`;

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value,
      isFocused: false,
    };
  }

  onChange(e) {
    if (e.target.validity.valid) {
      this.setState({ inputValue: e.target.value });
    }
  }

  commitChange() {
    this.props.onChange(parseFloat(this.state.inputValue));
    this.setState((state) => ({
      inputValue: parseFloat(state.inputValue),
      isFocused: false,
    }));
  }

  setValue(val) {
    this.setState(
      { inputValue: Math.max(val, this.props.min) },
      this.commitChange
    );
  }

  inputValue() {
    const value = this.state.inputValue;
    return value && value.toString();
  }

  increaseInputValue() {
    const value = this.inputValue();
    const step = this.props.step;
    if (value) {
      return (parseFloat(value) + step).toFixed(this.props.fixed).toString();
    }
    return step;
  }

  decreaseInputValue() {
    const value = this.inputValue();
    const step = this.props.step;
    if (value) {
      return (parseFloat(value) - step).toFixed(this.props.fixed).toString();
    }
    return step;
  }

  wheelHandler(e) {
    if (this.state.isFocused) {
      if (e.deltaY > 0) {
        this.setValue(this.increaseInputValue());
      } else if (e.deltaY < 0) {
        this.setValue(this.decreaseInputValue());
      }
    }
  }

  focusHandler() {
    this.setState({ isFocused: true });
  }

  render() {
    return (
      <StyledDiv
        className={this.props.className}
        prefix={this.props.prefix}
        showButtons={this.props.showButtons}
      >
        <input
          className="number-input"
          disabled={this.props.disabled}
          onBlur={this.commitChange.bind(this)}
          onChange={this.onChange.bind(this)}
          onFocus={this.focusHandler.bind(this)}
          onWheel={this.wheelHandler.bind(this)}
          pattern="[0-9]*(\.?[0-9]{0,2})?"
          step={this.props.step}
          type="text"
          value={this.inputValue()}
        />
        {this.props.showButtons && (
          <React.Fragment>
            <ArrowButton
              className="up"
              disabled={this.props.disabled}
              onClick={this.setValue.bind(this, this.increaseInputValue())}
            >
              <ChevronUp />
            </ArrowButton>
            <ArrowButton
              className="down"
              disabled={this.props.disabled}
              onClick={this.setValue.bind(this, this.decreaseInputValue())}
            >
              <ChevronDown />
            </ArrowButton>
          </React.Fragment>
        )}
      </StyledDiv>
    );
  }
}

NumberInput.defaultProps = {
  step: 1,
  showButtons: true,
};

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  fixed: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  showButtons: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default NumberInput;
