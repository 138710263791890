import DataPool from './DataPool';
import Scanning from './Scanning';

export default class Profile {
  constructor({
    id,
    name = '',
    deprecationDays = 90,
    reconciliationType = 'file',
    stable = false,
    originalStable,
    conciliationsCount,
    lastConciliationDate,
    dataPoolA = new DataPool({}),
    dataPoolB = new DataPool({}),
    profileRequestId,
    scannings = [],
  }) {
    this.id = id;
    this.name = name;
    this.deprecationDays = deprecationDays;
    this.reconciliationType = reconciliationType;
    this.stable = stable;
    this.originalStable = originalStable;
    this.profileRequestId = profileRequestId;
    this.conciliationsCount = conciliationsCount;
    this.lastConciliationDate = lastConciliationDate;
    this.dataPoolA = dataPoolA;
    this.dataPoolB = dataPoolB;
    this.scannings = scannings;
  }

  static fromAPI({
    id,
    name,
    deprecation_days: deprecationDays,
    reconciliation_type: reconciliationType,
    stable,
    profile_request_id: profileRequestId,
    conciliations_count: conciliationsCount,
    last_conciliation_date: lastConciliationDate,
    data_pool_a: dataPoolAFromAPI,
    data_pool_b: dataPoolBFromAPI,
    scannings: scanningsFromAPI,
    billing_data_pool: billingDataPool,
  }) {
    if (
      !dataPoolAFromAPI.abstract_fields ||
      !dataPoolBFromAPI.abstract_fields ||
      dataPoolAFromAPI.abstract_fields.length === 0 ||
      dataPoolBFromAPI.abstract_fields.length === 0
    ) {
      return { name: `NO MIGRADO ${name}` };
    }
    const dataPoolA = DataPool.fromAPI(dataPoolAFromAPI);
    const dataPoolB = DataPool.fromAPI(dataPoolBFromAPI);
    const scannings = scanningsFromAPI.map((scanning) => {
      return Scanning.fromAPI(scanning, dataPoolA.abstractFields);
    });
    return {
      id,
      name,
      deprecationDays,
      reconciliationType,
      stable,
      originalStable: stable,
      profileRequestId,
      conciliationsCount,
      lastConciliationDate,
      dataPoolA,
      dataPoolB,
      scannings,
      billingDataPool,
    };
  }

  static toAPI({
    id,
    name,
    reconciliationType,
    deprecationDays,
    stable,
    profileRequestId,
    dataPoolA,
    dataPoolB,
    scannings,
  }) {
    return {
      id: id,
      name: name,
      reconciliation_type: reconciliationType,
      deprecation_days: deprecationDays,
      stable: stable,
      profile_request_id: profileRequestId,
      data_pool_a: DataPool.toAPI(dataPoolA),
      data_pool_b: DataPool.toAPI(dataPoolB),
      // Use datapoolA abstract fields because they are the same for each datapool ¯\_(ツ)_/¯
      scannings: scannings.map((scanning) =>
        Scanning.toAPI(scanning, dataPoolA.abstractFields)
      ),
    };
  }
}
