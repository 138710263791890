import React from 'react';
import styled from 'styled-components';
import { Button, Stepper, StepperStep } from '@increase/typed-components';

const WizardContainer = styled.div`
  min-height: 100vh;
  min-width: 370px;
  width: 370px;
  padding: 2rem;
  background-color: white;
  border-right: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .wizard-logo {
    margin-bottom: 4rem;
  }
`;
const getStepStatus = (activeStep, step) => {
  if (activeStep === step) {
    return 'current';
  } else if (activeStep > step) {
    return 'done';
  }
  return 'pending';
};

function Wizard({ logo, activeStep, stepList, onClose, closeLabel }) {
  return (
    <WizardContainer>
      <div>
        {logo && <img alt="Logo" className="wizard-logo" src={logo} />}
        <Stepper>
          {stepList.map((step, i) => {
            return (
              <StepperStep
                description={step.text}
                key={i}
                status={getStepStatus(activeStep, i)}
                title={step.label}
              />
            );
          })}
        </Stepper>
      </div>
      {onClose && (
        <Button buttonType="invisible" onClick={onClose}>
          {closeLabel}
        </Button>
      )}
    </WizardContainer>
  );
}

export default Wizard;
