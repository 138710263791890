import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H2, InputLabel, CellText } from '../Typography';
import NumberInput from '../NumberInput';
import Select from '../Select';
import LinkButton from '../LinkButton';
import AliasConfigModal from './AliasConfigModal';

const MatchFieldsTable = styled.div`
  width: 420px;
  & .row {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
  }
  & .row:not(:first-child) {
    padding: 10px 0;
  }
  & .row:not(:first-child):not(:last-child) {
    border-bottom: 1px solid #b1b1b1;
  }
`;

const FieldConfig = ({ field, dateFormats, disabled, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const toggleModal = () => setShowModal(!showModal);

  switch (field.dataType) {
    case 'money':
      return (
        <Select
          disabled={disabled}
          onSelect={(value) => onChange({ centsSeparator: value })}
          options={t('money_separators', {
            returnObjects: true,
          })}
          selected={field.centsSeparator}
        />
      );
    case 'date':
      return (
        <Select
          disabled={disabled}
          onSelect={(value) => onChange({ dateFormat: value })}
          options={dateFormats}
          selected={field.dateFormat}
        />
      );
    case 'last_n':
    case 'first_n':
      return (
        <NumberInput
          disabled={disabled}
          min={1}
          onChange={(value) => onChange({ stringOffset: value })}
          value={field.stringOffset}
        />
      );
    case 'string':
      return (
        <React.Fragment>
          <AliasConfigModal
            aliases={field.comparators}
            onCancel={toggleModal}
            onSave={(value) => {
              onChange({ comparators: value });
              toggleModal();
            }}
            readOnly={disabled}
            show={showModal}
          />
          <LinkButton onClick={toggleModal}>{t('alias_config')}</LinkButton>
        </React.Fragment>
      );
    default:
      return null;
  }
};

const MatchFieldsConfigTable = ({
  fields,
  dateFormats,
  commonSeparators,
  onChangeField,
  disabled,
  reconciliationType,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <H2>{t('profile_creator.match_fields')}</H2>
      <MatchFieldsTable>
        <div className="row">
          <InputLabel>{t('name')}</InputLabel>
          <InputLabel>{t('fields.column')}</InputLabel>
          <InputLabel>{t('fields.configuration')}</InputLabel>
        </div>
        {Object.entries(fields).map(([id, field]) => {
          return (
            <div className="row field-header" key={id}>
              <div className="field-name">
                <CellText weight={'bold'}>
                  {reconciliationType === 'file'
                    ? field.name
                    : t(`constants:increasecard_fields.${field.name}`)}
                </CellText>
              </div>
              <div className="column-number">
                <NumberInput
                  disabled={disabled}
                  min={1}
                  onChange={(value) => onChangeField(id, { location: value })}
                  value={field.location}
                />
              </div>
              <div className="column-number">
                <FieldConfig
                  aliases={field.comparators}
                  dateFormats={dateFormats}
                  delimiters={commonSeparators}
                  disabled={disabled}
                  field={field}
                  onChange={(changes) => onChangeField(id, changes)}
                />
              </div>
            </div>
          );
        })}
      </MatchFieldsTable>
    </div>
  );
};

export default MatchFieldsConfigTable;
