import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parseJSON from 'date-fns/parseJSON';
import { FileDownload } from 'styled-icons/material';
import {
  Table,
  TableWrapper,
  THead,
  Th,
  TableRow,
  Td,
  Pagination,
} from '@increase/typed-components';
import LinkButton from '../../common/LinkButton';
import { withTranslation } from 'react-i18next';
import FullPageSpinner from '../../common/FullPageSpinner';
import { formatDate } from '../../../helpers/helpers';

const DownloadIcon = styled(FileDownload)`
  width: 22px;
  height: 22px;
  color: ${({ theme }) => theme.colors.brandGreen.regular};
`;

const FixedHeightWrapper = styled(TableWrapper)`
  height: 100%;
  overflow: auto;
  margin: 1rem 0;
`;

function FilesTableRow({ file, onDownloadClicked, t, showIdColumn }) {
  return (
    <TableRow>
      <Td>{formatDate(parseJSON(file.createdAt))}</Td>
      <Td>{file.filename}</Td>
      {showIdColumn && <Td>{file.fileableId}</Td>}
      <Td rightAligned>
        <DownloadIcon />
        <LinkButton
          onClick={() => {
            onDownloadClicked(file.id);
          }}
        >
          {t('download_file')}
        </LinkButton>
      </Td>
    </TableRow>
  );
}

FilesTableRow.propTypes = {
  file: PropTypes.object,
  onDownloadClicked: PropTypes.func,
  showIdColumn: PropTypes.bool,
};

function FilesTable({
  loading,
  data,
  onDownloadClicked,
  t,
  currentPage,
  totalPages,
  onPageSelect,
  showIdColumn,
}) {
  return (
    <>
      <FixedHeightWrapper>
        <Table zebra>
          <colgroup>
            <col span="1" style={{ width: '15%' }} />
            <col span="1" />
            {showIdColumn && <col span="1" style={{ width: '35%' }} />}
            <col span="1" style={{ width: '20%' }} />
          </colgroup>
          <THead>
            <tr>
              <Th>{t('uploaded_date')}</Th>
              <Th>{t('filename')}</Th>
              {showIdColumn && <Th>{t('reference_id')}</Th>}
              <Th />
            </tr>
          </THead>
          <tbody>
            {!loading &&
              data &&
              data.map((val, i) => {
                return (
                  <FilesTableRow
                    file={val}
                    key={i}
                    onDownloadClicked={onDownloadClicked}
                    showIdColumn={showIdColumn}
                    t={t}
                  />
                );
              })}
          </tbody>
        </Table>

        {loading && <FullPageSpinner />}
      </FixedHeightWrapper>
      <Pagination
        currentPage={currentPage}
        onSelectPage={onPageSelect}
        style={{ justifyContent: 'flex-end' }}
        totalPages={totalPages}
      />
    </>
  );
}

FilesTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onDownloadClicked: PropTypes.func,
  showIdColumn: PropTypes.bool,
};

export default withTranslation()(FilesTable);
