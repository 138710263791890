import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  & {
    height: 100%;
  }
  div.label-container {
    span {
      color: #61687c;
      font-family: Inter, sans-serif;
      font-size: 13px;
    }
  }
`;

function ComponentLabel({ className, children, label }) {
  return (
    <StyledContainer className={className}>
      <div className="label-container" style={{ paddingBottom: '8px' }}>
        <span>{label}</span>
      </div>
      <div>{children}</div>
    </StyledContainer>
  );
}

ComponentLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string,
  ]),
  label: PropTypes.string,
};

ComponentLabel.defaultProps = {};

export default ComponentLabel;
