import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { withTranslation } from 'react-i18next';
import { InputText } from '@increase/typed-components';
import { fetchUploadedFiles } from '../../../reducers/uploadedFilesReducer';
import { getFileLink } from '../../../services/uploadedFilesService';
import { H1 } from '../../common/Typography';
import FilesTable from './filesTable';

class UploadedFiles extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUploadedFiles());
    this.changeFilter.bind(this);
  }

  onDownloadClicked(fileId) {
    getFileLink(fileId).then((link) => window.open(link));
  }

  changeFilter(filter, value) {
    this.props.onFilterChange({ ...this.props.filters, [filter]: value });
    this.debouncedFetch();
  }

  debouncedFetch = debounce(this.props.onFetchFiles, 300);

  render() {
    const { fetching, uploadedFiles, t, masquerading } = this.props;
    return (
      <React.Fragment>
        <H1>{t('uploaded_files')}</H1>
        <InputText
          label={t('search_file')}
          onChange={(e) => this.changeFilter('filename', e.target.value)}
          placeholder={t('file_name')}
          value={this.props.filters.filename}
          width="300px"
        />
        <FilesTable
          currentPage={this.props.currentPage}
          data={uploadedFiles}
          loading={fetching}
          onDownloadClicked={this.onDownloadClicked.bind(this)}
          onSelectPage={this.props.onPageSelect}
          showIdColumn={masquerading}
          totalPages={this.props.pages}
        />
      </React.Fragment>
    );
  }
}

UploadedFiles.propTypes = {
  currentPage: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    filename: PropTypes.string,
  }).isRequired,
  masquerading: PropTypes.bool,
  onPageSelect: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onPageSelect: (page) => {
    dispatch({ type: 'SET_PAGE', page });
    return dispatch(fetchUploadedFiles());
  },
  onFilterChange: (filters) => {
    dispatch({ type: 'SET_FILTERS', filters });
  },
  onFetchFiles: () => dispatch(fetchUploadedFiles()),
});

function mapStateToProps(fullState) {
  const {
    uploadedFiles,
    currentPage,
    fetching,
    filters,
    pages,
  } = fullState.uploadedFiles;
  return {
    uploadedFiles,
    currentPage,
    pages,
    fetching,
    filters,
    masquerading: fullState.auth.user.masquerading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UploadedFiles));
