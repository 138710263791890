import React, { useEffect } from 'react';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H1, Paragraph } from '../../common/Typography';
import TextInput from '../../common/TextInput';
import LinkButton from '../../common/LinkButton';
import StructureEditor from '../../common/StructureEditor/StructureEditor';
import {
  profileUpdate,
  fetchProfiles,
} from '../../../reducers/profilesReducer';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import {
  addStructure,
  removeStructure,
  validateProfile,
  updateFieldKey,
  updateStructureProp,
  addIgnoredLine,
  deleteIgnoredLine,
  changeIgnoredLine,
  updateInformativeField,
  clearValidationSateById,
} from '../../../reducers/structuresReducer';

import {
  addAbstractInformativeField,
  deleteAbstractInformativeField,
  updateAbstractInformativeField,
} from '../../../reducers/dataPoolsReducer';

// Change path when "view details" pr is approved
import {
  mapCommonSeparatorsForSelect,
  mapComparationTypesForSelect,
  mapDataTypesForSelect,
  mapDateFormatsForSelect,
} from '../../../helpers/profileHelpers';

const StructuresWrapper = styled.div`
  & > div {
    margin-bottom: 0.815rem;
  }
  margin-bottom: 1rem:
`;

const AbstractInformativeFields = styled.div`
  width: 315px;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .table-header {
    height: 36px;
  }
  & .field-row {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  & .field-row {
    /* IncreaseWhite Bold */
    border-bottom: 1px solid #b1b1b1;
  }
  & > div button {
    margin-top: 1rem;
  }
`;

const DataPoolConfig = ({
  dispatch,
  fileStructureCached,
  dataPoolName,
  match,
  onPropChange,
  profile,
  structures,
  structuresKey,
  onAddIgnoredLine,
  onDeleteIgnoredLine,
  onIgnoredLineChange,
  onAddStructure,
  onChangeField,
  abstractInformativeFields,
  onAddAbstractInformativeField,
  onDeleteAbstractInformativeField,
  onUpdateAbstractInformativeField,
  onChangeInformativeField,
  onClearValidationState,
  onValidate,
  validationResults,
  ...rest
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (!fileStructureCached) {
      dispatch(fetchStructureMetadata());
    }
  }, [dispatch, match, fileStructureCached]);

  return (
    <div>
      <H1>
        {t('profile_creator.data_pool_config', {
          dataPoolName: dataPoolName || 'Banco A',
        })}
      </H1>
      <Paragraph>{t('profile_creator.data_pool_config_description')}</Paragraph>
      <AbstractInformativeFields>
        {Object.entries(abstractInformativeFields).map(([id, field]) => {
          return (
            <div className="field-row" key={id}>
              <TextInput
                disabled={profile.originalStable}
                label={t('fields.name')}
                onChange={(value) => {
                  onUpdateAbstractInformativeField(id, { field: value });
                }}
                value={field.field}
              />
              <LinkButton
                disabled={profile.originalStable}
                onClick={() => onDeleteAbstractInformativeField(id)}
                type="alert"
              >
                {t('delete')}
              </LinkButton>
            </div>
          );
        })}
      </AbstractInformativeFields>
      <LinkButton
        disabled={profile.originalStable}
        onClick={onAddAbstractInformativeField}
      >
        {t('profile.new_field')}
      </LinkButton>
      <H1>{t('profile.file_structures')}</H1>
      <Paragraph>
        {t('profile_creator.data_pool_config_structures_description')}
      </Paragraph>
      <StructuresWrapper>
        {structures.map((structure, index) => {
          return (
            <StructureEditor
              disabled={profile.originalStable}
              key={`${index}-${structure.id || 'structure'}`}
              onAddIgnoredLine={() => onAddIgnoredLine(index)}
              onChangeField={(fieldId, changes) =>
                onChangeField(index, fieldId, changes)
              }
              onChangeIgnoredLine={(line, changes) =>
                onIgnoredLineChange(line, changes, index)
              }
              onClearValidationState={() =>
                onClearValidationState(`${structuresKey}-${index}`)
              }
              onDeleteIgnoredLine={(line) => onDeleteIgnoredLine(index, line)}
              onInformativeFieldChange={(fieldId, changes) =>
                onChangeInformativeField(index, fieldId, changes)
              }
              onPropChange={(prop, value) => onPropChange(index, prop, value)}
              onValidate={(file) => onValidate(index, file)}
              structure={structure}
              structureIndex={index}
              validationResults={validationResults[`${structuresKey}-${index}`]}
              {...rest}
            />
          );
        })}
      </StructuresWrapper>

      <LinkButton disabled={profile.originalStable} onClick={onAddStructure}>
        {t('profile.add_new_structure')}
      </LinkButton>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,

  onPropChange: (structureIndex, prop, value) =>
    dispatch(
      updateStructureProp(ownProps.structuresKey, structureIndex, {
        [prop]: value,
      })
    ),
  onAddIgnoredLine: (structureIndex) => {
    dispatch(addIgnoredLine(ownProps.structuresKey, structureIndex));
  },
  onDeleteIgnoredLine: (structureIndex, line) => {
    dispatch(deleteIgnoredLine(ownProps.structuresKey, structureIndex, line));
  },
  onIgnoredLineChange: (structureIndex, line, changes) => {
    dispatch(
      changeIgnoredLine(ownProps.structuresKey, structureIndex, line, changes)
    );
  },
  onStableChange: (stable) => dispatch(profileUpdate('stable', stable)),

  onValidate: (structureIndex, file) => {
    dispatch(validateProfile(ownProps.structuresKey, structureIndex, file));
  },
  onClearValidationState: (validationId) => {
    dispatch(clearValidationSateById(validationId));
  },
  onProfilesFetch: () => dispatch(fetchProfiles()),
  onAddStructure: () => dispatch(addStructure(ownProps.structuresKey)),
  onRemoveStructure: (structureId) => {
    dispatch(removeStructure(ownProps.structuresKey, structureId));
  },
  onChangeField: (structureIndex, fieldId, changes) => {
    dispatch(
      updateFieldKey(ownProps.structuresKey, structureIndex, fieldId, changes)
    );
  },
  onAddAbstractInformativeField: () =>
    dispatch(addAbstractInformativeField(ownProps.dataPoolKey)),
  onDeleteAbstractInformativeField: (fieldId) =>
    dispatch(deleteAbstractInformativeField(ownProps.dataPoolKey, fieldId)),
  onUpdateAbstractInformativeField: (id, changes) =>
    dispatch(updateAbstractInformativeField(ownProps.dataPoolKey, id, changes)),
  onChangeInformativeField: (structureIndex, fieldId, changes) =>
    dispatch(
      updateInformativeField(
        ownProps.structuresKey,
        structureIndex,
        fieldId,
        changes
      )
    ),
});

function mapStateToProps(fullState, ownProps) {
  const {
    commonSeparators,
    dataTypes,
    fetching: fileStructureFetching,
    cached: fileStructureCached,
    dateFormats,
    comparationTypes,
  } = fullState.fileStructure;
  const { fetching: profileFetching, profile, profileId } = fullState.profiles;
  const {
    validationErrors,
    validating,
    validationResults,
  } = fullState.structures;
  const dataPool = fullState.dataPools[ownProps.dataPoolKey];
  const dataPoolName = dataPool.name;
  const abstractInformativeFields = dataPool.abstractInformativeFields;
  const structures = fullState.structures[ownProps.structuresKey];

  return {
    profileId,
    commonSeparators: mapCommonSeparatorsForSelect(commonSeparators),
    dataTypes: mapDataTypesForSelect(dataTypes),
    dateFormats: mapDateFormatsForSelect(dateFormats),
    comparationTypes: mapComparationTypesForSelect(comparationTypes),
    reconciliationType: profile.reconciliationType,
    validationErrors,
    validating,
    fields: fullState.structures.fields,
    fetching: profileFetching || fileStructureFetching,
    profile,
    fileStructureCached,
    validationResults,
    structures,
    dataPoolName,
    abstractInformativeFields,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataPoolConfig);
