import { countryToLangCode } from '../helpers/helpers';
export default class User {
  constructor({
    firstName,
    lastName,
    email,
    owner,
    accountId,
    country,
    createdAt,
    activeProducts = {},
    subscription,
    masquerading,
  }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.country = country;
    this.email = email;
    this.owner = owner;
    this.accountId = accountId;
    this.activeProducts = activeProducts;
    this.masquerading = masquerading;
    this.createdAt = new Date(createdAt);
    this.langCode = countryToLangCode(country);
    this.subscription = subscription;
  }

  get displayName() {
    if (this.firstName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return this.email;
  }

  usesCard() {
    return this.activeProducts[this.country].includes('card');
  }

  identifyWithAppcues() {
    window.AppcuesReady(() =>
      window.Appcues.identify(this.accountId, {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        created_at: this.createdAt.toLocaleDateString('es-AR'),
        country: this.country,
      })
    );
  }

  static fromAPI({
    first_name: firstName,
    last_name: lastName,
    default_country: country,
    email,
    owner,
    account_id: accountId,
    created_at: createdAt,
    authorizations,
    subscriptions,
    real_user,
  }) {
    const subscription =
      subscriptions.find((s) => s.product.name === 'conciliation') || {};
    const activeProducts = authorizations.reduce(
      (products, authorization) => {
        products[authorization.product_country] =
          products[authorization.product_country] || [];
        products[authorization.product_country] = [
          ...products[authorization.product_country],
          authorization.product_name,
        ];

        return products;
      },
      { ecuador: [], argentina: [], dominicana: [] }
    );
    return new User({
      firstName,
      lastName,
      country,
      email,
      owner,
      accountId,
      createdAt,
      activeProducts,
      subscription,
      masquerading: !!real_user,
    });
  }
}
