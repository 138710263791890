import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LinkButton from '../LinkButton';
import TextInput from '../TextInput';
import Table from '../Table';

const IgnoreLinesTable = styled(Table)`
  table-layout: fixed;
  background-color: none;
  margin-bottom: 1rem;
  th,
  td {
    text-align: left;
  }
  & .column-number {
    width: 30%;
  }
  & .content {
    width: 50%;
  }
  $ .delete {
    width: 20%;
  }
`;

const IgnoreLinesConfig = ({
  // eslint-disable-next-line no-unused-vars
  disable,
  lines,
  onDeleteLine,
  onAddLine,
  onChangeLine,
  // eslint-disable-next-line no-unused-vars
  availableFields,
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <IgnoreLinesTable>
        <colgroup>
          <col className="column-number" />
          <col className="content" />
        </colgroup>
        <thead>
          <tr>
            <th>{t('profile.ignore_lines_in')}</th>
            <th>{t('content')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {lines.map((line, index) => {
            return (
              <tr key={index}>
                <td>
                  <TextInput
                    fullWidth={true}
                    onChange={(position) => onChangeLine(line, { position })}
                    value={line.position}
                  />
                </td>
                <td>
                  <TextInput
                    fullWidth={true}
                    onChange={(value) => onChangeLine(line, { value })}
                    value={line.value}
                  />
                </td>
                <td>
                  <LinkButton onClick={() => onDeleteLine(line)} type="alert">
                    {t('delete')}
                  </LinkButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </IgnoreLinesTable>
      <LinkButton onClick={onAddLine}>
        {t('profile.new_line_to_ignore')}
      </LinkButton>
    </React.Fragment>
  );
};

IgnoreLinesConfig.propTypes = {
  availableFields: PropTypes.array,
  disable: PropTypes.bool,
  lines: PropTypes.array,
  onAddLine: PropTypes.func,
  onChangeLine: PropTypes.func,
  onDeleteLine: PropTypes.func,
};

export default IgnoreLinesConfig;
