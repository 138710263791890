import axios from 'axios';
import User from '../models/User';

const platformClient = axios.create({
  baseURL: process.env.REACT_APP_PLATFORM_API_URL,
  withCredentials: true,
});

export async function me() {
  const response = await platformClient.get('v1/users/me');
  const user = User.fromAPI(response.data);
  user.identifyWithAppcues();
  return user;
}

export async function getProducts() {
  return platformClient
    .get('v1/products')
    .then((response) => response.data.products);
}
