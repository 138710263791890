import AbstractField from './AbstractField';
import connect from 'react-redux/es/connect/connect';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import React from 'react';
import { H1, Paragraph } from '../../common/Typography';
import styled from 'styled-components';
import LinkButton from '../../common/LinkButton';
import Select from '../../common/Select';
import {
  newAbstractField,
  deleteAbstractField,
  updateAbstractField,
  setReferenceField,
} from '../../../reducers/dataPoolsReducer';
import { onUpdateCardParentField } from '../../../reducers/structuresReducer';

const HR = styled.hr`
  margin-top: 20px;
`;

const MatchFields = ({
  abstractFields,
  readOnly,
  dataTypes,
  onCardLocationChange,
  onNewAbstractField,
  onDeleteAbstractField,
  onUpdateAbstractField,
  onReferenceFieldChange,
  vsCard,
  referenceField,
  referenceFields,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <H1>{t('profile_creator.match_fields')}</H1>
      <Paragraph>{t('profile_creator.match_fields_description')}</Paragraph>
      {Object.entries(abstractFields).map(([identifier, abstractField]) => {
        return (
          <React.Fragment key={identifier}>
            <AbstractField
              abstractField={abstractField}
              cardLocation={abstractField}
              dataTypes={dataTypes}
              disabled={readOnly}
              idx={identifier}
              onCardLocationChange={onCardLocationChange}
              onChange={onUpdateAbstractField}
              onDeleteField={onDeleteAbstractField}
              vsCard={vsCard}
            />
            <HR />
          </React.Fragment>
        );
      })}
      {!readOnly && (
        <LinkButton onClick={onNewAbstractField}>
          {t('profile.new_field')}
        </LinkButton>
      )}
      {
        <React.Fragment>
          <H1>{t('profile_creator.reference_field')}</H1>
          <Paragraph>
            {t('profile_creator.reference_field_description')}
          </Paragraph>
          <Select
            disabled={readOnly}
            label={t('profile_creator.select_reference_field')}
            onSelect={onReferenceFieldChange}
            options={referenceFields}
            placeholder={t('constants:other_types')}
            selected={referenceField}
            width="218px"
          />
        </React.Fragment>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onCardLocationChange: (idx, value) => {
    dispatch(updateAbstractField(idx, 'cardLocation', value));
    return dispatch(onUpdateCardParentField(idx, 'location', value));
  },
  onNewAbstractField: (abstractField) => {
    return dispatch(newAbstractField(abstractField));
  },
  onDeleteAbstractField: (idx) => {
    return dispatch(deleteAbstractField(idx));
  },
  onUpdateAbstractField: (idx, prop, value) => {
    return dispatch(updateAbstractField(idx, prop, value));
  },
  onReferenceFieldChange: (newReferenceField) => {
    return dispatch(setReferenceField(newReferenceField));
  },
});

function mapStateToProps(fullState) {
  const { abstractFields, referenceField } = fullState.dataPools;
  const { user } = fullState.auth;
  const { profile, vsCard } = fullState.profiles;
  const readOnly = !user.masquerading || profile.originalStable;
  const dataTypes = vsCard
    ? fullState.fileStructure.increasecardFields[profile.reconciliationType]
    : fullState.fileStructure.dataTypes;
  const referenceFields = Object.values(abstractFields)
    .filter((i) => i.dataType === 'date')
    .map((item) => ({
      value: item.name,
      name: vsCard
        ? i18n.t(`constants:increasecard_fields.${item.name}`)
        : item.name,
    }));
  referenceFields.unshift({ name: i18n.t('uploaded_date'), value: null });
  return {
    referenceField,
    abstractFields,
    referenceFields,
    readOnly: readOnly,
    dataTypes: dataTypes || [],
    vsCard,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchFields);
