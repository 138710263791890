import React from 'react';
import styled from 'styled-components';
import Scanning from './Scanning';
import PropTypes from 'prop-types';
import { H2 } from './Typography';
import ScanningIcon from '../../img/cruce.svg';

const ScanningsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  overflow-x: auto;
  padding: 1rem 0;
`;

const NewScanningButton = styled.button`
  outline: none;
  flex-shrink: 0;
  width: 195px;
  min-height: 370px;
  height: auto;
  /* Background NotSoWhite */
  background: #fbfbfb;
  /* IncreaseWhite Medium */
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 9px;
  cursor: pointer;
  /* IncreaseBlack Light */
  color: #44494d;

  text-align: center;
  transition: all 150ms ease-in;
  ${H2}, svg path {
    transition: all 150ms ease-in;
  }
  :hover,
  :focus,
  :hover ${H2}, :focus ${H2} {
    /* Sky */
    color: #0f6bff;
    border-color: #0f6bff;
  }
  :hover svg path,
  :focus svg path {
    fill: #0f6bff;
  }
`;

function Scannings({
  fields,
  comparationTypes,
  vsCard,
  editMode,
  onDeleteScanning,
  onRuleChange,
  onToggleRule,
  onAddScanning,
  scannings,
  t,
}) {
  let cardFields;
  if (vsCard) {
    cardFields = Object.fromEntries(
      Object.entries(fields).map(([id, field]) => {
        return [
          id,
          {
            name: t(`constants:increasecard_fields.${field.name}`),
            dataType: field.dataType,
          },
        ];
      })
    );
  }
  return (
    <ScanningsWrapper>
      {scannings.map((scanning, i) => (
        <Scanning
          comparationTypes={comparationTypes}
          editMode={editMode}
          fields={cardFields || fields}
          key={i}
          onChange={onRuleChange && onRuleChange.bind(null, i)}
          onDelete={onDeleteScanning && onDeleteScanning.bind(null, i)}
          onToggleRule={onToggleRule && onToggleRule.bind(null, i)}
          scanningFields={scanning.scanningFields}
          scanningNumber={scanning.number}
          t={t}
        />
      ))}
      {editMode && (
        <NewScanningButton onClick={onAddScanning}>
          <img alt="scanning icon" src={ScanningIcon} />
          <H2>{t('profile.scanning.add')}</H2>
        </NewScanningButton>
      )}
    </ScanningsWrapper>
  );
}

Scannings.propTypes = {
  comparationTypes: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  onAddScanning: PropTypes.func,
  onDeleteScanning: PropTypes.func,
  onRuleChange: PropTypes.func,
  onToggleRule: PropTypes.func,
};

Scannings.defaultProps = {
  editMode: true,
  onAddScanning: null,
  onDeleteScanning: null,
  onRuleChange: null,
  onToggleRule: null,
};

export default Scannings;
