const INITIAL_STATE = {
  globalError: null,
  showModal: false,
};

export function showGenericError(error) {
  return { type: 'SHOW_GENERIC_ERROR', error };
}
export function hideGenericError() {
  return { type: 'HIDE_GENERIC_ERROR' };
}
const genericErrorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SHOW_GENERIC_ERROR':
      return { genericError: action.error, showModal: true };
    case 'HIDE_GENERIC_ERROR':
      return { genericError: null, showModal: false };
    default:
      return state;
  }
};

export default genericErrorReducer;
