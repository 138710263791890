import client from './apiClient';
import s3Client from './s3Client';
const noop = () => {};

function _getUploadLink() {
  return client.get('/files/upload_link').then((res) => {
    return res.data;
  });
}

function _uploadToS3(file, uploadInfo, onProgress) {
  const formData = new FormData();
  formData.append('key', uploadInfo.key);
  formData.append('x-amz-credential', uploadInfo['x-amz-credential']);
  formData.append('x-amz-date', uploadInfo['x-amz-date']);
  formData.append('x-amz-signature', uploadInfo['x-amz-signature']);
  formData.append('x-amz-algorithm', uploadInfo['x-amz-algorithm']);
  if (uploadInfo['x-amz-security-token']) {
    formData.append('x-amz-security-token', uploadInfo['x-amz-security-token']);
  }
  formData.append('policy', uploadInfo.policy);
  formData.append('Expires', uploadInfo['Expires']);
  formData.append(
    'Content-Type',
    uploadInfo['Content-Type'] || 'multipart/form-data'
  );
  formData.append('file', file);
  return s3Client
    .post(uploadInfo['base_url'], formData, {
      headers: {
        'Content-Type': uploadInfo['Content-Type'],
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress(percentCompleted);
      },
    })
    .then(() => {
      return uploadInfo.key;
    });
}

function uploadFile(file, onProgress = noop) {
  return _getUploadLink().then((uploadInfo) => {
    return _uploadToS3(file, uploadInfo, onProgress);
  });
}

export default {
  uploadFile,
};
