import client from './apiClient';
import {
  objectKeysToCamelCase,
  objectKeysToUnderscore,
  extractData,
  formatDateRange,
} from '../helpers/helpers';

const rowsPathsByConciliationType = {
  movements: '/increase_movements',
  payments: '/increase_payments',
  file: '/file_rows',
};

const rowsKeysByConciliationType = {
  movements: 'movements',
  payments: 'payments',
  file: 'fileRows',
};

export function getRows(
  conciliationType,
  dataPoolId,
  dateRange,
  page,
  filters
) {
  return client
    .get(rowsPathsByConciliationType[conciliationType], {
      params: {
        data_pool_id: dataPoolId,
        daterange: formatDateRange(dateRange),
        page,
        ...filters,
      },
    })
    .then(extractData)
    .then(objectKeysToCamelCase)
    .then((res) => {
      res.rows = res[rowsKeysByConciliationType[conciliationType]];
      return res;
    });
}

export function getFileColumns(dataPoolId) {
  return client
    .get(`/data_pools/${dataPoolId}/filterable_fields`)
    .then(extractData)
    .then(objectKeysToCamelCase);
}

// Unused endpoint
export function getFileNames(structureId, dateRange) {
  return client
    .get('/files/filenames', {
      params: {
        structure_id: structureId,
        daterange: formatDateRange(dateRange),
      },
    })
    .then(extractData)
    .then(objectKeysToCamelCase);
}

export function notifyUpload({
  profileId: reconciliation_profile_id,
  profileName: reconciliation_profile_name,
  simulate,
  filesA: a_input_files,
  filesB: b_input_files,
}) {
  return client.post('/reconciliations/notify', {
    reconciliation_profile_id,
    reconciliation_profile_name,
    simulate,
    a_input_files: objectKeysToUnderscore(a_input_files),
    b_input_files: objectKeysToUnderscore(b_input_files),
  });
}

export function conciliate(profileId, matches, matchSessionName) {
  return client.post('/reconciliations/conciliate', {
    reconciliation_profile_id: profileId,
    matchs: matches.map(({ dataPoolA, dataPoolB }) => {
      return {
        data_pool_a: dataPoolA ? objectKeysToUnderscore(dataPoolA) : [],
        data_pool_b: dataPoolB ? objectKeysToUnderscore(dataPoolB) : [],
      };
    }),
    name: matchSessionName,
  });
}

export function deprecate(ids) {
  return client.post('/increase_transactions/conciliate', {
    params: { deprecate: true, ids },
  });
}

export function rollbackConciliation(id) {
  return client.post(`/reconciliations/rollback`, {
    id,
  });
}

export function deleteConciliation(conciliationId) {
  return client.delete(`/reconciliations/${conciliationId}`);
}

export function generateReport({ dataPoolId, receivers, dateRange }) {
  const params = {
    report: {
      type: 'pending',
      data_pool_id: dataPoolId,
      options: {
        receiver_emails: receivers,
        query_params: {
          daterange: formatDateRange(dateRange),
        },
      },
    },
  };
  return client.post('/reports', params);
}

export function getReports({ dataPoolId }) {
  const params = { data_pool_id: dataPoolId, per_page: 20 };
  return client
    .get('/reports', { params })
    .then(extractData)
    .then(objectKeysToCamelCase);
}

export function resendReport({ reportId, receivers }) {
  const params = {
    receiver_emails: receivers,
  };
  return client.get(`/reports/${reportId}/resend`, { params });
}
