import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from '../Typography';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin-bottom: 3rem;
  .header-text {
    margin: 0;
    line-height: 1.25rem;
  }
`;

const WizardHeader = ({ title, text }) => {
  return (
    <HeaderContainer>
      <H3>{title}</H3>
      <p className="header-text">{text}</p>
    </HeaderContainer>
  );
};

WizardHeader.propTypes = { text: PropTypes.string, title: PropTypes.string };

export default WizardHeader;
