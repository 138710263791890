import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  H2,
  Paragraph,
  ModalContent,
  InputText,
  Button,
  Select,
  SelectOption,
} from '@increase/typed-components';

export function PendingRecordsReportsModal({
  onOk,
  onCancel,
  visible,
  reports,
  isLoading,
  creationSuccess = true,
}) {
  const { t } = useTranslation();
  const [receivers, setReceivers] = useState('');
  const [reportId, setReportId] = useState('');
  const translations = t('pending_operations.generate_report_modal', {
    returnObjects: true,
  });

  const closeHandle = () => {
    setReceivers('');
    setReportId('');
    onCancel();
  };

  const getContent = () => {
    return creationSuccess ? (
      <>
        <H2>{translations.success_title}</H2>
        <Paragraph style={{ margin: '1rem 0' }}>
          {translations.success_message}
        </Paragraph>
        <div style={{ marginTop: '1rem' }}>
          <Button onClick={closeHandle}>{translations.success_ok}</Button>
        </div>
      </>
    ) : (
      <>
        <H2>{translations.header}</H2>
        <Select
          label={t('select')}
          onChange={(e) => {
            setReportId(e.target.value);
          }}
        >
          <SelectOption value="">
            {isLoading ? translations.loading : translations.generate_new}
          </SelectOption>
          {reports &&
            reports.map((report) => (
              <SelectOption key={report.id} value={report.id}>
                {report.name} ({report.state})
              </SelectOption>
            ))}
        </Select>
        <Paragraph style={{ margin: '1rem 0' }}>{translations.body}</Paragraph>
        <InputText
          id="report-reciever"
          label={translations.receiver}
          onChange={(e) => setReceivers(e.target.value)}
          value={receivers}
        />
        <div style={{ marginTop: '1rem' }}>
          <Button onClick={() => onOk(receivers.split(';'), reportId)}>
            {translations.send_report}
          </Button>
          <Button buttonType="invisible" onClick={closeHandle}>
            {t('cancel')}
          </Button>
        </div>
      </>
    );
  };
  return (
    <Modal
      onCancel={onCancel}
      onClose={onCancel}
      onOk={onOk}
      visible={visible}
      width={540}
    >
      <ModalContent>{getContent()}</ModalContent>
    </Modal>
  );
}
