import UploadStatus from '../components/common/FileUpload/UploadStatus';
import fileService from './../services/fileService';
import { notifyUpload as conciliationNotifyUpload } from './../services/conciliationService';

const FILE_INITIAL_STATE = {
  progress: 0,
  dataPool: null,
  status: UploadStatus.EMPTY,
  s3Key: null,
  structureId: null,
  file: null,
};

export function newUploaderState() {
  return {
    files: {},
    uploadedFilesCount: 0,
  };
}

export function uploadFile(dropped) {
  return (dispatch, getState) => {
    const newFile = Object.assign({}, FILE_INITIAL_STATE);
    newFile.name = dropped.name;
    newFile.file = dropped;
    dispatch({
      type: 'FILE_DROPPED',
      newFile: { [newFile.name]: newFile },
    });
    newFile.status = UploadStatus.UPLOADING;
    newFile.progress = 0;
    dispatch({
      type: 'UPLOAD_STARTED',
      started: {
        [newFile.name]: { ...newFile },
      },
    });
    return fileService
      .uploadFile(newFile.file, (progress) => {
        const uploadingFile = getState().fileUploader.files[newFile.name];
        uploadingFile.status = UploadStatus.UPLOADING;
        uploadingFile.progress = progress;
        dispatch({
          type: 'UPLOAD_PROGRESS',
          progress: {
            [newFile.name]: { ...uploadingFile },
          },
        });
      })
      .then((key) => {
        const uploadedFile = getState().fileUploader.files[newFile.name];
        uploadedFile.s3Key = key;
        uploadedFile.status = UploadStatus.COMPLETED;
        uploadedFile.progress = 100;
        dispatch({
          type: 'UPLOAD_COMPLETE',
          completed: {
            [newFile.name]: { ...uploadedFile },
          },
        });
      })
      .catch(() => {
        const erroredFile = getState().fileUploader.files[newFile.name];
        erroredFile.status = UploadStatus.UPLOAD_ERROR;
        return dispatch({
          type: 'UPLOAD_ERROR',
          files: { [newFile.name]: erroredFile },
        });
      });
  };
}

export function removeFile(file) {
  return (dispatch, getState) => {
    const newFileList = Object.assign({}, getState().fileUploader.files);
    delete newFileList[file.name];
    return dispatch({ type: 'FILE_REMOVED', newFileList });
  };
}

export function setFileStructure(fileName, dataPool, structureId) {
  return (dispatch, getState) => {
    const newFile = getState().fileUploader.files[fileName];
    newFile.structureId = structureId;
    newFile.dataPool = dataPool;
    return dispatch({
      type: 'UPDATE_FILE_CONFIG',
      updatedFile: { [newFile.name]: newFile },
    });
  };
}

export function retryUpload(fileHandler) {
  return (dispatch, getState) => {
    const newFile = getState().fileUploader[fileHandler.key];
    newFile.file = null;
    newFile.profile = null;
    newFile.status = UploadStatus.EMPTY;
    newFile.progress = 0;
    return dispatch({
      type: 'RETRY_UPLOAD',
      retry: { [fileHandler.key]: newFile },
    });
  };
}

export function fileTypeError(dropped) {
  return (dispatch) => {
    const newFile = Object.assign({}, FILE_INITIAL_STATE);
    newFile.name = dropped.name;
    newFile.status = UploadStatus.FILE_TYPE_ERROR;
    return dispatch({
      type: 'FILE_DROPPED',
      newFile: { [newFile.name]: newFile },
    });
  };
}

export function clearFileUpload() {
  return (dispatch) => {
    return dispatch({ type: 'CLEAR_FILE_UPLOAD' });
  };
}

export function notifyUpload(simulate) {
  return (dispatch, getState) => {
    const { files } = getState().fileUploader;
    const { id: profileId, name: profileName } = getState().profiles.profile;
    const filesToUpload = Object.values(files).map(
      ({ name, s3Key, structureId, dataPool }) => {
        return {
          filename: name,
          s3Key,
          dataPool,
          structureId,
        };
      }
    );

    return conciliationNotifyUpload({
      profileId,
      profileName,
      simulate,
      filesA: filesToUpload.filter((file) => file.dataPool === 'dataPoolA'),
      filesB: filesToUpload.filter((file) => file.dataPool === 'dataPoolB'),
    }).then(() => {
      dispatch({
        type: 'SET_PROFILE_MATCHING_STATUS',
        isProfileMatching: true,
      });
    });
  };
}

const fileUploaderReducer = (state = newUploaderState(), action) => {
  switch (action.type) {
    case 'CLEAR_FILE_UPLOAD':
      return {
        ...state,
        ...newUploaderState(),
      };
    case 'FILE_DROPPED':
      return {
        ...state,
        files: { ...state.files, ...action.newFile },
      };
    case 'UPLOAD_STARTED':
      return {
        ...state,
        files: { ...state.files, ...action.started },
      };
    case 'UPLOAD_PROGRESS':
      return {
        ...state,
        files: { ...state.files, ...action.progress },
      };
    case 'UPLOAD_COMPLETE':
      return {
        ...state,
        files: { ...state.files, ...action.completed },
        uploadedFilesCount: Object.values(state.files).length,
      };
    case 'UPDATE_FILE_CONFIG':
      return { ...state, files: { ...state.files, ...action.updatedFile } };
    case 'FILE_REMOVED':
      return {
        ...state,
        files: { ...action.newFileList },
        uploadedFilesCount: Object.values(action.newFileList).length,
      };
    case 'RETRY_UPLOAD':
      return { ...state, ...action.retry };
    case 'UPLOAD_ERROR':
      return { ...state, ...action.files };
    default:
      return state;
  }
};

export default fileUploaderReducer;
