import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  H2,
  Paragraph,
  ModalContent,
  InputText,
  Button,
} from '@increase/typed-components';

export function NewManualMatchModal({ onOk, onCancel, visible }) {
  const { t } = useTranslation();
  const [matchDescription, setMatchDescription] = useState('');
  return (
    <Modal
      onCancel={onCancel}
      onClose={onCancel}
      onOk={onOk}
      visible={visible}
      width={380}
    >
      <ModalContent>
        <H2>{t('pending_operations.manual_match_modal.header')}</H2>
        <Paragraph style={{ margin: '1rem 0' }}>
          {t('pending_operations.manual_match_modal.body')}
        </Paragraph>
        <InputText
          id="match-description-input"
          label={t('pending_operations.manual_match_modal.match_description')}
          onChange={(e) => setMatchDescription(e.target.value)}
          value={matchDescription}
        />
        <div style={{ marginTop: '1rem' }}>
          <Button onClick={() => onOk(matchDescription)}>{t('start')}</Button>
          <Button buttonType="invisible" onClick={onCancel}>
            {t('cancel')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
