import styled, { css as CSS } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'styled-icons/fa-solid';
import onClickOutside from 'react-onclickoutside';
import LinkButton from './LinkButton';
import { InputLabel } from './Typography';

const dynamicPosition = CSS`
  left: ${({ position }) => position.x}px;
  top: ${({ position }) => position.y + 45}px;
`;

const fixedPosotion = CSS`
  top: calc(100% + 5px);
  right: -1px;
  left: -1px;
`;

const StyledDiv = styled.div`
  ${({ fixParentOverflow }) => !fixParentOverflow && 'position: relative;'}
  background: #fff;
  border: 1px solid ${({ isExpanded }) => (isExpanded ? '#0f6bff' : '#d8d8d8')};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'unset')};
  border-radius: 3px;
  font-size: 0.825rem;
  font-family: Inter, sans-serif;
  text-align: left;
  color: #182026;
  box-sizing: border-box;
  width: ${({ width }) => width || 'auto'};
  & ul {
    position: absolute;
    ${({ fixParentOverflow }) =>
      fixParentOverflow ? dynamicPosition : fixedPosotion}
    background: white;
    list-style: none;
    margin: 0;
    border: 1px solid #ededed;
    box-shadow: 0px 1px 4px rgba(177, 177, 177, 0.25);
    border-radius: 3px;
    padding: 0;
    z-index: 2;
    max-height: 300px;
    overflow-y: auto;
    li {
      padding: 0;
      color: #3b86ff;
    }
  }
`;

const SelectOption = styled(LinkButton)`
  color: #43425d;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  padding: 10px;
  width: 100%;
  text-align: left;
  :hover {
    /* IncreaseWhite Light */
    background: #f5f5f5;
  }
`;

const SelectedContainer = styled.div`
  padding: 11px 5px 11px 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DownArrow = styled(ChevronDown)`
  width: 11px;
  height: 11px;
`;

const IconContainer = styled.div`
  height: 100%;
  margin: 6px 0;
  padding: 5px 11px;
  /* IncreaseWhite Bold */
  border-left: 1px solid #b1b1b1;
  text-align: center;
  & svg {
    color: ${({ isExpanded }) => (isExpanded ? '#0F6BFF' : '#43425d')};
  }
`;

const getRelativeParent = (elem) => {
  const parent = elem.parentElement;
  if (parent) {
    if (
      getComputedStyle(parent).position === 'relative' ||
      parent.nodeName === 'BODY'
    ) {
      return parent;
    }
    return getRelativeParent(parent);
  }
  return document.body;
};

const getRelativeCoordinates = (elemRect, parentRect) => {
  return {
    x: elemRect.left - parentRect.left,
    y: elemRect.top - parentRect.top,
  };
};

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
    this.containerRef = React.createRef();
    this.containerPos = { x: 0, y: 0 };
    this.relativeParent = null;
    this.setDropdownPosition = this.setDropdownPosition.bind(this);
  }

  setDropdownPosition() {
    this.relativeParent =
      this.relativeParent || getRelativeParent(this.containerRef.current);
    this.containerPos = getRelativeCoordinates(
      this.containerRef.current.getBoundingClientRect(),
      this.relativeParent.getBoundingClientRect()
    );
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }

    if (this.state.active) {
      this.setState({ active: false });
    } else {
      this.setState({ active: true });
      // Get position only on click. In the future might be needed to watch window size to reposition.
      if (this.props.fixParentOverflow) {
        this.setDropdownPosition();
      }
    }
  }

  handleClickOutside() {
    this.setState({ active: false });
  }

  getSelected() {
    const sel = this.props.options.find(
      (op) => op.value === this.props.selected
    );
    return sel ? sel.name : this.props.placeholder;
  }

  onSelect(value) {
    this.props.onSelect(value);
  }

  render() {
    let dropdown = null;
    let extraButton = null;
    if (this.props.extraButtonText) {
      extraButton = (
        <li>
          <LinkButton onClick={this.props.onExtraButtonClick.bind(this)}>
            {this.props.extraButtonText}
          </LinkButton>
        </li>
      );
    }

    if (this.state.active) {
      dropdown = (
        <ul className="dropdown">
          {extraButton}
          {this.props.options.map((option, i) => (
            <li key={i}>
              <SelectOption onClick={this.onSelect.bind(this, option.value)}>
                {option.name}
              </SelectOption>
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div>
        {this.props.label && (
          <InputLabel style={{ marginBottom: '4px' }}>
            {this.props.label}
          </InputLabel>
        )}
        <StyledDiv
          className={this.props.className}
          disabled={this.props.disabled}
          fixParentOverflow={this.props.fixParentOverflow}
          isExpanded={this.state.active}
          onClick={this.onClick.bind(this)}
          position={this.containerPos}
          ref={this.containerRef}
          width={this.props.width}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SelectedContainer>{this.getSelected()}</SelectedContainer>
            {!this.props.disabled && (
              <IconContainer isExpanded={this.state.active}>
                <DownArrow />
              </IconContainer>
            )}
          </div>
          {dropdown}
        </StyledDiv>
      </div>
    );
  }
}

Select.propTypes = {
  disabled: PropTypes.bool,
  extraButtonText: PropTypes.string,
  fixParentOverflow: PropTypes.bool,
  label: PropTypes.string,
  onExtraButtonClick: PropTypes.func,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      name: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  selected: PropTypes.any,
  width: PropTypes.string,
};

Select.defaultProps = {
  placeholder: '',
  fixParentOverflow: false,
};

export default onClickOutside(Select);
