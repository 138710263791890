import React from 'react';
import PropTypes from 'prop-types';
import MoneyInput from './MoneyInput';
import NumberInput from './NumberInput';

function RangeInput({ dataType, threshold, onChange, disabled }) {
  switch (dataType) {
    case 'money':
      return (
        <MoneyInput
          className="range money"
          disabled={disabled}
          fixed={2}
          min={0}
          onChange={(newValue) => onChange('threshold', newValue)}
          showButtons={!disabled}
          step={0.1}
          value={threshold || 0}
        />
      );
    case 'integer':
    case 'long':
    case 'date':
      return (
        <NumberInput
          className="range money"
          disabled={disabled}
          fixed={0}
          min={0}
          onChange={(newValue) => onChange('threshold', newValue)}
          showButtons={!disabled}
          step={1}
          value={threshold || 0}
        />
      );
    default:
      return null;
  }
}

RangeInput.propTypes = {
  dataType: PropTypes.string,
  onChange: PropTypes.func,
  threshold: PropTypes.number,
};

export default RangeInput;
