import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from './NumberInput';

function MoneyInput({
  disabled,
  className,
  value,
  onChange,
  min,
  showButtons,
  fixed,
  step = 1,
}) {
  return (
    <NumberInput
      className={className}
      disabled={disabled}
      fixed={fixed}
      min={min}
      onChange={onChange}
      prefix="$"
      showButtons={showButtons}
      step={step}
      value={value}
    />
  );
}

MoneyInput.defaultProps = {
  showButtons: true,
  disabled: false,
};

MoneyInput.propTypes = {
  disabled: PropTypes.bool,
  fixed: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  showButtons: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default MoneyInput;
