import React from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '@increase/typed-components';
import BottomNavigation from './BottomNavigation';
import Wizard from '../../common/Wizard/Wizard';
import Logo from '../../common/Wizard/logo.svg';

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}
html, body {
  background-color: #F7F8FC !important;
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 4rem 5rem 120px;
`;

class MultiStep extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
    };
  }

  next = () => {
    const page = Math.min(this.state.page + 1, this.props.children.length - 1);
    this.setState(() => ({
      page: page,
    }));
    this.props.onStepChange(page);
  };

  previous = () => {
    const page = Math.max(this.state.page - 1, 0);
    this.setState(() => ({
      page: page,
    }));
    this.props.onStepChange(page);
  };

  onSubmit = () => {
    const valid = this.props.submitForm();
    if (valid) return this.next();
    return valid;
  };

  onFinish = () => {
    return this.props.finishForm();
  };

  submitButton() {
    const { children, t, canSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    const isMiddlePage = page < React.Children.count(children) - 2;

    if (isMiddlePage) {
      return <Button onClick={this.next}>{t('next')}</Button>;
    } else if (!isMiddlePage && !isLastPage) {
      return (
        <Button disabled={!canSubmit} onClick={this.onSubmit}>
          {t('submit')}
        </Button>
      );
    } else if (isLastPage) {
      return <Button onClick={this.onFinish}>{t('finish')}</Button>;
    }
    throw new Error(`Unknown page: ${page}`);
  }

  render() {
    const { children, stepList, t } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    return (
      <Wrapper>
        <GlobalStyle />
        <Wizard activeStep={page} logo={Logo} stepList={stepList} />
        <Content>
          <ContentWrapper>{activePage}</ContentWrapper>
          <BottomNavigation>
            {page > 0 && (
              <Button buttonType="invisible" onClick={this.previous}>
                {t('back')}
              </Button>
            )}
            {this.submitButton()}
          </BottomNavigation>
        </Content>
      </Wrapper>
    );
  }
}

export default MultiStep;
