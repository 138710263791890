import axios from 'axios';

const s3Client = axios.create({
  transformRequest: [
    (data) => {
      if (data && data.get && data.get('x-amz-credential')) {
        return data;
      }
      return JSON.stringify(data);
    },
  ],
  headers: {
    Accept: 'application/json',
  },
});

export default s3Client;
