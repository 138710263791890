import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import { deleteScanningFields } from './scanningsReducer';
import profilesService from './../services/profilesService';
import fileService from './../services/fileService';
import { DATA_POOL_KEYS } from './dataPoolsReducer';
import { incrementalNumber } from '../helpers/helpers';

export const STRUCTURE_KEYS = {
  STRUCTURES_A: 'structuresA',
  STRUCTURES_B: 'structuresB',
};

const structureNumbers = {
  [STRUCTURE_KEYS.STRUCTURES_A]: incrementalNumber(1, 20, 1),
  [STRUCTURE_KEYS.STRUCTURES_B]: incrementalNumber(1, 20, 1),
};

const getStructureNumber = (structureKey) =>
  structureNumbers[structureKey].next().value;

const NEW_IGNORED_LINE = {
  position: 1,
  value: '',
};

const NEW_INFORMATIVE_FIELD = {
  location: 1,
  field: '',
};

const NEW_FIELD_PROPS = {
  name: '',
  centsSeparator: '',
  stringOffset: null,
  location: 1,
  filterable: true,
  dateFormat: null,
  dataType: '',
  comparators: [{ originalValue: '', possibleValues: '' }],
};

const NEW_FIELD = {
  dataType: 'string',
  name: '',
  propsA: clone(NEW_FIELD_PROPS),
  propsB: clone(NEW_FIELD_PROPS),
};

export function newEmptyStructure(dataPoolKey) {
  return {
    name: `Estructura ${getStructureNumber(dataPoolKey)}`,
    headersCount: 0,
    footersCount: 0,
    delimiter: ',',
    stringToRemove: '',
    informativeFields: {},
    ignoredLines: [],
    fields: {},
  };
}

export function newEmptyField() {
  const rawField = cloneDeep(NEW_FIELD);
  rawField.id = new Date().getTime().toString();
  rawField.propsA.id = new Date().getTime().toString();
  rawField.propsB.id = (new Date().getTime() + 1).toString();
  return rawField;
}

const getInitialState = () => {
  return {
    structuresA: [],
    structuresB: [],
    validating: false,
    validationResults: {},
  };
};

//Delete this when removing newProfile/editProfile
export function newFieldA() {
  return newField('structureA');
}

function newIgnoredLine() {
  const rawLine = { ...NEW_IGNORED_LINE };
  rawLine.id = new Date().getTime().toString();
  return rawLine;
}

//Delete this when removing newProfile/editProfile
export function newField() {
  return (dispatch, getState) => {
    const prevFields = getState().structures.fields;
    return dispatch({
      type: 'UPDATE_FIELDS',
      fields: [...prevFields, newEmptyField()],
    });
  };
}

//Delete this when removing newProfile/editProfile
export function deleteField(field) {
  return (dispatch, getState) => {
    const { fields } = getState().structures;
    const newFields = fields.filter((prevField) => prevField.id !== field.id);
    dispatch(deleteScanningFields(field));
    return dispatch({ type: 'UPDATE_FIELDS', fields: newFields });
  };
}

export function addIgnoredLine(structureKey, structureIndex) {
  return (dispatch, getState) => {
    const prevIgnoredLines = getState().structures[structureKey][structureIndex]
      .ignoredLines;
    const ignoredLines = [...prevIgnoredLines, newIgnoredLine()];
    return dispatch(
      updateStructureProp(structureKey, structureIndex, {
        ignoredLines: ignoredLines,
      })
    );
  };
}

export function deleteIgnoredLine(structureKey, structureIndex, line) {
  return (dispatch, getState) => {
    const prevIgnoredLines = getState().structures[structureKey][structureIndex]
      .ignoredLines;
    const ignoredLines = prevIgnoredLines.filter(
      (prevLine) => prevLine !== line
    );
    return dispatch(
      updateStructureProp(structureKey, structureIndex, {
        ignoredLines: ignoredLines,
      })
    );
  };
}

export function setStructures(structuresA, structuresB) {
  return (dispatch) => {
    dispatch({ type: 'SET_STRUCTURES_A', structures: structuresA });
    return dispatch({ type: 'SET_STRUCTURES_B', structures: structuresB });
  };
}

export function addStructure(structureKey) {
  return (dispatch, getState) => {
    const { STRUCTURES_A, STRUCTURES_B } = STRUCTURE_KEYS;
    let abstractInformativeFields;
    const action = {};
    const { dataPools } = getState();
    if (structureKey === STRUCTURES_A) {
      action.type = 'ADD_STRUCTURE_A';
      abstractInformativeFields =
        dataPools[DATA_POOL_KEYS.DATA_POOL_A].abstractInformativeFields;
    } else if (structureKey === STRUCTURES_B) {
      action.type = 'ADD_STRUCTURE_B';
      abstractInformativeFields =
        dataPools[DATA_POOL_KEYS.DATA_POOL_B].abstractInformativeFields;
    }
    action.newStructure = createStructure(
      dataPools.abstractFields,
      abstractInformativeFields,
      structureKey
    );
    return dispatch(action);
  };
}

export function removeStructure(structureKey, structureIndex) {
  return (dispatch, getState) => {
    const { STRUCTURES_A, STRUCTURES_B } = STRUCTURE_KEYS;
    const actionType = {
      [STRUCTURES_A]: 'REMOVE_STRUCTURE_A',
      [STRUCTURES_B]: 'REMOVE_STRUCTURE_B',
    };
    const action = {
      type: actionType[structureKey],
      newStructures: getState().structures[structureKey].filter(
        (s, index) => index !== structureIndex
      ),
    };

    return dispatch(action);
  };
}

export function changeIgnoredLine(
  structureKey,
  line,
  changedProp,
  structureIndex
) {
  return (dispatch, getState) => {
    const prevIgnoredLines = getState().structures[structureKey][structureIndex]
      .ignoredLines;
    const ignoredLines = prevIgnoredLines.map((prevLine) => {
      if (prevLine === line) {
        return { ...prevLine, ...changedProp };
      }
      return prevLine;
    });
    return dispatch(
      updateStructureProp(structureKey, structureIndex, {
        ignoredLines: ignoredLines,
      })
    );
  };
}

export function validateStructure(structuresKey, structureIndex, s3Key) {
  return (dispatch, getState) => {
    const structure = getState().structures[structuresKey][structureIndex];
    const { validationResults } = getState().structures;
    const structureValidationId = `${structuresKey}-${structureIndex}`;
    return profilesService.validateProfile(s3Key, structure).then((data) => {
      const changes = {
        ...validationResults[structureValidationId],
        ...data,
        validating: false,
      };
      dispatch({
        type: 'SET_VALIDATE_PARAMS',
        changes: { [structureValidationId]: changes },
      });
      return dispatch({
        type: 'STRUCTURE_VALIDATE_REQUEST_FETCHED',
      });
    });
  };
}

export function validateFile(structuresKey, structureIndex, file) {
  return (dispatch) => {
    const structureValidationId = `${structuresKey}-${structureIndex}`;
    dispatch({
      type: 'UPLOADING_FOR_VALIDATION',
      changes: {
        [structureValidationId]: {
          filename: file.name,
          uploading: true,
        },
      },
    });
    fileService.uploadFile(file).then((s3Key) => {
      if (s3Key) {
        const fileParams = {
          s3Key: s3Key,
          filename: file.name,
          validating: true,
        };
        dispatch({
          type: 'SET_VALIDATE_PARAMS',
          changes: {
            [structureValidationId]: fileParams,
          },
        });
        return dispatch(
          validateStructure(structuresKey, structureIndex, s3Key)
        );
      }
      return dispatch({ type: 'UPLOAD_FILE_FAILED' });
    });
    return dispatch({
      type: 'STRUCTURE_FILE_VALIDATE_REQUEST',
      structureValidationId,
    });
  };
}

export function clearValidationSateById(validationId) {
  return (dispatch) => {
    return dispatch({ type: 'CLEAR_VALIDATION_STATE', validationId });
  };
}

export function validateProfile(structuresKey, structureIndex, file) {
  return (dispatch, getState) => {
    const structureValidationId = `${structuresKey}-${structureIndex}`;
    dispatch({ type: 'VALIDATE_REQUEST', structureValidationId });
    if (file) {
      return dispatch(validateFile(structuresKey, structureIndex, file));
    }
    const { validationResults } = getState().structures;

    if (validationResults && validationResults[structureValidationId]) {
      const changes = {
        ...validationResults[structureValidationId],
        error: null,
      };
      dispatch({
        type: 'RESET_VALIDATION_ERROR',
        changes: { [structureValidationId]: changes },
      });
      return dispatch(
        validateStructure(
          structuresKey,
          structureIndex,
          validationResults[structureValidationId].s3Key
        )
      );
    }

    return dispatch({ type: 'VALIDATION_FAILED' });
  };
}

export function updateFieldProps(field, prop, value) {
  return (dispatch, getState) => {
    const prevFields = getState().structures.fields;
    const fields = prevFields.map((prevField) => {
      if (prevField === field) {
        prevField[prop] = { ...prevField[prop], ...value };
      }
      return prevField;
    });
    return dispatch({ type: 'UPDATE_FIELDS', fields });
  };
}

export function updateFieldKey(
  structuresKey,
  structureIndex,
  fieldId,
  changes
) {
  return (dispatch, getState) => {
    const structures = getState().structures[structuresKey].map(
      (structure, index) => {
        if (index === structureIndex || structureIndex === null) {
          return {
            ...structure,
            fields: {
              ...structure.fields,
              [fieldId]: {
                ...structure.fields[fieldId],
                ...changes,
              },
            },
          };
        }
        return structure;
      }
    );
    return dispatch({ type: 'UPDATE_FIELDS', structuresKey, structures });
  };
}

export function updateStructureProp(structuresKey, structureIndex, prop) {
  return (dispatch, getState) => {
    const structures = getState().structures[structuresKey].map(
      (structure, index) => {
        if (index !== structureIndex) {
          return structure;
        }
        return Object.assign({}, structure, prop);
      }
    );

    return dispatch({
      type: 'UPDATE_STRUCTURE_PROP',
      structuresKey,
      structures,
    });
  };
}

export function clearStructureState() {
  return (dispatch, getState) => {
    const initalState = getInitialState();
    const { abstractFields } = getState().dataPools;
    initalState.structuresA = [
      createStructure(abstractFields, null, STRUCTURE_KEYS.STRUCTURES_A),
    ];
    initalState.structuresB = [
      createStructure(abstractFields, null, STRUCTURE_KEYS.STRUCTURES_B),
    ];
    return dispatch({ type: 'CLEAR_STRUCTURES_STATE', initalState });
  };
}

//Delete this when removing newProfile/editProfile
export function setFields(dataPoolA, dataPoolB) {
  return (dispatch) => {
    const fieldsA = dataPoolFields(dataPoolA.structures[0].fields, 0);
    const fieldsB = dataPoolFields(dataPoolB.structures[0].fields, 1);
    const groupedFields = groupBy([...fieldsA, ...fieldsB], ({ name }) => name);
    const fields = buildFields(groupedFields);

    return dispatch({ type: 'UPDATE_FIELDS', fields });
  };
}

//Delete this when removing newProfile/editProfile
function dataPoolFields(fields, dataPoolIndex) {
  return Object.entries(fields).map((field) => {
    return { ...field[1], structure: dataPoolIndex, idx: field[0] };
  });
}

//Delete this when removing newProfile/editProfile
function buildFields(fieldPairs) {
  const mapedFields = Object.values(fieldPairs).map((fieldPair) => {
    if (fieldPair.length === 2) {
      return buildFieldPair(fieldPair[0], fieldPair[1]);
    }
    return buildSingleField(fieldPair[0]);
  });
  return Object.assign({}, ...mapedFields);
}

//Delete this when removing newProfile/editProfile
function buildFieldPair(fieldA, fieldB) {
  return {
    [fieldA.idx]: {
      id: fieldA.id,
      name: fieldA.name,
      dataType: fieldA.dataType,
      propsA: fieldA,
      propsB: fieldB,
    },
  };
}

//Delete this when removing newProfile/editProfile
function buildSingleField(field) {
  if (field.dataPool === 0) {
    return { ...field, propsA: field };
  }
  return { ...field, propsB: field };
}

//NvsM section
export function createStructuresFields(abstractFieldObject) {
  return (dispatch) => {
    const [abstractFieldIdenfier] = Object.keys(abstractFieldObject);
    const newField = Object.assign(
      {},
      NEW_FIELD_PROPS,
      abstractFieldObject[abstractFieldIdenfier]
    );
    dispatch(
      updateFieldKey(
        STRUCTURE_KEYS.STRUCTURES_A,
        null,
        abstractFieldIdenfier,
        newField
      )
    );
    return dispatch(
      updateFieldKey(
        STRUCTURE_KEYS.STRUCTURES_B,
        null,
        abstractFieldIdenfier,
        newField
      )
    );
  };
}

function newFieldFromAbstract(referenceId, abstractField) {
  const newField = Object.assign({}, NEW_FIELD_PROPS, abstractField);
  newField.dataType = abstractField.dataType;
  newField.name = abstractField.name;
  return { [referenceId]: newField };
}

export function createStructure(
  abstractFields,
  abstractInformativeFields,
  structureKey
) {
  const newStructure = newEmptyStructure(structureKey);
  const newFields = createFieldsFromAbstractsFields(abstractFields);
  updateInformativefieldsFromAbstracts(newStructure, abstractInformativeFields);
  newStructure.fields = Object.assign({}, ...newFields);
  return newStructure;
}

function createFieldsFromAbstractsFields(abstractFields) {
  return Object.entries(abstractFields).map((abstractField) => {
    return newFieldFromAbstract(abstractField[0], abstractField[1]);
  });
}

export function onUpdateParentField(referenceId, prop, value) {
  return (dispatch) => {
    dispatch(
      updateFieldKey(STRUCTURE_KEYS.STRUCTURES_A, null, referenceId, {
        [prop]: value,
      })
    );
    return dispatch(
      updateFieldKey(STRUCTURE_KEYS.STRUCTURES_B, null, referenceId, {
        [prop]: value,
      })
    );
  };
}

export function onUpdateCardParentField(reference_id, prop, value) {
  return (dispatch, getState) => {
    const { structuresB } = getState().structures;
    const newStructuresB = updateFieldsByReferenceId(
      structuresB,
      reference_id,
      prop,
      value
    );
    return dispatch({ type: 'SET_STRUCTURES_B', structures: newStructuresB });
  };
}

function updateFieldsByReferenceId(structures, reference_id, prop, value) {
  return structures.map((structure) => {
    structure.fields[reference_id][prop] = value;
    return structure;
  });
}

export function onDeleteParentField(reference_id) {
  return (dispatch, getState) => {
    const { structuresA, structuresB } = getState().structures;
    const newStructuresA = removeFieldByReferenceId(structuresA, reference_id);
    const newStructuresB = removeFieldByReferenceId(structuresB, reference_id);
    return dispatch(setStructures(newStructuresA, newStructuresB));
  };
}

function removeFieldByReferenceId(structures, reference_id) {
  return structures.map((structure) => {
    delete structure.fields[reference_id];
    return structure;
  });
}

function updateInformativefieldsFromAbstracts(
  structure,
  abstractInformativeFields
) {
  // eslint-disable-next-line no-unused-vars
  for (const fieldKey in abstractInformativeFields) {
    if (
      Object.prototype.hasOwnProperty.call(abstractInformativeFields, fieldKey)
    ) {
      const newInformativeField = Object.assign(
        {},
        NEW_INFORMATIVE_FIELD,
        structure.informativeFields[fieldKey] || {},
        abstractInformativeFields[fieldKey]
      );
      structure.informativeFields[fieldKey] = newInformativeField;
    }
  }
}

export function setInformativeFields(structuresKey, abstractInformativeFields) {
  return (dispatch, getState) => {
    const newStructures = cloneDeep(getState().structures[structuresKey]);
    newStructures.forEach((structure) =>
      updateInformativefieldsFromAbstracts(structure, abstractInformativeFields)
    );

    return dispatch({
      type: 'SET_INFORMATIVE_FIELDS',
      structuresKey,
      newStructures,
    });
  };
}

export function updateInformativeField(
  structuresKey,
  structureIndex,
  fieldId,
  changes
) {
  return (dispatch, getState) => {
    const newStructures = cloneDeep(getState().structures[structuresKey]);
    const informativeFields = newStructures[structureIndex].informativeFields;
    informativeFields[fieldId] = { ...informativeFields[fieldId], ...changes };
    return dispatch({
      type: 'UPDATE_INFORMATIVE_FIELDS',
      newStructures,
      structuresKey,
    });
  };
}

export function deleteInformativeField(structuresKey, fieldId) {
  return (dispatch, getState) => {
    const newStructures = cloneDeep(getState().structures[structuresKey]);
    newStructures.forEach((structure) => {
      // eslint-disable-next-line no-unused-vars
      delete structure.informativeFields[fieldId];
    });
    return dispatch({
      type: 'SET_INFORMATIVE_FIELDS',
      structuresKey,
      newStructures,
    });
  };
}

const structuresReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'SET_STRUCTURES_A':
      return { ...state, structuresA: action.structures };
    case 'SET_STRUCTURES_B':
      return { ...state, structuresB: action.structures };
    case 'ADD_STRUCTURE_A':
      return {
        ...state,
        structuresA: [...state.structuresA, action.newStructure],
      };
    case 'ADD_STRUCTURE_B':
      return {
        ...state,
        structuresB: [...state.structuresB, action.newStructure],
      };
    case 'REMOVE_STRUCTURE_A':
      return {
        ...state,
        structuresA: action.newStructures,
      };
    case 'REMOVE_STRUCTURE_B':
      return {
        ...state,
        structuresB: action.newStructures,
      };
    case 'VALIDATE_REQUEST':
      return {
        ...state,
        validating: true,
        validationResults: {
          ...state.validationResults,
          [action.structureValidationId]: {
            ...state.validationResults[action.structureValidationId],
            validating: true,
          },
        },
      };
    case 'STRUCTURE_FILE_VALIDATE_REQUEST':
      return {
        ...state,
        validating: true,
        validationResults: {
          ...state.validationResults,
          [action.structureValidationId]: {
            validating: true,
          },
        },
      };
    case 'STRUCTURE_VALIDATE_REQUEST_FETCHED':
      return { ...state, validating: false };
    case 'RESET_VALIDATION_ERROR':
    case 'UPLOADING_FOR_VALIDATION':
    case 'SET_VALIDATE_PARAMS': {
      return {
        ...state,
        validationResults: { ...state.validationResults, ...action.changes },
      };
    }
    case 'CLEAR_VALIDATION_STATE':
      return {
        ...state,
        validationResults: {
          ...state.validationResults,
          [action.validationId]: {},
        },
      };
    case 'UPDATE_STRUCTURE_PROP':
    case 'UPDATE_FIELDS':
      return {
        ...state,
        [action.structuresKey]: action.structures,
      };
    case 'SET_INFORMATIVE_FIELDS':
    case 'UPDATE_INFORMATIVE_FIELDS':
      return { ...state, ...{ [action.structuresKey]: action.newStructures } };
    case 'CLEAR_STRUCTURES_STATE':
      return { ...action.initalState };
    default:
      return state;
  }
};

export default structuresReducer;
