import React from 'react';
import { Route } from 'react-router-dom';
import MainAppLayout from './components/layout/Layout';

export default function CustomRoute({
  component: Component,
  withLayout = true,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => {
        window.AppcuesReady(() => window.Appcues.page());
        if (withLayout) {
          return (
            <MainAppLayout>
              <Component {...props} />
            </MainAppLayout>
          );
        }
        return <Component {...props} />;
      }}
    />
  );
}
