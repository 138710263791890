import { addIfNumber } from '../helpers/helpers';

export default class Structure {
  constructor({
    id,
    name = '',
    headersCount,
    footersCount,
    stringToRemove,
    delimiter,
    ignoredLines = [],
    fields = {},
    informativeFields = {},
  }) {
    this.id = id;
    this.name = name;
    this.headersCount = headersCount;
    this.footersCount = footersCount;
    this.stringToRemove = stringToRemove;
    this.delimiter = delimiter;
    this.ignoredLines = ignoredLines;
    this.fields = Object.values(fields);
    this.informativeFields = Object.values(informativeFields);
  }

  static fromAPI(
    {
      id,
      name,
      headers_count: headersCount,
      footers_count: footersCount,
      string_to_remove: stringToRemove,
      delimiter,
      ignore_lines: ignoredLines,
      fields,
      informative_fields: informativeFields,
    },
    abstractFieldsObj,
    abstractInformativeFieldsObj
  ) {
    const lines = (ignoredLines || []).map((c) => {
      return { position: addIfNumber(c.position, 1), value: c.value };
    });
    const structureFields = mapFieldsFromAPI(fields, abstractFieldsObj);
    const structureInfFields = abstractInformativeFieldsObj
      ? mapInformativeFieldsFromAPI(
          informativeFields,
          abstractInformativeFieldsObj
        )
      : {};
    return {
      id,
      name,
      headersCount,
      footersCount,
      stringToRemove,
      delimiter,
      ignoredLines: lines,
      fields: structureFields,
      informativeFields: structureInfFields,
    };

    function mapFieldsFromAPI(fields, abstractFields) {
      const fieldsObj = {};

      fields.forEach((field) => {
        const absField = Object.entries(abstractFields).find(
          (abstractField) => {
            return abstractField[1].name === field.name;
          }
        );
        fieldsObj[absField[0]] = buildField(field);
      });

      return fieldsObj;
    }

    function buildField(field) {
      return {
        id: field.id,
        name: field.name,
        location: addIfNumber(field.location, 1),
        centsSeparator: field.cents_separator,
        stringOffset: field.string_offset,
        dateFormat: field.date_format,
        dataType: field.data_type,
        filterable: field.filterable,
        comparators: (field.comparators || []).map((c) => {
          return {
            originalValue: c.original_value,
            possibleValues: c.possible_values.join(', '),
          };
        }),
      };
    }

    function mapInformativeFieldsFromAPI(
      informativeFields,
      abstractInformativeFields
    ) {
      const fieldsObj = {};

      if (informativeFields && informativeFields.length) {
        informativeFields.forEach(({ field, location }) => {
          const [absInfKey] = Object.entries(abstractInformativeFields).find(
            ([, absInfValue]) => {
              return absInfValue.field === field;
            }
          );
          // Older profiles could fail
          if (absInfKey) {
            // AbstractInformativeField and InformativeField are related by using the same key
            fieldsObj[absInfKey] = { field, location };
          }
        });
      }

      return fieldsObj;
    }
  }

  static toAPI({
    id,
    name,
    delimiter,
    headersCount,
    footersCount,
    stringToRemove,
    ignoredLines = [],
    fields,
    informativeFields = [],
  }) {
    return {
      id,
      name: name,
      delimiter: delimiter,
      headers_count: headersCount,
      footers_count: footersCount,
      string_to_remove: stringToRemove,
      ignore_lines: ignoredLinesToAPI(ignoredLines),
      fields: fieldsToAPI(fields),
      informative_fields: informativeFields,
    };
  }

  mapToValidate() {
    return {
      headers_count: this.headersCount,
      string_to_remove: this.stringToRemove,
      delimiter: this.delimiter,
      fields: fieldsToAPI(this.fields),
    };
  }
}

function ignoredLinesToAPI(ignoredLines) {
  return ignoredLines.map((lineToIgnore) => {
    return {
      position: addIfNumber(lineToIgnore.position, -1),
      value: lineToIgnore.value,
    };
  });
}

function fieldsToAPI(fields) {
  return fields.map((field) => {
    return {
      id: field.id,
      name: field.name,
      data_type: field.dataType,
      location: addIfNumber(field.location, -1),
      cents_separator: field.centsSeparator,
      string_offset: field.stringOffset,
      date_format: field.dateFormat,
      filterable: field.filterable,
      comparators: field.comparators.map((comparator) => {
        return {
          original_value: comparator.originalValue,
          possible_values: comparator.possibleValues
            .split(',')
            .map((s) => s.trim()),
        };
      }),
    };
  });
}
