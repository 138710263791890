import React from 'react';
import styled from 'styled-components';
import parseJSON from 'date-fns/parseJSON';
import { LibraryAdd as Clone } from 'styled-icons/material/LibraryAdd';
import { DeleteForever as Delete } from 'styled-icons/material/DeleteForever';
import { Autorenew } from 'styled-icons/material/Autorenew';
import { formatDate } from '../../helpers/helpers';
import IconButton from '../common/IconButton';

const ProfileItemStyled = styled.div`
  padding: 1rem 1.25rem;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  /* IncreaseWhite Light */
  border-bottom: 1px solid #f5f5f5;
  &.selected {
    /* Background NotSoWhite */
    background: #fbfbfb;
    &::before {
      position: absolute;
      content: '';
      width: 5px;
      top: 0;
      bottom: 0;
      left: 0;
      background: #0d4;
    }
  }
  .item-name {
    font-size: 1rem;
    margin: 0 0 0.625rem;
    font-weight: 700;
    color: #b1b1b1;
    &.active {
      color: #182026;
    }
  }
  .item-lastUpdate {
    font-size: 0.625rem;
    color: #b1b1b1;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }
  .item-lastUpdateDate {
    font-size: 0.75rem;
    color: #b1b1b1;
    font-weight: 500;
    margin: 0 0 0.625rem;
  }
  .item-status {
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.625rem;
    margin: 0;
    padding: 0.125rem 0.5rem;
    border-radius: 1rem;
    color: #b1b1b1;
    border: 1px solid #b1b1b1;
    &.active {
      color: green;
      border: 1px solid green;
    }
  }
`;

const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  & :focus {
    outline: none;
  }
`;

const ActionButton = styled(IconButton)`
  padding: 3px;
  float: right;
`;

const ClonIcon = styled(Clone)`
  color: #b1b1b1;
`;
const DeleteIcon = styled(Delete)`
  color: #b1b1b1;
`;
const RetryIcon = styled(Autorenew)`
  color: #b1b1b1;
`;
const ProfileItem = ({
  profileName,
  lastConciliationDate,
  masquerading,
  onClone,
  onDestroy,
  onClick,
  onRetry,
  selected,
  stable,
  t,
}) => {
  const lastUpdate =
    lastConciliationDate && lastConciliationDate !== 'N/A'
      ? formatDate(parseJSON(lastConciliationDate))
      : t('never');
  return (
    <ProfileItemStyled
      className={selected && 'selected'}
      disabled={!stable && !masquerading}
      onClick={masquerading || stable ? onClick : undefined}
      title={profileName}
    >
      <h2 className={`item-name ${(stable || masquerading) && 'active'}`}>
        {profileName}
      </h2>
      <p className="item-lastUpdate">{`${t('last_conciliation')}:`}</p>
      <p className="item-lastUpdateDate">{lastUpdate}</p>
      <ItemFooter>
        <p className={`item-status ${stable && 'active'}`}>
          {stable ? t('profile.enabled') : t('profile.in_process')}
        </p>
        {masquerading && (
          <div>
            <ActionButton onClick={onDestroy}>
              <DeleteIcon size={24} />
            </ActionButton>
            <ActionButton onClick={onClone}>
              <ClonIcon size={24} />
            </ActionButton>
            <ActionButton onClick={onRetry}>
              <RetryIcon size={24} />
            </ActionButton>
          </div>
        )}
      </ItemFooter>
    </ProfileItemStyled>
  );
};

export default ProfileItem;
