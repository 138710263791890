import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: process.env.NODE_ENV !== `production`,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'dateFormat') {
          return value.replace('yyyy', 'aaaa').replace('yy', 'aa');
        }
        return value;
      },
    },
    ns: ['translation', 'constants'],
    react: {
      wait: true,
      useSuspense: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
