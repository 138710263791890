import onboardingService from './../services/onboardingService';

const PATHS = ['welcome', 'files', 'finished'];

const INITIAL_STATE = {
  page: 0,
  valid: false,
  path: PATHS[0],
};

export function setPage(page) {
  return (dispatch) => {
    return dispatch({ type: 'SET_PAGE', page });
  };
}

export function setValue(field, value) {
  return (dispatch) => {
    return dispatch({ type: 'SET_VALUE', values: { [field]: value } });
  };
}

export function submitOnBoarding() {
  return (dispatch, getState) => {
    const { description } = getState().onboarding;
    const { files } = getState().fileUploader;
    const filesArray = Object.values(files).map(({ s3Key, name }) => {
      return {
        filename: name,
        key: s3Key,
      };
    });
    dispatch({ type: 'SUBMIT_ONBOARDING' });
    return onboardingService.submitForm(filesArray, description);
  };
}

export function getPath(page) {
  return PATHS[page];
}

export function getPage(path) {
  return Math.max(PATHS.indexOf(path), 0);
}

const onboardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return { ...state, page: action.page };
    case 'SET_VALUE':
      return { ...state, ...action.values };
    default:
      return state;
  }
};

export default onboardingReducer;
