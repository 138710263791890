import React from 'react';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import { Button } from '@increase/typed-components';
import { withTranslation } from 'react-i18next';
import FileStructureViewer from './FileStructureViewer';
import Scannings from '../../common/Scannings';
import FullPageSpinner from '../../common/FullPageSpinner';
import { H1, H2 } from '../../common/Typography';
import { selectProfile } from '../../../reducers/profilesReducer';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import { mapComparationTypesForSelect } from '../../../helpers/profileHelpers';

const StructuresWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    margin-bottom: 1rem;
  }
`;

class ViewDetails extends React.Component {
  componentDidMount() {
    const { match, fileStructureCached, dispatch } = this.props;
    dispatch(selectProfile(Number(match.params.profileId)));
    if (!fileStructureCached) {
      dispatch(fetchStructureMetadata());
    }
  }

  editHandle = () => {
    const { match, history } = this.props;
    history.push(`/profiles/${match.params.profileId}/edit`);
  };

  render() {
    const {
      comparationTypes,
      vsCard,
      fetching,
      fields,
      masquerading,
      scannings,
      structuresA,
      structuresB,
      dataPoolAName,
      dataPoolBName,
      t,
    } = this.props;

    if (fetching) {
      return <FullPageSpinner />;
    }
    return (
      <div>
        {masquerading && (
          <Button buttonType="outline" onClick={this.editHandle}>
            {t('edit_model')}
          </Button>
        )}
        <H1>{t('profile.match_rules')}</H1>
        <Scannings
          comparationTypes={comparationTypes}
          editMode={false}
          fields={fields}
          scannings={scannings}
          t={t}
          vsCard={vsCard}
        />
        <H1>{t('profile.data_configuration')}</H1>
        <H2>{`${t('data_pool_a')}: ${dataPoolAName}`}</H2>
        <StructuresWrapper>
          {structuresA.map((structure, idx) => {
            return (
              <FileStructureViewer
                key={idx}
                structure={structure}
                vsCard={vsCard}
              />
            );
          })}
        </StructuresWrapper>
        {!vsCard && <H2>{`${t('data_pool_b')}: ${dataPoolBName}`}</H2>}
        <StructuresWrapper>
          {!vsCard &&
            structuresB.map((structure, idx) => {
              return <FileStructureViewer key={idx} structure={structure} />;
            })}
        </StructuresWrapper>
      </div>
    );
  }
}

const mapStateToProps = (fullState) => {
  const { fetching: profilesFetching, vsCard } = fullState.profiles;
  const { structuresA, structuresB } = fullState.structures;
  const { abstractFields } = fullState.dataPools;
  const {
    dataPoolA: { name: dataPoolAName },
    dataPoolB: { name: dataPoolBName },
  } = fullState.dataPools;
  const {
    comparationTypes,
    cached: fileStructureCached,
    fetching: fileStructureFetching,
  } = fullState.fileStructure;
  const scannings = Object.values(fullState.scannings);
  return {
    comparationTypes: mapComparationTypesForSelect(comparationTypes),
    vsCard,
    fetching: profilesFetching || fileStructureFetching,
    fields: abstractFields,
    fileStructureCached,
    masquerading: fullState.auth.user.masquerading,
    structuresA,
    structuresB,
    scannings,
    dataPoolAName,
    dataPoolBName,
  };
};
export default connect(mapStateToProps)(withTranslation()(ViewDetails));
