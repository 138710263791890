import client from './apiClient';
import { isBlank } from '../helpers/helpers';

function mapServerFilesToLocal(file) {
  return {
    id: file.id,
    createdAt: file.created_at,
    filename: file.filename,
    s3Key: file.s3_key,
    fileableType: file.fileable_type.toLowerCase(),
    fileableId: file.fileable_id,
  };
}

export function getUploadedFiles(page, profileId, { filename }) {
  const params = { page, reconciliation_profile_id: profileId };
  if (!isBlank(filename)) {
    params.filename = filename;
  }
  return client
    .get('files/uploaded_files', { params })
    .then(extractData)
    .then(mapData);
}

function extractData(res) {
  return res.data;
}

function mapData(data) {
  return {
    pages: data.pages,
    uploadedFiles: data.files.map((file) => mapServerFilesToLocal(file)),
  };
}

export function getFileLink(id) {
  return client
    .get(`/files/${id}/download_link`)
    .then((res) => res.data['download_link']);
}
