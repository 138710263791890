import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import reduce from 'lodash/reduce';
import format from 'date-fns/format';

export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...keyValues);
}

export function isBlank(str) {
  return !str || str.trim().length === 0;
}

export function formatCurrency(number, sign) {
  sign = sign || '$';
  number = parseFloat(number);
  if (isNaN(number)) {
    return `${sign} 0.00`;
  }
  return `${sign} ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
}

export function mapArrayForSelect(map, arr) {
  return arr.map((v) => {
    return { value: v, name: get(map, v, v) };
  });
}

export function getUrlParams() {
  const s1 = window.location.search
      .substring(1, window.location.search.length)
      .split('&'),
    r = {};
  let s2;
  for (let i = 0; i < s1.length; i += 1) {
    s2 = s1[i].split('=');
    r[decodeURIComponent(s2[0]).toLowerCase()] = decodeURIComponent(s2[1]);
  }
  return r;
}

export const objectKeysToCamelCase = (obj) => {
  if (!isObject(obj)) {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map((v) => objectKeysToCamelCase(v));
  }
  return reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        [camelCase(k)]: objectKeysToCamelCase(v),
      };
    },
    {}
  );
};

export const objectKeysToUnderscore = (obj) => {
  if (!isObject(obj)) {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map((v) => objectKeysToUnderscore(v));
  }
  return reduce(
    obj,
    (r, v, k) => {
      return {
        ...r,
        [snakeCase(k)]: objectKeysToUnderscore(v),
      };
    },
    {}
  );
};

export const snakeCase = (str) => {
  return str
    .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, '');
};

export function extractData(res) {
  return res.data;
}

export function formatDateRange(dateRange) {
  return dateRange.map(formatDate).join(' - ');
}

export function addIfNumber(attr, number) {
  return isNaN(attr) ? attr : parseInt(attr) + number;
}

export function newIdx() {
  return (
    Number(String(Math.random()).slice(2)) +
    Date.now() +
    Math.round(performance.now())
  )
    .toString(36)
    .substring(0, 4);
}

export function* incrementalNumber(start = 0, end = 100, step = 1) {
  for (let i = start; i < end; i += step) {
    yield i;
  }
}

export function countryToLangCode(countryName) {
  switch (countryName) {
    case 'argentina':
    case 'uruguay':
      return 'es-AR';
    case 'dominicana': // TODO Which one is correct?
    case 'republicadominicana':
      return 'es-DO';
    case 'ecuador':
      return 'es-EC';
    default:
      // eslint-disable-next-line no-console
      console.warn('Missing langCode for country', countryName);
      return 'es';
  }
}

export function formatDate(date) {
  return format(date, 'dd/MM/yyyy');
}
