import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Build } from 'styled-icons/material/Build';
import { H2, Paragraph } from '../../common/Typography';

const EmptyBar = styled.div`
  width: 100%;
  height: 56px;
  /* IncreaseBlack */
  background: #182026;
`;

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 56px);
  background-color: #f5f5f5;
`;

const Circle = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  /* IncreaseWhite Medium */
  background: #d8d8d8;
  border-radius: 50%;
  margin: 3rem auto;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f5f5f5;
  }
`;

const Message = styled.div`
  width: 500px;
  margin: 0 auto;
  h2,
  p {
    text-align: center;
  }
`;
const MaintenanceScreen = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <EmptyBar />
      <Circle>
        <Build size={100} />
      </Circle>
      <Message>
        <H2>{t('maintenance.title')}</H2>
        <Paragraph>{t('maintenance.message')}</Paragraph>
      </Message>
    </Wrapper>
  );
};

export default MaintenanceScreen;
