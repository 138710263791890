import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css as CSS } from 'styled-components';
import { MoreHoriz } from 'styled-icons/material/MoreHoriz';
import IconButton from './IconButton';
import { CellText } from './Typography';

const PopOverWrapper = styled.div`
  position: absolute;
  ${({ position }) => CSS(position)}
  text-align: ${({ expandPosition }) =>
    (expandPosition.includes('left') && 'left') ||
    (expandPosition.includes('right') && 'right')};
  background-color: #fff;
  /* IncreaseBlack Light */
  border: 1px solid #44494d;
  /* Black cast */
  box-shadow: 0px 3px 9px rgba(24, 32, 38, 0.18);
  border-radius: 4px;
  padding: 10px;
  z-index: 9999;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ItemButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  text-align: right;
  outline: none;
  :hover ${CellText} {
    color: #0f6bff;
  }
  :disabled ${CellText} {
    color: #a3a3a3;
  }
`;

const CustomIconButton = styled(IconButton)`
  border: 1px solid transparent;
  border-radius: 4px;
  ${({ active }) =>
    active &&
    `
    background-color: #fff;
    svg {
      color: #0f6bff;
    }
    border: 1px solid #44494d;
    box-shadow: 0px 3px 9px rgba(24, 32, 38, 0.18);
  `};
`;

function useOutsideAlerter(ref, onClickOutsideHandle) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutsideHandle();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const PopOver = ({ expandPosition, items, disabled }) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const [popOverPosition, setPopOverPosition] = useState({});
  const popWrapperRef = React.createRef();

  useOutsideAlerter(popWrapperRef, () => setShowPopOver(false));

  function setDropdownPosition() {
    const elemRect = popWrapperRef.current.getBoundingClientRect();
    const propName = expandPosition.includes('right') ? 'left' : 'right';
    setPopOverPosition({
      [propName]: 0,
      top: `${elemRect.height + 5}px`,
    });
  }

  function handleExpand(event) {
    event.stopPropagation();
    setShowPopOver(true);
    setDropdownPosition();
  }

  function handleItemClick(callback, event) {
    event.stopPropagation();
    callback();
    setShowPopOver(false);
  }

  return (
    <div ref={popWrapperRef} style={{ position: 'relative' }}>
      <CustomIconButton
        active={showPopOver}
        disabled={disabled}
        onClick={handleExpand}
      >
        <MoreHoriz size={24} />
      </CustomIconButton>
      {showPopOver && (
        <PopOverWrapper
          expandPosition={expandPosition}
          position={popOverPosition}
        >
          <ItemList>
            {items &&
              items.map(({ name, callback }) => {
                return (
                  <ItemButton
                    key={name}
                    onClick={handleItemClick.bind(this, callback)}
                  >
                    <CellText>{name}</CellText>
                  </ItemButton>
                );
              })}
          </ItemList>
        </PopOverWrapper>
      )}
    </div>
  );
};

PopOver.propTypes = {
  expandPosition: PropTypes.oneOf(['bottom-right', 'bottom-left']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      callback: PropTypes.func,
    })
  ),
};
PopOver.defaultProps = {
  expandPosition: 'bottom-left',
  items: [],
};
export default PopOver;
