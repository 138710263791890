import { mapArrayForSelect } from './helpers';
import partial from 'lodash/partial';
import get from 'lodash/get';
import i18n from '../i18n';

export const mapArrayWithTranslations = (namespace, arr) => {
  if (!arr) {
    return [];
  }

  return arr.reduce((hash, v) => {
    hash[v] = i18n.t(`${namespace}.${v}`);
    return hash;
  }, {});
};

const COMMON_SEPARATORS = [',', ';', '\t'];

const INCREASECARD_FIELDS = [
  'amount',
  'arancel',
  'authorization_number',
  'batch_number',
  'bin',
  'card_brand_id',
  'card_number',
  'clearing_number',
  'coupon_number',
  'description_code',
  'establishment_number',
  'info_provider',
  'installments',
  'origin_date',
  'pay_date',
  'payment_date',
  'payment_id',
  'presentation_date',
  'store_number',
  'total_earn',
  'type',
];

const INCREASECARD_PROVIDERS = [
  'amex',
  'visa',
  'mastercard',
  'cabal',
  'mercadopago',
  'naranja',
];

const DATA_TYPES = [
  'string',
  'date',
  'long',
  'bin',
  'money',
  'card',
  'integer',
  'first_n',
  'last_n',
];

const COMPARATION_TYPES = [
  '=',
  'range',
  'opposite',
  '!=',
  'percentage_range',
  'match_value',
];

export const mapCommonSeparatorsForSelect = (separators) => {
  return mapArrayForSelect(
    mapArrayWithTranslations('constants:common_separators', COMMON_SEPARATORS),
    separators
  );
};

export const mapIncreasecardFieldsForSelect = (val) => {
  if (!val) {
    return [];
  }
  const increasecard_fields = mapArrayWithTranslations(
    'constants:increasecard_fields',
    INCREASECARD_FIELDS
  );
  const values = val.map((v) => {
    return {
      value: v.location,
      name: get(increasecard_fields, v.location, v.location),
    };
  });
  return values;
};

export const mapIncreasecardProvidersForSelect = (arr) => {
  return mapArrayForSelect(
    mapArrayWithTranslations(
      'constants:increasecard_providers',
      INCREASECARD_PROVIDERS
    ),
    arr
  );
};

export const mapDataTypesForSelect = (arr) => {
  return mapArrayForSelect(
    mapArrayWithTranslations('constants:data_types', DATA_TYPES),
    arr
  );
};

export const mapComparationTypesForSelect = (arr) => {
  return mapArrayForSelect(
    mapArrayWithTranslations('constants:comparation_types', COMPARATION_TYPES),
    arr
  );
};
export const mapDateFormatsForSelect = partial(mapArrayForSelect, {});

export const locationBMatchingA = (locationA, scannings) => {
  const scanning = scanningWithLocationA(locationA, scannings);
  return (
    scanning && scanningFieldWithLocationA(locationA, scanning).fieldBLocation
  );
};

const scanningWithLocationA = (locationA, scannings) => {
  return scannings.find((scanning) => {
    return scanningFieldWithLocationA(locationA, scanning);
  });
};

const scanningFieldWithLocationA = (locationA, scanning) => {
  return scanning.scanningFields.find((scanningField) => {
    return scanningField.fieldALocation === locationA;
  });
};

export const mapComparationTypesByFields = (arr) => {
  return mapArrayForSelect(
    mapArrayWithTranslations('constants:comparation_types', COMPARATION_TYPES),
    arr
  );
};

export const comparationTypesForField = (dataType, comparationTypes) => {
  switch (dataType) {
    case 'money':
    case 'integer':
    case 'long':
      return comparationTypes.filter((comparationType) => {
        return comparationType.value !== 'match_value';
      });
    case 'date':
      return comparationTypes.filter((comparationType) => {
        return (
          comparationType.value !== 'percentage_range' &&
          comparationType.value !== 'match_value'
        );
      });
    case 'card':
    case 'bin':
    case 'last_n':
    case 'first_n':
      return comparationTypes.filter((comparationType) => {
        return (
          comparationType.value !== 'range' &&
          comparationType.value !== 'percentage_range' &&
          comparationType.value !== 'match_value'
        );
      });
    case 'string':
      return comparationTypes.filter((comparationType) => {
        return (
          comparationType.value !== 'range' &&
          comparationType.value !== 'percentage_range'
        );
      });
    default:
      throw new Error(`Unknown data type: ${dataType}`);
  }
};
