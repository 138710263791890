import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H1, H2, Paragraph } from '../../common/Typography';
import TextInput from '../../common/TextInput';
import DataPoolSelector from '../../common/DataPoolSelector';
import { Switch, InputNumber } from '@increase/typed-components';
import { profileUpdate } from '../../../reducers/profilesReducer';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  margin-top: 1rem;
`;

const InitialConfig = ({
  disabled,
  usesCard,
  profile,
  dataPoolA,
  dataPoolB,
  onProfileChange,
  onDataPoolAChange,
  onDataPoolBChange,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <H1>{t('profile_creator.new_match_model')}</H1>
      <Paragraph>{t('profile_creator.new_match_model_description')}</Paragraph>
      <Wrapper>
        <InputWrapper>
          <TextInput
            disabled={disabled}
            label={t('profile_creator.initial_configuration.profile_name')}
            onChange={(value) => onProfileChange('name', value)}
            placeholder={t('input_name')}
            value={profile && profile.name}
          />
        </InputWrapper>
        <InputWrapper>
          <TextInput
            disabled={disabled}
            label={t('profile_creator.initial_configuration.data_pool_a_label')}
            onChange={(value) => onDataPoolAChange('name', value)}
            placeholder={t('input_name')}
            value={dataPoolA && dataPoolA.name}
          />
        </InputWrapper>
        <InputWrapper>
          <DataPoolSelector
            dataPoolName={dataPoolB && dataPoolB.name}
            onChange={(value) => onProfileChange('reconciliationType', value)}
            onDataPoolNameChange={(value) => onDataPoolBChange('name', value)}
            readOnly={disabled}
            reconciliationType={profile && profile.reconciliationType}
            usesCard={usesCard}
          />
        </InputWrapper>
      </Wrapper>
      <Wrapper>
        <Paragraph>
          {t(
            'profile_creator.initial_configuration.deprecation_days_description'
          )}
        </Paragraph>
        <InputWrapper>
          <InputNumber
            disabled={disabled}
            label={t(
              'profile_creator.initial_configuration.deprecation_days_label'
            )}
            min={1}
            onChange={(e) => onProfileChange('deprecationDays', e.target.value)}
            value={
              profile && profile.deprecationDays ? profile.deprecationDays : 1
            }
            width="150px"
          />
        </InputWrapper>
      </Wrapper>
      <Wrapper>
        <H2>{t('profile_creator.initial_configuration.state_description')}</H2>
        <Switch
          checked={profile && profile.stable}
          disabled={disabled}
          id={'stable-switch'}
          label={t('profile_creator.initial_configuration.enable_profile')}
          name="stable"
          onChange={(e) => onProfileChange('stable', e.target.checked)}
        />
      </Wrapper>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onProfileChange: (prop, value) => dispatch(profileUpdate(prop, value)),
  onDataPoolAChange: (prop, value) =>
    dispatch({ type: 'UPDATE_DATA_POOL_A', prop, value }),
  onDataPoolBChange: (prop, value) =>
    dispatch({ type: 'UPDATE_DATA_POOL_B', prop, value }),
});

function mapStateToProps(fullState) {
  const { profile } = fullState.profiles;
  const { dataPoolA, dataPoolB } = fullState.dataPools;
  const user = fullState.auth.user;

  const usesCard = user && user.usesCard();
  const disabled = profile.originalStable;

  return {
    profile: profile,
    usesCard: usesCard,
    dataPoolA: dataPoolA,
    dataPoolB: dataPoolB,
    disabled: disabled,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InitialConfig);
