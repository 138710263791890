// import { getUser } from '../services/authenticationService';

const INITIAL_STATE = {
  user: null,
  loading: true,
  maintenanceMode: false,
  hiddenFeatures: false,
};

export function setUser(user) {
  return (dispatch) => {
    return dispatch({ type: 'SET_USER', user });
  };
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return { ...state, loading: true };
    case 'SET_USER':
      return { ...state, user: action.user, loading: false };
    default:
      return state;
  }
};

export default authReducer;
