import React from 'react';
import styled from 'styled-components';
import { Button } from '@increase/typed-components';
import Wizard from '../../common/Wizard/Wizard';
import FullPageSpinner from '../../common/FullPageSpinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  width: calc(100% - 370px);
  flex-direction: column;
  position: relative;
`;

const ContentWrapper = styled.div`
  padding: 2rem 1rem;
  /* Background NotSoWhite */
  background: #fbfbfb;
  height: 100%;
  overflow: auto;
`;

const BottomNavigation = styled.div`
  background-color: white;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  display: flex;
  justify-content: left;
  border-top: 1px solid #d8d8d8;
  button:first-child {
    margin-right: 1rem;
  }
`;

class MultiStep extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: this.props.startPage || 0,
    };
    this.pageCount = React.Children.count(props.children);
  }

  next = () => {
    const { stepList } = this.props;
    let page = Math.min(this.state.page + 1, this.pageCount - 1);
    if (stepList[page].disabled) {
      page = Math.min(page + 1, this.pageCount - 1);
    }
    this.setState(() => ({
      page: page,
    }));
  };

  previous = () => {
    const { stepList } = this.props;
    let page = Math.max(this.state.page - 1, 0);
    if (stepList[page].disabled) {
      page = Math.max(page - 1, 0);
    }
    this.setState(() => ({
      page: page,
    }));
  };

  onSubmit = () => {
    const valid = this.props.submitForm();
    if (valid) return this.next();
    return valid;
  };

  onFinish = () => {
    return this.props.finishForm();
  };

  getFooterButtons() {
    const { t } = this.props;
    const { page } = this.state;
    const isLastPage = page === this.pageCount - 1;
    const isFirstPage = page === 0;

    if (isLastPage) {
      return (
        <React.Fragment>
          <Button buttonType="invisible" onClick={this.previous.bind(this)}>
            {t('back')}
          </Button>
          <Button onClick={this.onSubmit.bind(this)}>
            {t('profile.save_changes')}
          </Button>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          buttonType="invisible"
          disabled={isFirstPage}
          onClick={this.previous.bind(this)}
        >
          {t('back')}
        </Button>
        <Button onClick={this.next.bind(this)}>{t('next')}</Button>
      </React.Fragment>
    );
  }

  render() {
    const {
      children,
      loading,
      logo,
      stepList,
      onClose,
      closeLabel,
    } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    if (loading) {
      return (
        <Wrapper>
          <FullPageSpinner />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Wizard
          activeStep={page}
          closeLabel={closeLabel}
          logo={logo}
          onClose={onClose}
          stepList={stepList}
        />
        <Content>
          <ContentWrapper>{activePage}</ContentWrapper>
          <BottomNavigation>{this.getFooterButtons()}</BottomNavigation>
        </Content>
      </Wrapper>
    );
  }
}

export default MultiStep;
