import Structure from './Structure';
import { newIdx } from '../helpers/helpers';

export default class DataPool {
  constructor({
    id,
    name = '',
    abstractFields = {},
    abstractInformativeFields = {},
    structures = [new Structure({})],
  }) {
    this.id = id;
    this.name = name;
    this.abstractFields = abstractFields;
    this.abstractInformativeFields = abstractInformativeFields;
    this.structures = structures;
  }

  static fromAPI({
    id,
    name,
    abstract_fields: abstractFieldsFromAPI,
    abstract_informative_fields: abstractInformativeFieldsFromAPI,
    date_reference_field_name: referenceField,
    billing_data_pool: billingDataPool,
    structures: structuresFromAPI = [],
  }) {
    const abstractFields = mapAbstractFieldsFromAPI(abstractFieldsFromAPI);
    const abstractInformativeFields = mapAbstractInformativeFieldsFromAPI(
      abstractInformativeFieldsFromAPI
    );

    const structures = structuresFromAPI.map((structure) =>
      Structure.fromAPI(structure, abstractFields, abstractInformativeFields)
    );

    return {
      id,
      name,
      abstractFields: abstractFields,
      abstractInformativeFields: abstractInformativeFields,
      referenceField,
      billingDataPool,
      structures: structures,
    };

    function mapAbstractFieldsFromAPI(abstractFields) {
      const abstractFieldsObj = {};
      abstractFields.forEach((absField) => {
        // When the fields come from api, use the names to identify
        // abstractFields from both dataPools;
        const identifier = absField.name.toLowerCase().split(' ').join('-');
        abstractFieldsObj[identifier] = buildAbstractField(absField);
      });
      return abstractFieldsObj;
    }

    function buildAbstractField(abstractField) {
      return {
        id: abstractField.id,
        name: abstractField.name,
        dataType: abstractField.data_type,
      };
    }

    function mapAbstractInformativeFieldsFromAPI(informativeFields) {
      const informativeFieldsObj = {};
      if (informativeFields) {
        informativeFields.forEach((infField) => {
          informativeFieldsObj[newIdx()] = infField;
        });
      }
      return informativeFieldsObj;
    }
  }

  static toAPI({
    id,
    name,
    abstractFields,
    abstractInformativeFields,
    referenceField,
    isBillingDataPool,
    structures,
  }) {
    return {
      id: id,
      name: name,
      abstract_fields: abstractFields.map(buildAbstractFieldToAPI),
      abstract_informative_fields: Object.values(abstractInformativeFields),
      date_reference_field_name: referenceField,
      billing_data_pool: isBillingDataPool,
      structures: structures.map(Structure.toAPI),
    };
  }
}

function buildAbstractFieldToAPI(abstractField) {
  return {
    id: abstractField.id,
    name: abstractField.name,
    data_type: abstractField.dataType,
  };
}
