import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Button, Label } from '@increase/typed-components';
import { Link as RouterLink } from 'react-router-dom';

const EmptyStateStyled = styled.div`
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(251, 251, 251, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  .textEmptyState {
    margin: 2rem 0 3rem;
    max-width: 40rem;
    text-align: center;
  }
`;

export const EmptyState = ({
  children,
  description,
  buttonText,
  redirectTo,
}) => {
  return (
    <EmptyStateStyled>
      {children}
      <Label className="textEmptyState" weight="bold">
        {description}
      </Label>
      {buttonText && (
        <RouterLink to={redirectTo}>
          <Button>{buttonText}</Button>
        </RouterLink>
      )}
    </EmptyStateStyled>
  );
};
export const FullScreenEmptyState = (props) => {
  return createPortal(
    <FullScreenEmptyState {...props} />,
    document.getElementById('root')
  );
};
