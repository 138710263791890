import React, { useMemo } from 'react';
import styled from 'styled-components';
import Select from './Select';
import PercentageInput from './PercentageInput';
import RangeInput from './RangeInput';
import TextInput from './TextInput';
import PropTypes from 'prop-types';
import { comparationTypesForField } from '../../helpers/profileHelpers';
import { Checkbox } from '@increase/typed-components';

const Container = styled.div`
  display: flex;
  margin-bottom: 0.625rem;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ enabled }) => (enabled ? '1' : '.36')};
`;

const NameContainer = styled.div`
  width: 40%;
  font-size: 1rem;
  line-height: 1.62rem;
  letter-spacing: -0.011em;
`;

const SelectContainer = styled.div`
  width: 40%;
`;

const InputContainer = styled.div`
  width: 15%;
  margin-left: 0.5rem;
`;

function ScanningField({
  comparationTypes,
  field,
  editMode,
  onChange,
  onToggle,
  scanningField,
}) {
  const comparisonType = scanningField ? scanningField.comparationType : '=';
  const comparisonsForFieldType = useMemo(() => {
    return comparationTypesForField(field.dataType, comparationTypes);
  }, [field.dataType, comparationTypes]);

  function onKeyChange(key, value) {
    if (editMode && onChange) {
      onChange({ [key]: value });
    }
  }

  function getComparationTypeInput() {
    switch (comparisonType) {
      case 'range':
        return (
          <RangeInput
            dataType={field.dataType}
            disabled={!editMode}
            onChange={onKeyChange.bind(this)}
            threshold={scanningField.threshold || 0}
          />
        );
      case 'percentage_range':
        return (
          <PercentageInput
            dataType={field.dataType}
            disabled={!editMode}
            onChange={onKeyChange.bind(this)}
            threshold={scanningField.threshold || 0}
          />
        );
      case 'match_value':
        return editMode ? (
          <TextInput
            fullWidth={true}
            onChange={onKeyChange.bind(this, 'value')}
            type="text"
            value={scanningField.value}
          />
        ) : (
          <p className="read-only">{scanningField.value}</p>
        );
      default:
        return null;
    }
  }

  return (
    <Container alignItems="center" enabled={scanningField}>
      <div>
        <Checkbox
          checked={!!scanningField}
          disabled={!editMode}
          id={field.name}
          label=""
          onChange={onToggle && onToggle.bind(this, !scanningField)}
          readOnly={!editMode}
        />
      </div>
      <NameContainer enabled={scanningField}>{field.name}</NameContainer>
      <SelectContainer>
        <Select
          className="comparison-type"
          disabled={!editMode || !scanningField}
          fixParentOverflow={true}
          onSelect={onKeyChange.bind(this, 'comparationType')}
          options={comparisonsForFieldType}
          selected={comparisonType}
        />
      </SelectContainer>
      <InputContainer>{getComparationTypeInput(comparisonType)}</InputContainer>
    </Container>
  );
}

ScanningField.propTypes = {
  comparationTypes: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onToggle: PropTypes.func,
};

export default React.memo(ScanningField);
