import {
  setStructures,
  onUpdateParentField,
  onDeleteParentField,
  createStructuresFields,
  setInformativeFields,
  deleteInformativeField,
  STRUCTURE_KEYS,
} from './structuresReducer';
import {
  onDeleteAbstractField,
  onUpdateAbstractField,
} from './scanningsReducer';
import { newIdx, incrementalNumber } from '../helpers/helpers';
import i18n from '../i18n';
const fieldNumbers = incrementalNumber(1, 20, 1);

export const DATA_POOL_KEYS = {
  DATA_POOL_A: 'dataPoolA',
  DATA_POOL_B: 'dataPoolB',
};

const informativeFieldsNumbers = {
  [DATA_POOL_KEYS.DATA_POOL_A]: incrementalNumber(1, 20, 1),
  [DATA_POOL_KEYS.DATA_POOL_B]: incrementalNumber(1, 20, 1),
};
const counters = {
  getFieldNumber: () => fieldNumbers.next().value,
  getInformativeFieldNumber: (dataPoolKey) =>
    informativeFieldsNumbers[dataPoolKey].next().value,
};

export function newEmptyDataPool(name) {
  return {
    id: '',
    name,
    abstractInformativeFields: {},
  };
}

const newEmptyAbstractField = () => {
  return {
    [newIdx()]: {
      name: `Campo ${counters.getFieldNumber()}`,
      dataType: 'string',
    },
  };
};

const newEmptyAbstractInformativeField = (dataPoolKey) => {
  return {
    [newIdx()]: {
      field: `Campo informativo ${counters.getInformativeFieldNumber(
        dataPoolKey
      )}`,
      order: 0,
    },
  };
};

const INITIAL_STATE = {
  dataPoolA: newEmptyDataPool(`${i18n.t('datapool')} A`),
  dataPoolB: newEmptyDataPool(`${i18n.t('datapool')} B`),
  abstractFields: newEmptyAbstractField(),
  referenceField: null,
  billingDataPool: DATA_POOL_KEYS.DATA_POOL_A,
};

export function setDataPools(dataPoolA, dataPoolB, billingDataPoolName) {
  return (dispatch) => {
    dispatch({ type: 'SET_DATA_POOL_A', dataPool: dataPoolA });
    dispatch({ type: 'SET_DATA_POOL_B', dataPool: dataPoolB });
    dispatch({
      type: 'SET_BILLING_DATAPOOL',
      billingDataPool: billingDataPoolName
        ? billingDataPoolName === dataPoolA.name
          ? DATA_POOL_KEYS.DATA_POOL_A
          : DATA_POOL_KEYS.DATA_POOL_B
        : null,
    });
    const abstractFields = Object.fromEntries(
      Object.entries(dataPoolA.abstractFields).map(([identifier, field]) => [
        identifier,
        { name: field.name, dataType: field.dataType },
      ])
    );
    dispatch({ type: 'SET_ABSTRACT_FIELDS_CONFIG', abstractFields });
    return dispatch(setStructures(dataPoolA.structures, dataPoolB.structures));
  };
}

export function addAbstractInformativeField(dataPoolKey) {
  return (dispatch, getState) => {
    const newFields = {
      ...getState().dataPools[dataPoolKey].abstractInformativeFields,
      ...newEmptyAbstractInformativeField(dataPoolKey),
    };
    Object.values(newFields).map((item, index) => (item.order = index));
    if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_A) {
      dispatch(setInformativeFields(STRUCTURE_KEYS.STRUCTURES_A, newFields));
    } else if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_B) {
      dispatch(setInformativeFields(STRUCTURE_KEYS.STRUCTURES_B, newFields));
    }
    return dispatch({
      type: 'ADD_ABSTRACT_INFORMATIVE_FIELD',
      newFields,
      dataPoolKey,
    });
  };
}

export function deleteAbstractInformativeField(dataPoolKey, fieldId) {
  return (dispatch, getState) => {
    let newFields = Object.entries(
      getState().dataPools[dataPoolKey].abstractInformativeFields
    );
    newFields = Object.fromEntries(newFields.filter(([id]) => fieldId !== id));
    Object.values(newFields).map((item, index) => (item.order = index));
    if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_A) {
      dispatch(deleteInformativeField(STRUCTURE_KEYS.STRUCTURES_A, fieldId));
    } else if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_B) {
      dispatch(deleteInformativeField(STRUCTURE_KEYS.STRUCTURES_B, fieldId));
    }
    return dispatch({
      type: 'DELETE_ABSTRACT_INFORMATIVE_FIELD',
      newFields,
      dataPoolKey,
    });
  };
}

export function updateAbstractInformativeField(dataPoolKey, fieldId, changes) {
  return (dispatch, getState) => {
    const fields = getState().dataPools[dataPoolKey].abstractInformativeFields;
    const informativeField = {
      ...fields[fieldId],
      ...changes,
    };
    if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_A) {
      dispatch(
        setInformativeFields(STRUCTURE_KEYS.STRUCTURES_A, {
          [fieldId]: informativeField,
        })
      );
    } else if (dataPoolKey === DATA_POOL_KEYS.DATA_POOL_B) {
      dispatch(
        setInformativeFields(STRUCTURE_KEYS.STRUCTURES_B, {
          [fieldId]: informativeField,
        })
      );
    }
    return dispatch({
      type: 'UPDATE_ABSTRACT_INFORMATIVE_FIELD',
      newFields: { ...fields, [fieldId]: informativeField },
      dataPoolKey,
    });
  };
}

export function newAbstractField() {
  return (dispatch) => {
    const abstractField = newEmptyAbstractField();
    dispatch({ type: 'NEW_ABSTRACT_FIELD', abstractField });
    return dispatch(createStructuresFields(abstractField));
  };
}

export function deleteAbstractField(idx) {
  return (dispatch, getState) => {
    const { abstractFields } = getState().dataPools;
    dispatch(onDeleteParentField(idx));
    dispatch(onDeleteAbstractField(idx));
    delete abstractFields[idx];
    return dispatch({ type: 'DELETE_ABSTRACT_FIELD', abstractFields });
  };
}

export function updateAbstractField(idx, prop, value) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_ABSTRACT_FIELD', idx, prop, value });
    dispatch(onUpdateParentField(idx, prop, value));
    return dispatch(onUpdateAbstractField(idx, prop, value));
  };
}

export function clearDataPoolState() {
  return (dispatch) => {
    return dispatch({ type: 'CLEAR_DATA_POOL_STATE' });
  };
}

export function setReferenceField(newReferenceField) {
  return (dispatch) => {
    return dispatch({ type: 'SET_REFERENCE_FIELD', newReferenceField });
  };
}

export function setBillingDataPool(billingDataPool) {
  return (dispatch) => {
    return dispatch({ type: 'SET_BILLING_DATAPOOL', billingDataPool });
  };
}

const dataPoolsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_DATA_POOL_A':
      return {
        ...state,
        referenceField: action.dataPool.referenceField,
        dataPoolA: action.dataPool,
      };
    case 'SET_DATA_POOL_B':
      return {
        ...state,
        referenceField: action.dataPool.referenceField,
        dataPoolB: action.dataPool,
      };
    case 'SET_ABSTRACT_FIELDS_CONFIG':
      return {
        ...state,
        abstractFields: { ...action.abstractFields },
      };
    case 'UPDATE_DATA_POOL_A':
      return {
        ...state,
        dataPoolA: { ...state.dataPoolA, [action.prop]: action.value },
      };
    case 'UPDATE_DATA_POOL_B':
      return {
        ...state,
        dataPoolB: { ...state.dataPoolB, [action.prop]: action.value },
      };
    case 'ADD_ABSTRACT_INFORMATIVE_FIELD':
    case 'DELETE_ABSTRACT_INFORMATIVE_FIELD':
    case 'UPDATE_ABSTRACT_INFORMATIVE_FIELD':
      return {
        ...state,
        [action.dataPoolKey]: {
          ...state[action.dataPoolKey],
          abstractInformativeFields: action.newFields,
        },
      };
    case 'NEW_ABSTRACT_FIELD':
      return {
        ...state,
        abstractFields: {
          ...state.abstractFields,
          ...action.abstractField,
        },
      };
    case 'DELETE_ABSTRACT_FIELD':
      return { ...state, abstractFields: action.abstractFields };
    case 'UPDATE_ABSTRACT_FIELD':
      return {
        ...state,
        abstractFields: {
          ...state.abstractFields,
          [action.idx]: {
            ...state.abstractFields[action.idx],
            [action.prop]: action.value,
          },
        },
      };
    case 'SET_REFERENCE_FIELD':
      return { ...state, referenceField: action.newReferenceField };
    case 'SET_BILLING_DATAPOOL':
      return { ...state, billingDataPool: action.billingDataPool };
    case 'CLEAR_DATA_POOL_STATE':
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default dataPoolsReducer;
