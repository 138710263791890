import React from 'react';
import styled from 'styled-components';
import { Modal, ModalContent } from '@increase/typed-components';
import { useTranslation } from 'react-i18next';
import { H1, Paragraph } from './Typography';

const SupportLink = styled.a`
  font-size: 15px;
  color: #0f6bff;
`;

export function GenericErrorModal({ isVisible, onClose }) {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose} visible={isVisible}>
      <ModalContent>
        <H1>{t('there_was_an_error')}</H1>
        <Paragraph>
          {t('try_again_later')}{' '}
          <SupportLink href="https://soporte.increasecard.com/hc/es/requests/new">
            {t('support_center')}
          </SupportLink>
        </Paragraph>
        <br />
        <Paragraph>{t('sorry_for_the_incovenience')}</Paragraph>
      </ModalContent>
    </Modal>
  );
}
