import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useEventTracker } from '@increasecard/increase-core';

const capitalize = (s = '') => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1, s.length)}`;
};

// const PRODUCT_NAME = 'conciliation';
// mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
// WHY THIS CONFIG WAS NEEDED????
//   cookie_name: PRODUCT_NAME,
// });

export const EVENTS = {
  RECONCILIATION: 'Conciliation',
  MANUAL_RECONCILIATION: 'Manual Conciliation',
  VIEW_PAGE: 'View Conciliation',
  VIEW_ONBOARDING: 'View onboarding',
  DOWNLOAD_REPORT: 'Download Conciliation Report',
};

const getEventFromPathname = (pathname) => {
  const pathnames = pathname.slice(1).split('/');
  let eventString = '';
  if (pathnames[0] === 'profiles') {
    eventString = `${EVENTS.VIEW_PAGE} ${capitalize(
      pathnames[pathnames.length - 1]
    )}`;
  } else if (pathnames[0] === 'onboarding') {
    eventString = `${EVENTS.VIEW_ONBOARDING} ${capitalize(
      pathnames[pathnames.length - 1]
    )}`;
  }
  return eventString;
};

export function usePageViewTracking() {
  const location = useLocation();
  const eventTracker = useEventTracker();
  useEffect(() => {
    eventTracker.track(getEventFromPathname(location.pathname));
  }, [location, eventTracker]);
}
