import { getStructureMetadata } from '../services/fileStructureService';
import camelCase from 'lodash/camelCase';

const INITIAL_STATE = {
  commonSeparators: [],
  increasecardFields: { movements: [], payments: [] },
  increasecardProviders: [],
  dataTypes: [],
  comparationTypes: [],
  dateFormats: [],
  fetching: false,
  cached: false,
  increasecardFiltersStructure: {
    movements: {},
    payments: {},
  },
};

const mapIncreaseCardFields = (fields) => {
  const increasecardFields = {};
  Object.entries(fields).forEach(([name, value]) => {
    increasecardFields[name] = value.reduce((acc, curr) => {
      if (!curr.filterable) return acc;

      acc[camelCase(curr.location)] = {
        dataType: curr.dataType,
      };
      return acc;
    }, {});
  });
  return increasecardFields;
};

export function fetchStructureMetadata() {
  return (dispatch) => {
    dispatch({ type: 'STRUCTURE_METADATA_REQUEST' });
    return getStructureMetadata().then((fileStructures) => {
      fileStructures.increasecardFiltersStructure = mapIncreaseCardFields(
        fileStructures.increasecardFields
      );
      const { movements, payments, file } = fileStructures.rowsKeys;
      fileStructures.rowsKeys = {
        movements: movements.map(camelCase),
        payments: payments.map(camelCase),
        file: file.map(camelCase),
      };
      dispatch({
        type: 'STRUCTURE_METADATA_FETCHED',
        fileStructures,
      });
    });
  };
}

const increaseDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'STRUCTURE_METADATA_REQUEST':
      return {
        ...state,
        commonSeparators: [],
        increasecardFields: { movements: [], payments: [] },
        increasecardProviders: [],
        dataTypes: [],
        comparationTypes: [],
        dateFormats: [],
        fetching: true,
        cached: false,
      };
    case 'STRUCTURE_METADATA_FETCHED':
      return {
        ...state,
        ...action.fileStructures,
        fetching: false,
        cached: true,
      };
    default:
      return state;
  }
};

export default increaseDataReducer;
