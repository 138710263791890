import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SeparatorSelector from './SeparatorSelector';
import TextInput from '../TextInput';
import NumberInput from '../NumberInput';
import { InputLabel } from '../Typography';
import LinkButton from '../LinkButton';
import IgnoreLinesConfiguration from './IgnoreLinesConfiguration';
import MatchFieldsConfigTable from './MatchFieldsConfigTable';
import InformativeFieldsConfigTable from './InformativeFieldsConfigTable';
import IconButton from '../../common/IconButton';
import { DeleteForever } from 'styled-icons/material/DeleteForever';
import ProfileValidator from '../ProfileValidator';

const Wrapper = styled.div`
  max-width: 997px;
  background-color: #fff;
  /* IncreaseWhite Medium */
  border: 1px solid #d8d8d8;
  border-radius: 9px;
  padding: 1rem;
  hr {
    margin: 2rem 0 1rem 0;
  }
`;

const SplitPanel = styled.div`
  display: flex;
  padding: 1rem 0 2rem;
  border-bottom: 1px solid #b1b1b1;
  .left-panel {
    width: 25%;
    margin-right: 3rem;
  }
  .right-panel {
    width: 75%;
  }
  .lines-to-remove {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .lines-to-remove button {
    padding-right: 0;
  }
`;

const AdvancedConfig = styled.div`
  overflow: hidden;
  transition: height 250ms ease-in-out;
  height: ${({ expand, expandHeight }) => (expand ? `${expandHeight}px` : '0')};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: 1rem;
  }
  > * {
    width: 70px;
  }
`;

const StructureEditor = ({
  onPropChange,
  structure,
  disabled,
  onAddIgnoredLine,
  onDeleteIgnoredLine,
  onChangeIgnoredLine,
  onRemoveStructure,
  onChangeField,
  onInformativeFieldChange,
  onClearValidationState,
  onValidate,
  validationResults,
  dateFormats,
  commonSeparators,
  reconciliationType,
  structureIndex,
}) => {
  const [advConfigPanelExpand, setAdvConfigPanelExpand] = useState(false);
  const [advConfigPanelHeight, setAdvConfigPanelHeight] = useState(false);
  const { t } = useTranslation();
  const advConfigRef = React.createRef();
  const getNodeHeight = (node) => (node ? node.offsetHeight : 0);
  const expandAdvConfigHandle = () => {
    setAdvConfigPanelExpand(!advConfigPanelExpand);
    setAdvConfigPanelHeight(getNodeHeight(advConfigRef.current));
  };

  useEffect(() => {
    setAdvConfigPanelHeight(getNodeHeight(advConfigRef.current));
  }, [setAdvConfigPanelHeight, advConfigRef]);
  return (
    <Wrapper>
      <ProfileValidator
        onClearValidationState={onClearValidationState}
        onValidate={onValidate}
        validationResults={validationResults}
      />
      <SplitPanel>
        <div className="left-panel">
          <TextInput
            className="field-name"
            fullWidth={true}
            label={t('profile.file_structure_name')}
            onChange={(value) => onPropChange('name', value)}
            placeholder={t('input_name')}
            type="text"
            value={structure && structure.name ? structure.name : ''}
          />
        </div>
        <div className="right-panel">
          <SeparatorSelector
            commonSeparators={commonSeparators}
            disabled={disabled}
            onChange={(value) => onPropChange('delimiter', value)}
            selected={structure.delimiter}
          />
          <div className="lines-to-remove">
            <InputWrapper>
              <InputLabel>{t('profile.header_lines')}</InputLabel>
              <NumberInput
                disabled={disabled}
                min={0}
                onChange={(value) => onPropChange('headersCount', value)}
                value={structure.headersCount ? structure.headersCount : 0}
              />
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{t('profile.footer_lines')}</InputLabel>
              <NumberInput
                disabled={disabled}
                min={0}
                onChange={(value) => onPropChange('footersCount', value)}
                value={structure.footersCount}
              />
            </InputWrapper>
            <LinkButton onClick={expandAdvConfigHandle}>
              {t('profile.show_advanced_config')}
            </LinkButton>
          </div>
        </div>
      </SplitPanel>
      <AdvancedConfig
        expand={advConfigPanelExpand}
        expandHeight={advConfigPanelHeight}
      >
        <SplitPanel ref={advConfigRef}>
          <div className="left-panel">
            <TextInput
              className="string-to-remove"
              fullWidth={true}
              label={t('profile.text_to_remove')}
              onChange={(value) => onPropChange('stringToRemove', value)}
              placeholder="Ingresar texto"
              type="text"
              value={structure.stringToRemove || ''}
            />
          </div>
          <div className="right-panel">
            <IgnoreLinesConfiguration
              lines={structure.ignoredLines}
              onAddLine={onAddIgnoredLine}
              onChangeLine={onChangeIgnoredLine}
              onDeleteLine={onDeleteIgnoredLine}
            />
          </div>
        </SplitPanel>
      </AdvancedConfig>
      <div style={{ display: 'flex' }}>
        <MatchFieldsConfigTable
          commonSeparators={commonSeparators}
          dateFormats={dateFormats}
          disabled={disabled}
          fields={structure.fields}
          onChangeField={onChangeField}
          reconciliationType={reconciliationType}
        />
        <InformativeFieldsConfigTable
          disabled={disabled}
          informativeFields={structure.informativeFields}
          onInformativeFieldChange={onInformativeFieldChange}
        />
      </div>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <IconButton
          disabled={disabled}
          onClick={() => onRemoveStructure(structureIndex)}
        >
          <DeleteForever size={24} />
        </IconButton>
      </div>
    </Wrapper>
  );
};

export default StructureEditor;
