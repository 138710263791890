import * as userService from '../services/userService';

let user = null;

export async function isAuthenticated() {
  try {
    user = await userService.me();
    return true;
  } catch (error) {
    return false;
  }
}

export async function authenticateUser() {
  return await userService.me();
}

export function getUser() {
  return user;
}
