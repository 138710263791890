import React from 'react';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { withTranslation } from 'react-i18next';
import MultiStep from './MultiStep';
import UploadDragZone from '../../common/FileUpload/UploadDragZone';
import UploadStatus from '../../common/FileUpload/UploadStatus';
import Table from '../../common/Table';
import ProgressBar from '../../common/ProgressBar';
import LinkButton from '../../common/LinkButton';
import { InfoOutline as InfoIcon } from 'styled-icons/material/InfoOutline';
import Welcome from './Welcome';
import {
  setValue,
  submitOnBoarding,
  setPage,
  getPage,
  getPath,
} from './../../../reducers/onboardingReducer';
import {
  // eslint-disable-next-line no-unused-vars
  clearFileUpload,
  uploadFile,
  retryUpload,
  fileTypeError,
  removeFile,
} from './../../../reducers/fileUploaderReducer';

import WizardHeader from './../../common/Wizard/WizardHeader';

const {
  EMPTY,
  UPLOADING,
  COMPLETED,
  UPLOAD_ERROR,
  FILE_TYPE_ERROR,
} = UploadStatus;

const UploadWrapper = styled(Flex)`
  width: 600px;
  > :nth-child(2) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -3px;
  }
`;

const DescriptionLabel = styled.label`
  font-size: 0.625rem;
  letter-spacing: 0.09em;
  text-transform: uppercase;
`;

const TextArea = styled.textarea`
  width: 600px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-family: inherit;
  margin-top: 0.375rem;
  padding: 0.75rem;
  outline: none;
  resize: vertical;
  :focus {
    border-color: #87b5ff;
  }
`;

const RemoveButton = styled(LinkButton)`
  /* Alerta */
  ${({ type }) => type === 'error' && 'color: #e71321'};
`;

const ErrorMessage = styled.div`
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.09em;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  .file-name-message {
    margin-left: 0.5rem;
  }
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

function Action({ file, onRetry, onRemove, t }) {
  switch (file.status) {
    case UPLOADING:
      return <ProgressBar progress={file.progress} />;
    case UPLOAD_ERROR:
      return (
        <LinkButton onClick={() => onRetry(file)}>{t('retry')}</LinkButton>
      );
    case FILE_TYPE_ERROR:
      return (
        <RemoveButton onClick={() => onRemove(file)} type="error">
          {t('delete')}
        </RemoveButton>
      );
    case COMPLETED:
    case EMPTY:
    default:
      return (
        <RemoveButton onClick={() => onRemove(file)}>
          {t('delete')}
        </RemoveButton>
      );
  }
}
function FileRow({ file, onRetry, onRemove, t }) {
  const fileTypeError = file.status === FILE_TYPE_ERROR;
  const uploadError = file.status === UPLOAD_ERROR;

  return (
    <tr className={fileTypeError || uploadError ? 'error' : ''} key={file.name}>
      <td title={file.name}>
        <NameWrapper>
          <span>
            {(fileTypeError || uploadError) && <InfoIcon size="20" />}
          </span>
          <div className="file-name-message">
            {file.name}
            {fileTypeError && (
              <ErrorMessage>
                {t('file_upload_invalid_file_type_short')}
              </ErrorMessage>
            )}
            {uploadError && (
              <ErrorMessage>{t('file_upload_error')}</ErrorMessage>
            )}
          </div>
        </NameWrapper>
      </td>
      <td className="file-type">{file.name.split('.')[1].toUpperCase()}</td>
      <td className="actions">
        <Action file={file} onRemove={onRemove} onRetry={onRetry} t={t} />
      </td>
    </tr>
  );
}

const FilesTable = styled(Table)`
  table-layout: fixed;
  background-color: none;

  tr.error td {
    /* Alerta */
    color: #e71321;
  }
  td.actions {
    text-align: right;
  }
  td.file-type {
    text-align: center;
  }
  & .file-name {
    width: 70%;
  }
  & .file-type {
    width: 15%;
  }
  & .actions {
    width: 15%;
  }
`;

class Onboarding extends React.Component {
  submitForm() {
    const { dispatch, description, files } = this.props;
    const filesCounter = files.length > 0;
    if (filesCounter && description) dispatch(submitOnBoarding());
    return filesCounter;
  }

  canSubmit() {
    const { description, files } = this.props;
    return files.length > 0 && description;
  }

  handleChange(event) {
    const { dispatch } = this.props;
    return dispatch(setValue(event.target.name, event.target.value));
  }

  render() {
    const {
      files,
      onDropAccepted,
      onDropRejected,
      onFileRemove,
      onFinish,
      onRetry,
      onStepChange,
      page,
      valid,
      t,
      user,
    } = this.props;
    return (
      <React.Fragment>
        <MultiStep
          canSubmit={this.canSubmit()}
          finishForm={onFinish}
          onStepChange={onStepChange}
          page={page}
          stepList={[
            {
              label: '',
              text: t('welcome'),
            },
            {
              label: `${t('step')} 1`,
              text: t('files'),
            },
            {
              label: `${t('step')} 2`,
              text: t('next_steps'),
            },
          ]}
          submitForm={this.submitForm.bind(this)}
          t={t}
          valid={valid}
        >
          <MultiStep.Page>
            <Welcome username={user && user.firstName} />
          </MultiStep.Page>
          <MultiStep.Page>
            <WizardHeader
              text={t('onboarding.files_step.text')}
              title={t('onboarding.files_step.title')}
            />
            <UploadWrapper mt="1rem">
              <UploadDragZone
                files={files}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                onRemove={onFileRemove}
                onRetry={onRetry}
              >
                <div
                  style={{
                    borderBottom: '1px solid #b1b1b1',
                    paddingBottom: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  <DescriptionLabel>
                    {t('onboarding.files_step.description_label')}
                  </DescriptionLabel>
                  <TextArea
                    key="description"
                    name="description"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    placeholder={t('onboarding.files_step.description')}
                    rows="5"
                  />
                </div>

                <FilesTable>
                  <colgroup>
                    <col className="file-name" />
                    <col className="file-type" />
                    <col className="actions" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{t('filename')}</th>
                      <th>{t('filetype')}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file) => (
                      <FileRow
                        file={file}
                        key={file.name}
                        onRemove={onFileRemove}
                        onRetry={onRetry}
                        t={t}
                      />
                    ))}
                  </tbody>
                </FilesTable>
              </UploadDragZone>
            </UploadWrapper>
          </MultiStep.Page>
          <MultiStep.Page>
            <WizardHeader
              text={t('onboarding.confirmed_step.text')}
              title={t('onboarding.confirmed_step.title')}
            />
          </MultiStep.Page>
        </MultiStep>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onFinish: () => {
    dispatch({ type: 'ON_FINISHED' });
    return ownProps.history.push('/profiles');
  },
  onValidateError: () => {
    return dispatch({ type: 'VALIDATE_ERROR' });
  },
  onStepChange: (page) => {
    const path = getPath(page);
    dispatch(setPage(page));
    return ownProps.history.push(`/onboarding/${path}`);
  },
  onRetry: (file) => dispatch(retryUpload(file)),
  onDropAccepted: (files) => {
    files.forEach((file) => {
      dispatch(uploadFile(file));
    });
  },
  onDropRejected: (files) => {
    files.forEach((file) => {
      dispatch(fileTypeError(file));
    });
  },
  onFileRemove: (fileName) => {
    return dispatch(removeFile(fileName));
  },
});

function mapStateToProps(fullState, ownProps) {
  const state = fullState.onboarding;
  const { files, uploadedFilesCount } = fullState.fileUploader;
  const user = fullState.auth.user;
  const path = ownProps.location.pathname.replace('/onboarding/', '');
  const page = getPage(path);

  return {
    fetching: state.fetching,
    files: Object.values(files),
    uploadedFilesCount,
    user: user,
    description: state.description,
    valid: state.valid,
    page: page,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Onboarding));
