import React from 'react';
import styled from 'styled-components';
import { Modal } from '@increase/typed-components';
import doubleCheckmark from './double-checkmark.png';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  background: none;
  padding: 0;
  overflow: hidden;
  border: none;
  border-radius: 3px;
  position: relative;
  z-index: -1;

  & > .top-container {
    padding: 1rem;
    background-color: #465253;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.06);
  }

  & .top-container .summary-wrapper {
    display: flex;
    justify-content: space-between;
  }

  & .top-container .summary-wrapper > * {
    flex: 1;
  }

  & .top-container .summary-wrapper div:nth-child(2) {
    border-left: 1px solid rgba(1, 1, 1, 0.2);
  }

  & .top-container h1 {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 28px;
    color: white;
    padding-bottom: 1rem;
  }

  & > .top-container h1.title {
    margin: 0;
  }

  & > .top-container h1.percentage {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif;
    font-size: 42px;
    color: #00dd00;
    font-weight: bold;
  }

  & .subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  & .double-checkmark {
    width: 40px;
    height: 24px;
    margin-right: 17px;
  }

  & > .top-container p {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 15px;
    color: white;
    text-align: left;
  }

  & > .bottom-container {
    display: flex;
    background-color: #f7f7f7;
  }
  & > .bottom-container {
    display: flex;
    justify-content: center;
    background-color: #f7f7f7;
  }

  & > .bottom-container .entries-container {
    width: 50%;
    background-color: white;
    margin: 1rem;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #465253;
    padding: 1rem;
    text-align: left;
  }

  & > .bottom-container ol {
    list-style: none;
    margin: 0;
    padding-left: 5px;
    opacity: 0.7;
    font-size: 12px;
  }

  & > .bottom-container .download-link-container {
    padding: 22px;
    text-align: center;
  }

  & > .bottom-container .download-link-container > p {
    font-size: 13px;
    font-weight: bold;
    color: #43425d;
    margin: 5px 0;
  }
`;

const ResultsDetails = ({ dataPoolInfo }) => {
  const { t } = useTranslation();
  const ordinal = [
    t('first'),
    t('second'),
    t('third'),
    t('fourth'),
    t('fifth'),
    t('sixth'),
    t('seventh'),
    t('eighth'),
    t('nineth'),
    t('tenth'),
    t('eleventh'),
    t('twelfth'),
    t('thirteenth'),
    t('fourteenth'),
    t('fifteenth'),
    t('sixteenth'),
    t('seventeenth'),
    t('eighteenth'),
    t('nineteenth'),
    t('twentieth'),
  ];
  const {
    conciliated,
    nonConciliated,
    pending,
    totalLines,
    deprecated,
    scannings,
  } = dataPoolInfo;
  const sortedScannings = sortBy(scannings, 'scanningNumber');
  return (
    <div className="entries-container">
      <span>
        <b>{t('conciliated_entries')}</b>
        {conciliated}
      </span>
      <ol>
        {sortedScannings.map((scanning, idx) => {
          return (
            <li key={idx}>
              {`${scanning.conciliatedPercentage}% ${t('in_the')} ${
                ordinal[scanning.scanningNumber - 1]
              } ${t('swept')} (${scanning.reconciliedLines} ${t('entries')})`}
            </li>
          );
        })}
      </ol>
      <span>
        <b>{t('entries_not_reconciled')}</b>
        {nonConciliated}
      </span>
      {nonConciliated > 0 && (
        <ol>
          <li>
            <span>
              {((pending * 100) / totalLines).toFixed(1)}%{t('pending_lines')}(
              {pending} {t('entries')})
            </span>
          </li>
          {deprecated > 0 && (
            <li>
              <span>
                {((deprecated * 100) / totalLines).toFixed(1)}%
                {t('deprecated_lines')}({deprecated} {t('entries')})
              </span>
            </li>
          )}
        </ol>
      )}
    </div>
  );
};

const ResultsSummary = ({
  dataPoolName,
  conciliatedPercentage,
  totalLines,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="datpool-name">{dataPoolName}</h1>
      <h1 className="percentage">{conciliatedPercentage}%</h1>
      <div className="subtitle">
        <div height="24px">
          <img alt="" className="double-checkmark" src={doubleCheckmark} />
        </div>
        <div>
          <p>{t('conciliated_operations')}</p>
          <p>
            {totalLines} {t('analized_records')}
          </p>
        </div>
      </div>
    </div>
  );
};

const ModalContent = ({ conciliationResults = {}, dataPoolNames }) => {
  const { t } = useTranslation();
  const { dataPoolA = {}, dataPoolB = {} } = conciliationResults;
  return (
    <StyledCard>
      <div className="top-container">
        <h1 className="title">{t('results')}</h1>
        <div className="summary-wrapper">
          <ResultsSummary
            conciliatedPercentage={dataPoolA.conciliatedPercentage}
            dataPoolName={dataPoolNames.A}
            totalLines={dataPoolA.totalLines}
          />
          {dataPoolB && (
            <ResultsSummary
              conciliatedPercentage={dataPoolB.conciliatedPercentage}
              dataPoolName={dataPoolNames.B}
              totalLines={dataPoolB.totalLines}
            />
          )}
        </div>
      </div>
      <div className="bottom-container">
        <ResultsDetails dataPoolInfo={dataPoolA} />
        {dataPoolB && <ResultsDetails dataPoolInfo={dataPoolB} />}
      </div>
    </StyledCard>
  );
};

function DetailsModal({
  isVisible,
  onClose,
  conciliationResults,
  dataPoolNames,
}) {
  return (
    <Modal onClose={onClose} visible={isVisible} width={700}>
      <ModalContent
        conciliationResults={conciliationResults}
        dataPoolNames={dataPoolNames}
      />
    </Modal>
  );
}

export default DetailsModal;
