import axios from 'axios';

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ||
  'https://private-anon-81da470ac8-increaseconciliationapi.apiary-mock.com';

const client = axios.create({
  baseURL: `${API_BASE_URL}/api/v1`,
  withCredentials: true,
  transformRequest: [
    (data) => {
      if (data && data.get && data.get('x-amz-credential')) {
        return data;
      }
      return JSON.stringify(data);
    },
  ],
  headers: {
    Accept: 'application/json',
    Tenant: 'argentina',
    'Content-Type': 'application/json',
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = process.env.REACT_APP_REDIRECT_URL;
      return null;
    }
    return Promise.reject(error);
  }
);

export default client;
