import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FullPageSpinner from './FullPageSpinner';

const StyledTable = styled.table`
  font-family: Inter, sans-serif;
  width: 100%;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  color: #182026;

  thead {
    text-transform: uppercase;
    text-align: left;
    border-bottom: 3px solid #182026;
    th {
      font-weight: 500;
      font-size: 12px;
    }
  }

  && tbody > tr:hover {
    background-color: rgba(216, 216, 216, 0.65);
  }

  tr:nth-child(2n) {
    background-color: #f5f5f5;
  }
  th {
    padding: 5px 9px;
  }
  td {
    padding: 9px;
  }
  th,
  td {
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
  }
`;

function Table({ className, children, loading }) {
  if (loading) {
    return <FullPageSpinner />;
  }
  return <StyledTable className={className}>{children}</StyledTable>;
}

Table.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  loading: PropTypes.bool,
};

export default Table;
