import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Equals } from 'styled-icons/fa-solid/Equals';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
} from '@increase/typed-components';
import LinkButton from '../LinkButton';
import TextInput from '../TextInput';
import { H2 } from '../Typography';

AliasConfigModal.propTypes = {
  aliases: PropTypes.arrayOf(
    PropTypes.shape({
      originalValue: PropTypes.string,
      possibleValues: PropTypes.string,
    })
  ).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  show: PropTypes.bool.isRequired,
};

const AliasRow = styled.div`
  display: flex;
  padding: 1rem 0 0.675rem 0;
  border-bottom: 1px solid #b1b1b1;
  & svg {
    margin: 0.5rem;
  }
  .possible-values {
    flex: 1;
    textarea {
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      width: 100%;
      min-height: 80px;
      resize: none;
      :focus {
        outline: none;
      }
    }
  }
  :last-child {
    margin-bottom: 0.675rem;
  }
`;

export default function AliasConfigModal({
  readOnly,
  show,
  aliases,
  onSave,
  onCancel,
}) {
  const { t } = useTranslation();
  const [localAliases, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'addAlias':
        return Object.assign({}, state, {
          [Date.now()]: { originalValue: '', possibleValues: '' },
        });
      case 'deleteAlias':
        delete state[action.aliasId];
        return Object.assign({}, state);
      case 'updateAlias':
        return Object.assign({}, state, {
          [action.aliasId]: {
            ...state[action.aliasId],
            ...action.changes,
          },
        });
      default:
        return null;
    }
  }, Object.assign({}, aliases));
  return (
    <Modal onClose={onCancel} visible={show}>
      <H2 align="left">{t('alias_config')}</H2>
      <ModalContent align="left">
        {t('alias_explanation')}
        {Object.entries(localAliases).map(([id, alias]) => {
          return (
            <AliasRow
              key={id}
              style={{
                display: 'flex',
                borderBottom: '1px solid #b1b1b1',
              }}
            >
              <div>
                <TextInput
                  disabled={readOnly}
                  onChange={(value) =>
                    dispatch({
                      type: 'updateAlias',
                      aliasId: id,
                      changes: { originalValue: value },
                    })
                  }
                  value={alias.originalValue}
                />
                <LinkButton
                  disabled={readOnly}
                  onClick={() => dispatch({ type: 'deleteAlias', aliasId: id })}
                  type="alert"
                >
                  {t('delete_alias')}
                </LinkButton>
              </div>
              <Equals size={24} />
              <div className="possible-values">
                <textarea
                  disabled={readOnly}
                  onChange={(event) =>
                    dispatch({
                      type: 'updateAlias',
                      aliasId: id,
                      changes: { possibleValues: event.target.value },
                    })
                  }
                  value={alias.possibleValues}
                />
              </div>
            </AliasRow>
          );
        })}
        <LinkButton
          disabled={readOnly}
          onClick={() => dispatch({ type: 'addAlias' })}
          style={{ marginTop: '0.675rem' }}
        >
          {t('add_new_alias')}
        </LinkButton>
      </ModalContent>
      <ModalFooter>
        <Button buttonType="invisible" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          disabled={readOnly}
          onClick={() => onSave(Object.values(localAliases))}
        >
          {t('save_changes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
