import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { usePageViewTracking } from './actionTracker';
import NewConciliation from './components/screens/newConciliation/NewConciliation';
import Reports from './components/screens/reports/Reports';
import ViewDetails from './components/screens/viewDetails/ViewDetails';
import Records from './components/screens/records/Records';
import Onboarding from './components/screens/onboarding/Onboarding';
import ProfileCreator from './components/screens/ProfileCreator/ProfileCreator';
import UploadedFiles from './components/screens/uploadedFiles/UploadedFiles';
import CustomRoute from './CustomRoute';
import DefaultScreen from './components/screens/defaultScreen/DefaultScreen';

export const Routes = () => {
  usePageViewTracking();
  return (
    <Switch>
      <CustomRoute
        component={Onboarding}
        exact
        path="/onboarding/:subpaths?"
        withLayout={false}
      />
      <CustomRoute component={DefaultScreen} exact path="/profiles" />
      <CustomRoute
        component={ProfileCreator}
        exact
        path="/profiles/new"
        withLayout={false}
      />
      <CustomRoute
        component={NewConciliation}
        path="/profiles/:profileId/newconciliation"
      />
      <CustomRoute component={Reports} path="/profiles/:profileId/reports" />
      <CustomRoute component={Records} path="/profiles/:profileId/records" />
      <CustomRoute
        component={ViewDetails}
        path="/profiles/:profileId/details"
      />
      <CustomRoute
        component={UploadedFiles}
        path="/profiles/:profileId/files"
      />
      <Redirect exact from="/" to="/profiles" />
      <Redirect
        exact
        from="/profiles/:profileId"
        to="/profiles/:profileId/newconciliation"
      />
      <CustomRoute
        component={ProfileCreator}
        exact
        path="/profiles/:profileId/edit"
        withLayout={false}
      />
    </Switch>
  );
};
