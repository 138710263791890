import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { css as CSS } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import IconButton from './IconButton';
import { Paragraph } from './Typography';
import { Cancel } from 'styled-icons/material/Cancel';
import { Error as InfoIcon } from 'styled-icons/material/Error';
import { Check } from 'styled-icons/material/Check';
import { Close } from 'styled-icons/material/Close';

const getPlacementStyle = (placement) => {
  if (typeof placement !== 'string') {
    return placement;
  }
  const [y, x] = placement.split('-');
  if (x === 'center') {
    return {
      left: '50%',
      [y]: '2rem',
    };
  }
  return {
    [x]: '2rem',
    [y]: '2rem',
  };
};

const floatStyle = CSS`
  box-shadow: 0px 3px 6px rgba(24, 32, 38, 0.18), 0px 9px 9px rgba(24, 32, 38, 0.09);
  transform: translate(-50%);
  ${({ placement }) => CSS(placement)}
`;

const Container = styled.div`
  position: ${({ placement }) => (placement ? 'absolute' : 'relative')};
  ${({ placement }) => placement && floatStyle}
  z-index: 1001;
  overflow: hidden;
  &.notification-enter {
    max-height: 0;
  }
  &.notification-enter-active {
    max-height: 4rem;
    transition: max-height 300ms ease-in-out;
  }
  &.notification-exit {
    max-height: 4rem;
  }
  &.notification-exit-active {
    max-height: 0;
    transition: max-height 200ms ease-in-out;
  }
`;

const backgroundColors = {
  alert: '#E71321', // Alerta
  error: '#E71321', // Alerta
  info: '#0F6BFF', // Sky
  success: '#099237', // Green Bold
};

const NotificationBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 4rem;
  padding: 0.5rem;
  border-radius: 3px;
  background-color: ${(props) =>
    props.outline ? '#ffffff' : backgroundColors[props.type]};
  & p,
  & svg {
    color: ${(props) =>
      props.outline ? backgroundColors[props.type] : '#ffffff'};
  }
  ${(props) =>
    props.outline && `border: 1px solid ${backgroundColors[props.type]};`}
`;

const MessageContainer = styled(Paragraph)`
  width: 100%;
  margin: 0 0 0 1rem;
`;

const NotificationIcon = (type) => {
  switch (type) {
    case 'error':
    case 'alert':
      return <Cancel size={24} />;
    case 'success':
      return <Check size={24} />;
    case 'info':
    default:
      return <InfoIcon size={24} />;
  }
};

const Notification = ({ placement, ...props }) => {
  return (
    <CSSTransition
      classNames="notification"
      in={props.visible}
      mountOnEnter
      timeout={300}
      unmountOnExit
    >
      <Container placement={placement && getPlacementStyle(placement)}>
        <NotificationBody {...props}>
          <NotificationIcon type={props.type} />
          <MessageContainer weight="bold">{props.message}</MessageContainer>
          {props.onClose && (
            <IconButton onClick={props.onClose}>
              <Close size={24} />
            </IconButton>
          )}
        </NotificationBody>
      </Container>
    </CSSTransition>
  );
};

Notification.defaultProps = {
  message: '',
  outline: false,
  type: 'info',
};

Notification.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  outline: PropTypes.bool,
  type: PropTypes.oneOf(['alert', 'error', 'info', 'success']),
  visible: PropTypes.bool,
};

const getTopInsertionNode = () => {
  let notificationsContainer = document.getElementById('notifications');
  if (!notificationsContainer) {
    notificationsContainer = document.createElement('div');
    notificationsContainer.id = 'notifications';
    const rootElement = document.getElementById('root') || document.body;
    rootElement.insertBefore(notificationsContainer, rootElement.firstChild);
  }
  return notificationsContainer;
};

export function InlineNotification(props) {
  return <Notification {...props} />;
}

export function TopNotification(props) {
  return ReactDOM.createPortal(
    <Notification {...props} />,
    getTopInsertionNode()
  );
}

export function FloatNotification(props) {
  return <TopNotification {...props} />;
}

FloatNotification.propTypes = {
  placement: PropTypes.oneOfType([
    PropTypes.oneOf([
      'top-left',
      'top-center',
      'top-right',
      'bottom-left',
      'bottom-center',
      'bottom-right',
    ]),
    PropTypes.object,
  ]),
};

export default Notification;
