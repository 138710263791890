import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import { InputLabel } from '../Typography';

const FlexContainer = styled.div`
  display: flex;
  margin-top: 6px;
  > div {
    padding: 10px 20px;
    font-family: Inter, sans-serif;
    color: black;
    font-size: 13px;
    border: 1px solid #b1b1b1;
    background-color: white;
    border-right: none;
    cursor: pointer;
    input {
      font-size: 13px;
      width: 46px;
      height: 33px;
      border: 1px solid #ededed;
      border-radius: 3px;
      margin: -10px 0;
      padding-left: 7px;
    }
  }
  > div.selected {
    /* IncreaseGreen Medium */
    background: #27ba58;
    /* IncreaseWhite Medium */
    color: #fff;
  }
  > div:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  > div:last-child {
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #b1b1b1;
  }
`;

class SeparatorSelector extends React.Component {
  getOtherSeparatorValue() {
    if (
      this.props.selected === null ||
      this.props.commonSeparators.some(
        (item) => item.value === this.props.selected
      )
    ) {
      return '';
    }
    return this.props.selected;
  }

  otherSeparatorChange(e) {
    this.props.onChange(e.nativeEvent.data);
  }

  render() {
    const otherSeparatorValue = this.getOtherSeparatorValue();
    const { commonSeparators, disabled, selected, onChange, t } = this.props;

    return (
      <div>
        <InputLabel>{t('profile.column_separator')}</InputLabel>
        <FlexContainer>
          {commonSeparators.map((separator, i) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              className={classnames({
                selected: separator.value === selected,
              })}
              key={i}
              onClick={!disabled ? onChange.bind(null, separator.value) : null}
            >
              {separator.name}
            </div>
          ))}
          <div className={classnames({ selected: otherSeparatorValue !== '' })}>
            Otro:{' '}
            <input
              disabled={disabled}
              onChange={this.otherSeparatorChange.bind(this)}
              type="text"
              value={otherSeparatorValue}
            />
          </div>
        </FlexContainer>
      </div>
    );
  }
}

SeparatorSelector.propTypes = {
  commonSeparators: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export default withTranslation()(SeparatorSelector);
