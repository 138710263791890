import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextInput from '../../common/TextInput';
import Select from '../../common/Select';
import LinkButton from '../../common/LinkButton';
import {
  mapDataTypesForSelect,
  mapIncreasecardFieldsForSelect,
} from '../../../helpers/profileHelpers';
import { CellText, InputLabel } from '../../common/Typography';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const FieldName = styled.div`
  width: 218px;
  margin-right: 40px;
`;

const FieldType = styled.div`
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  margin-top: 20px;
  margin-bottom: 6px;
  width: 100%;
`;

const RadioOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  input[type='radio'] {
    margin-right: 1em;
  }
`;

const RadioOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  input {
    margin-right: 10px;
  }
`;

const DeleteFieldButton = styled(LinkButton)`
  color: red;
  margin-top: 36px;
`;

const FileAbstractField = ({
  idx,
  abstractField,
  dataTypes,
  disabled,
  t,
  onNameChange,
  onTypeChange,
}) => {
  const mainDataTypes = ['date', 'integer', 'money', 'string'];
  const otherDataTypes = dataTypes.filter(
    (dataType) => !mainDataTypes.includes(dataType)
  );
  return (
    <React.Fragment>
      <FieldName>
        <StyledInputLabel>{t('profile_creator.fields.name')}</StyledInputLabel>
        <TextInput
          disabled={disabled}
          onChange={(value) => onNameChange(value, idx)}
          value={abstractField.name}
        />
      </FieldName>
      <FieldType>
        <StyledInputLabel>{t('profile_creator.fields.type')}</StyledInputLabel>
        <RadioOptions>
          {mainDataTypes.map((mainType, i) => {
            return (
              <RadioOptionWrapper key={i}>
                <input
                  checked={abstractField.dataType === mainType}
                  disabled={disabled}
                  onChange={(e) =>
                    onTypeChange(e.target.checked, e.target.value, idx)
                  }
                  type="radio"
                  value={mainType}
                />
                <CellText>{t(`constants:data_types.${mainType}`)}</CellText>
              </RadioOptionWrapper>
            );
          })}
          <FieldName>
            <SelectWrapper>
              <input
                checked={otherDataTypes.includes(abstractField.dataType)}
                disabled={disabled}
                key={idx}
                readOnly={true}
                type="radio"
                value={'other'}
              />
              <Select
                disabled={disabled}
                onSelect={(value) =>
                  onTypeChange(otherDataTypes.includes(value), value, idx)
                }
                options={mapDataTypesForSelect(otherDataTypes)}
                placeholder={t('constants:other_types')}
                selected={abstractField.dataType}
                width="200px"
              />
            </SelectWrapper>
          </FieldName>
        </RadioOptions>
      </FieldType>
    </React.Fragment>
  );
};

class AbstractField extends React.Component {
  onNameChange(value, idx) {
    this.props.onChange(idx, 'name', value);
  }

  onTypeChange(selected, value, idx) {
    if (selected) {
      this.props.onChange(idx, 'dataType', value);
    }
  }

  onCardChange(value, idx) {
    this.props.onCardLocationChange(idx, value);
  }

  onSelectCardChange(idx, value) {
    const cardType = this.props.dataTypes.find((dataType) => {
      return dataType.location === value;
    });

    this.onNameChange(cardType.location, idx);
    this.onTypeChange(true, cardType.dataType, idx);
    this.onCardChange(cardType.location, idx);
  }

  onDelete(idx) {
    this.props.onDeleteField(idx);
  }

  render() {
    const { t, abstractField, idx, disabled, dataTypes, vsCard } = this.props;
    return (
      <StyledDiv>
        {vsCard && (
          <FieldName>
            <StyledInputLabel>
              {t('profile_creator.fields.name')}
            </StyledInputLabel>
            <Select
              disabled={disabled}
              onSelect={this.onSelectCardChange.bind(this, idx)}
              options={mapIncreasecardFieldsForSelect(dataTypes)}
              placeholder={t('fields.select')}
              selected={abstractField.name}
              width="234px"
            />
          </FieldName>
        )}
        {!vsCard && (
          <FileAbstractField
            abstractField={abstractField}
            dataTypes={dataTypes}
            disabled={disabled}
            idx={idx}
            onNameChange={this.onNameChange.bind(this)}
            onTypeChange={this.onTypeChange.bind(this)}
            t={t}
          />
        )}
        {!disabled && (
          <DeleteFieldButton onClick={this.onDelete.bind(this, idx)}>
            {t('fields.delete_field')}
          </DeleteFieldButton>
        )}
      </StyledDiv>
    );
  }
}
export default withTranslation()(AbstractField);
