const ERROR_MAP = {
  '0':
    'La columna que se encuentra en la posición %{location} de la linea %{line} excede a la cantidad de columnas del archivo.',
  '1':
    'El valor %{value} que se encuentra en la posición %{location} de la línea %{line} es incompatible con la estructura del archivo.',
  '2': 'Error en la estructura del archivo',
  '3':
    'El campo que se encuentra en la posición %{location} de la linea %{line} está vacío.',
  '4':
    'El campo que se encuentra en la posición %{location} de la linea %{line} es inválido',
  '5': 'No se encuentra definido el campo monto.',
};

export function translateError(error) {
  let error_str = ERROR_MAP[error.code];
  if (error_str) {
    const location = isNaN(error.location)
      ? error.location
      : parseInt(error.location) + 1;
    const line = isNaN(error.line) ? error.line : parseInt(error.line) + 1;
    error_str = error_str.replace('%{location}', location);
    error_str = error_str.replace('%{value}', error.value);
    error_str = error_str.replace('%{line}', line);
  } else {
    error_str =
      'Hubo un error. Lo estamos resolviendo. Intentá otra vez en minutos.';
  }
  return error_str;
}
