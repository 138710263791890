import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@increase/typed-components';

export function ConfirmManualMatchModal({ onOk, onCancel, visible }) {
  const { t } = useTranslation();
  return (
    <Modal
      cancelLabel={t('pending_operations.confirm_manual_match_modal.cancel')}
      description={t('pending_operations.confirm_manual_match_modal.body')}
      headerText={t('pending_operations.confirm_manual_match_modal.header')}
      okLabel={t('pending_operations.confirm_manual_match_modal.ok')}
      onCancel={onCancel}
      onClose={onCancel}
      onOk={onOk}
      visible={visible}
      width={560}
    />
  );
}
