import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RadioButton } from '@increase/typed-components';
import TextInput from './TextInput';
import { CellText, InputLabel } from './Typography';

const StyledWrapper = styled.div`
  height: 100%;
  margin-top: 6px;
`;

const TextInputContainer = styled.div`
  margin-bottom: 17px;
  margin-left: 20px;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
`;

const RECONCILIATION_TYPES = ['file', 'movements', 'payments'];

const DataPoolOption = ({ onChange, readOnly, selected, value }) => {
  const { t } = useTranslation();
  return (
    <RadioButtonContainer>
      <RadioButton
        checked={selected === value}
        disabled={readOnly}
        label=""
        name=""
        onChange={(e) => onChange(e.target.checked, value)}
        value={value}
      />
      <CellText>
        {t(`profile_creator.initial_configuration.data_pool_labels.${value}`)}
      </CellText>
    </RadioButtonContainer>
  );
};

DataPoolOption.defaultProps = {
  value: PropTypes.oneOf(RECONCILIATION_TYPES),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  selected: PropTypes.oneOf(RECONCILIATION_TYPES),
};

const DataPoolSelector = ({
  dataPoolName,
  onDataPoolNameChange,
  onChange,
  readOnly,
  usesCard,
  reconciliationType,
}) => {
  const { t } = useTranslation();

  const onSelectionChange = (checked, value) => {
    if (!readOnly && checked) {
      onChange(value);
    }
  };

  const showDataPool = (str) => {
    return !readOnly || reconciliationType === str;
  };

  return (
    <React.Fragment>
      <InputLabel style={{ marginBottom: '6px' }}>
        {t('profile_creator.initial_configuration.data_pool_b_label')}
      </InputLabel>
      {usesCard && (
        <StyledWrapper>
          {showDataPool('file') && (
            <DataPoolOption
              onChange={(checked, value) => onSelectionChange(checked, value)}
              readOnly={readOnly}
              selected={reconciliationType}
              value={'file'}
            />
          )}
          {reconciliationType === 'file' && (
            <TextInputContainer>
              <TextInput
                disabled={readOnly}
                onChange={(value) => onDataPoolNameChange(value)}
                placeholder={t('input_name')}
                value={dataPoolName}
              />
            </TextInputContainer>
          )}
          {showDataPool('movements') && (
            <DataPoolOption
              onChange={(checked, value) => onSelectionChange(checked, value)}
              readOnly={readOnly}
              selected={reconciliationType}
              value={'movements'}
            />
          )}
          {showDataPool('payments') && (
            <DataPoolOption
              onChange={(checked, value) => onSelectionChange(checked, value)}
              readOnly={readOnly}
              selected={reconciliationType}
              value={'payments'}
            />
          )}
        </StyledWrapper>
      )}
      {!usesCard && (
        <TextInputContainer>
          <TextInput
            disabled={readOnly}
            onChange={(value) => onDataPoolNameChange(value)}
            placeholder={t('input_name')}
            value={dataPoolName}
          />
        </TextInputContainer>
      )}
    </React.Fragment>
  );
};

DataPoolSelector.propTypes = {
  dataPoolName: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  reconciliationType: PropTypes.oneOf(RECONCILIATION_TYPES),
};

DataPoolSelector.defaultProps = {
  reconciliationType: 'file',
  readOnly: false,
};

export default withTranslation()(DataPoolSelector);
