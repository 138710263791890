import {
  deleteProfile,
  getProfile,
  getProfiles,
  saveProfile,
  createProfile,
  generateRetry,
  isConciliating,
} from '../services/profilesService';
import {
  setDataPools,
  clearDataPoolState,
  DATA_POOL_KEYS,
} from './dataPoolsReducer';
import { setScannings, clearScanningsState } from './scanningsReducer';
import { clearStructureState } from './structuresReducer';
import { setRecordsData, clearRecordsState } from './recordsReducer';
import { clearFileUpload } from './fileUploaderReducer';
import Profile from '../models/Profile';

const CARD_RECONCILIATION_TYPES = ['movements', 'payments'];

const NEW_PROFILE = {
  name: '',
  reconciliationType: 'file',
  deprecationDays: 90,
  stable: false,
  originalStable: false,
};

const INITIAL_STATE = {
  profiles: [],
  fetching: false,
  vsCard: false,
  isProfileMatching: false,
  profile: newEmptyProfile(),
  showOnboarding: false,
  pageDisabled: true,
  profilesNavShow: true,
};

function newEmptyProfile() {
  return {
    ...NEW_PROFILE,
  };
}

export function fetchProfiles() {
  return (dispatch) => {
    dispatch({ type: 'PROFILES_REQUEST' });
    return getProfiles().then((profiles) => {
      const sortedProfiles = profiles.sort((a, b) =>
        a.originalStable === b.originalStable ? 0 : a.originalStable ? -1 : 1
      );
      dispatch({ type: 'PROFILES_FETCHED', profiles: sortedProfiles });
      dispatch(setShowOnboarding(sortedProfiles));
      dispatch(setPageDisabled(sortedProfiles));
      return sortedProfiles;
    });
  };
}

export function fetchProfile(profileId) {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_REQUEST' });
    return getProfile(profileId).then((profile) => {
      dispatch(setProfile(profile));
      dispatch({ type: 'PROFILE_FETCHED', profile });
      return profile;
    });
  };
}

export function setProfile(profile) {
  return (dispatch) => {
    const stable = profile && profile.stable;
    const vsCard = CARD_RECONCILIATION_TYPES.includes(
      profile.reconciliationType
    );
    dispatch({
      type: 'SET_PROFILE',
      profile,
      profileId: profile.id,
      vsCard,
      stable,
    });
    dispatch(clearFileUpload());
    dispatch(
      setDataPools(
        profile.dataPoolA,
        profile.dataPoolB,
        profile.billingDataPool
      )
    );
    dispatch(setRecordsData(profile));
    dispatch(setScannings(profile.scannings));
    return profile;
  };
}

export function destroyProfile(profileId) {
  return () => {
    return deleteProfile(profileId);
  };
}

export function newProfile() {
  return (dispatch) => {
    dispatch(clearProfileState());
    dispatch(clearDataPoolState());
    dispatch(clearStructureState());
    dispatch(clearScanningsState());
    return dispatch(clearRecordsState());
  };
}

export function cloneProfile(profileId) {
  return (dispatch) => {
    return getProfile(profileId).then((profileToClone) => {
      profileToClone.name = `${profileToClone.name} - cloned`;
      profileToClone.id = null;
      profileToClone.originalStable = false;
      profileToClone.stable = false;

      return dispatch(setProfile(profileToClone));
    });
  };
}

export function generateProfileRetry(profileId) {
  return () => {
    return generateRetry(profileId);
  };
}

export function checkConciliationStatus(profileId) {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_MATCHING_STATUS_FETCHED' });
    return isConciliating(profileId).then((isProfileMatching) => {
      dispatch({ type: 'SET_PROFILE_MATCHING_STATUS', isProfileMatching });
    });
  };
}

export function profileUpdate(field, value) {
  return (dispatch) => {
    const changes = {
      [field]: value,
    };

    if (field === 'reconciliationType') {
      dispatch({
        type: 'UPDATE_RECONCILIATION_TYPE',
        vsCard: CARD_RECONCILIATION_TYPES.includes(value),
      });
      dispatch(clearDataPoolState());
      dispatch(clearStructureState());
      dispatch(clearScanningsState());
      dispatch(clearRecordsState());
      return dispatch({ type: 'PROFILE_UPDATE', changes });
    }
    return dispatch({ type: 'PROFILE_UPDATE', changes });
  };
}

export function newInformativeFields(
  informativeFields,
  fieldName,
  informative
) {
  if (informative) {
    return informativeFields.concat(fieldName);
  }
  return informativeFields.filter(
    (informativeField) => informativeField !== fieldName
  );
}

//NvsM section
function buildProfile({ profiles, dataPools, structures, scannings }) {
  const { profile } = profiles;
  const { structuresA, structuresB } = structures;
  const {
    dataPoolA,
    dataPoolB,
    abstractFields,
    referenceField,
    billingDataPool,
  } = dataPools;
  const profileAttributes = {
    id: profile.id,
    name: profile.name,
    deprecationDays: profile.deprecationDays,
    stable: profile.stable,
    reconciliationType: profile.reconciliationType,
  };
  const buildedDPA = buildDataPool(
    dataPoolA,
    structuresA,
    abstractFields,
    referenceField,
    billingDataPool === DATA_POOL_KEYS.DATA_POOL_A
  );
  const buildedDPB = buildDataPool(
    dataPoolB,
    structuresB,
    abstractFields,
    referenceField,
    billingDataPool === DATA_POOL_KEYS.DATA_POOL_B
  );
  const buildedScannings = buildScannings(
    Object.values(scannings),
    abstractFields
  );
  return {
    ...profileAttributes,
    dataPoolA: buildedDPA,
    dataPoolB: buildedDPB,
    scannings: buildedScannings,
  };
}

function buildDataPool(
  dataPool,
  structures,
  abstractFields,
  referenceField,
  isBillingDataPool
) {
  return {
    ...dataPool,
    structures: buildStructures(structures),
    abstractFields: buildAbstractFields(
      dataPool.abstractFields,
      abstractFields
    ),
    referenceField,
    isBillingDataPool,
  };
}

function buildStructures(structures) {
  return structures.map((structure) => {
    return {
      ...structure,
      fields: Object.values(structure.fields),
      informativeFields: Object.values(structure.informativeFields),
    };
  });
}

function buildAbstractFields(dataPoolFields, abstractFields) {
  if (dataPoolFields) {
    return Object.entries(abstractFields).map(
      ([referenceId, abstractField]) => {
        return Object.assign({}, dataPoolFields[referenceId], abstractField);
      }
    );
  }
  return Object.values(abstractFields);
}

function buildScannings(scannings, abstractFields) {
  return scannings.map((scanning) => {
    return {
      ...scanning,
      scanningFields: buildScanningFields(
        scanning.scanningFields,
        abstractFields
      ),
    };
  });
}

function buildScanningFields(scanningFields, abstractFields) {
  return Object.entries(scanningFields).map(([referenceId, scanningField]) => {
    return Object.assign({}, scanningField, {
      name: abstractFields[referenceId].name,
    });
  });
}

export function profileSave() {
  return (dispatch, getState) => {
    const profile = buildProfile(getState());
    const profileToSave = Profile.toAPI(profile);
    return saveProfile(profileToSave).then((savedProfile) =>
      dispatch(setProfile(savedProfile))
    );
  };
}

export function profileCreate() {
  return (dispatch, getState) => {
    const profile = buildProfile(getState());
    const newProfile = Profile.toAPI(profile);
    return createProfile(newProfile).then((profileCreated) =>
      dispatch(setProfile(profileCreated))
    );
  };
}

export function selectProfile(profileId, refetch = false) {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;
    if (profile && profile.id === profileId && !refetch) {
      return Promise.resolve(profile);
    }
    return dispatch(fetchProfile(profileId));
  };
}

export function clearProfileState() {
  return (dispatch) => {
    return dispatch({
      type: 'CLEAR_PROFILE_STATE',
    });
  };
}

export function toggleSidebarShow() {
  return (dispatch) => {
    return dispatch({
      type: 'TOGGLE_PROFILES_NAV_SHOW',
    });
  };
}

function setPageDisabled(profiles) {
  return (dispatch, getState) => {
    const { masquerading } = getState().auth.user;
    const hasStableProfiles = profiles.filter((p) => p.stable).length > 0;
    return dispatch({
      type: 'SET_PAGE_DISABLED',
      disabledPage: !masquerading && !hasStableProfiles,
    });
  };
}

function setShowOnboarding(profiles) {
  return (dispatch, getState) => {
    const { masquerading } = getState().auth.user;
    const noProfiles = profiles.length === 0;
    return dispatch({
      type: 'SET_SHOW_ONBOARDING',
      showOnboarding: !masquerading && noProfiles,
    });
  };
}

const profilesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.profile,
        profileId: action.profileId,
        vsCard: action.vsCard,
        simulate: !action.stable,
        lockedSimulate: !action.stable,
      };
    case 'UPDATE_RECONCILIATION_TYPE':
      return { ...state, vsCard: action.vsCard };
    case 'PROFILES_REQUEST':
      return { ...state, profiles: [], fetchingProfiles: true };
    case 'PROFILES_FETCHED':
      return { ...state, profiles: action.profiles, fetchingProfiles: false };
    case 'PROFILE_REQUEST':
      return { ...state, fetching: true };
    case 'PROFILE_FETCHED':
      return { ...state, profile: action.profile, fetching: false };
    case 'PROFILE_UPDATE':
      return {
        ...state,
        profile: { ...state.profile, ...action.changes },
      };
    case 'SET_SHOW_ONBOARDING':
      return { ...state, showOnboarding: action.showOnboarding };
    case 'SET_PAGE_DISABLED':
      return { ...state, disabledPage: action.disabledPage };
    case 'TOGGLE_PROFILES_NAV_SHOW':
      return { ...state, profilesNavShow: !state.profilesNavShow };
    case 'SET_PROFILE_MATCHING_STATUS':
      return { ...state, isProfileMatching: action.isProfileMatching };
    case 'CLEAR_PROFILE_STATE':
      return { ...state, profile: newEmptyProfile(), vsCard: false };
    default:
      return state;
  }
};

export default profilesReducer;
