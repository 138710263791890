import cloneDeep from 'lodash/cloneDeep';

const NEW_SCANNING_FIELD = {
  comparationType: '=',
  threshold: 0,
};

const getInitialState = () => {
  return [newEmptyScanning()];
};

export function newEmptyScanning() {
  return {
    id: '',
    number: 1,
    scanningFields: {},
  };
}

export function setScannings() {
  return (dispatch, getState) => {
    const { profile } = getState().profiles;

    return dispatch({
      type: 'SET_SCANNINGS',
      scannings: { ...cloneDeep(orderScannings(profile.scannings)) },
    });
  };
}

export function clearScanningsState() {
  return (dispatch) => {
    return dispatch({ type: 'CLEAR_SCANNINGS_STATE' });
  };
}

function orderScannings(scannings) {
  const ordered = scannings.sort((a, b) => {
    return a.number - b.number;
  });
  return ordered.map((scan, index) => {
    scan.number = index + 1;
    return scan;
  });
}

export function addScanning() {
  return (dispatch, getState) => {
    const scannings = Object.values(getState().scannings);
    const newScanning = newEmptyScanning();
    newScanning.number = nextScanningNumber(scannings);
    scannings.push(newScanning);
    return dispatch({
      type: 'SCANNINGS_UPDATE',
      scannings: scannings,
    });
  };
}

function nextScanningNumber(scannings) {
  return scannings.reduce((a, b) => Math.max(a, b.number), 1) + 1;
}

export function deleteScanning(idx) {
  return (dispatch, getState) => {
    const scannings = Object.values(getState().scannings);
    const newScannings = orderScannings(
      scannings.filter((value, i) => {
        return i !== idx;
      })
    );
    return dispatch({ type: 'SCANNINGS_UPDATE', scannings: newScannings });
  };
}

export function deleteScanningFields(structure_field) {
  return (dispatch, getState) => {
    const scannings = Object.values(getState().scannings);
    const newScannings = scannings.map((scanning) => {
      scanning.scanningFields = scanning.scanningFields.filter(
        (scannField) =>
          scannField.fieldALocation !== structure_field.propsA.location &&
          scannField.fieldBLocation !== structure_field.propsB.location
      );
      return scanning;
    });
    return dispatch({ type: 'SCANNINGS_UPDATE', scannings: newScannings });
  };
}

export function toggleRule(scanningIndex, referenceId, selected) {
  return (dispatch, getState) => {
    const scanning = cloneDeep(getState().scannings[scanningIndex]);
    if (selected) {
      scanning.scanningFields[referenceId] = { ...NEW_SCANNING_FIELD };
    } else {
      delete scanning.scanningFields[referenceId];
    }
    return dispatch({
      type: 'SCANNING_UPDATE',
      scanningIndex,
      scanning,
    });
  };
}

export function changeRule(scanningIndex, referenceId, changes) {
  return (dispatch, getState) => {
    const scanning = getState().scannings[scanningIndex];
    const scanningField = scanning.scanningFields[referenceId];
    const newScanning = {
      ...scanning,
      scanningFields: {
        ...scanning.scanningFields,
        [referenceId]: { ...scanningField, ...changes },
      },
    };
    return dispatch({
      type: 'SCANNING_UPDATE',
      scanningIndex,
      scanning: newScanning,
    });
  };
}

//NvsM section
export function onUpdateAbstractField(reference_id, prop, value) {
  return (dispatch, getState) => {
    const scannings = getState().scannings;
    Object.values(scannings).forEach((scanning) => {
      if (scanning.scanningFields[reference_id]) {
        scanning.scanningFields[reference_id][prop] = value;
      }
    });

    return dispatch({ type: 'SET_SCANNINGS', scannings });
  };
}

export function onDeleteAbstractField(reference_id) {
  return (dispatch, getState) => {
    const scannings = getState().scannings;
    Object.values(scannings).forEach((scanning) => {
      delete scanning.scanningFields[reference_id];
    });
    return dispatch({ type: 'SCANNINGS_UPDATE', scannings });
  };
}

const scanningsReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'SET_SCANNINGS':
      return { ...action.scannings };
    case 'SCANNINGS_UPDATE':
      return { ...action.scannings };
    case 'SCANNING_UPDATE':
      return { ...state, [action.scanningIndex]: action.scanning };
    case 'CLEAR_SCANNINGS_STATE':
      return { ...getInitialState() };
    default:
      return state;
  }
};

export default scanningsReducer;
