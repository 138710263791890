import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { H2, InputLabel, CellText } from '../Typography';
import NumberInput from '../NumberInput';

const InformativeFields = styled.div`
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .table-header {
    height: 36px;
  }
  & .field-row {
    padding: 10px 0;
  }
  & .field-row:first-child {
    padding-top: 0;
  }
  & .field-row:not(:last-child) {
    /* IncreaseWhite Bold */
    border-bottom: 1px solid #b1b1b1;
  }
`;

const InformativeFieldsConfigTable = ({
  informativeFields,
  onInformativeFieldChange,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginLeft: '8rem' }}>
      <H2>{t('informative_columns')}</H2>
      <InformativeFields>
        <div className="table-header">
          <InputLabel>{t('name')}</InputLabel>
          <InputLabel>{t('fields.column')}</InputLabel>
        </div>
        {Object.entries(informativeFields).map(([id, field]) => {
          return (
            <div className="field-row" key={id}>
              <CellText weight={'bold'}>{field.field}</CellText>
              <NumberInput
                min={1}
                onChange={(value) =>
                  onInformativeFieldChange(id, { location: value })
                }
                value={field.location}
              />
            </div>
          );
        })}
      </InformativeFields>
    </div>
  );
};

export default InformativeFieldsConfigTable;
