import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translateError } from '../../errors';
import { useDropzone } from 'react-dropzone';
import { Button } from '@increase/typed-components';
import { Spinner } from '@increase/typed-components';
import { InsertDriveFile } from 'styled-icons/material/InsertDriveFile';
import { Autorenew } from 'styled-icons/material/Autorenew';
import { withTranslation } from 'react-i18next';
import { CellText } from './Typography';
import { InlineNotification } from './Notification';
import LinkButton from './LinkButton';

ProfileValidator.propTypes = {
  onValidate: PropTypes.func,
  validationResults: PropTypes.object,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function ProfileValidator({
  onClearValidationState,
  onValidate,
  validationResults = {},
  t,
}) {
  function handleOnDrop(event) {
    const file = event[0];
    onValidate(file);
  }

  function handleReValidate() {
    onValidate(null);
  }

  function isValidating() {
    return validationResults && validationResults.validating;
  }

  function getValidationState() {
    if (isValidating()) {
      return (
        <React.Fragment>
          <Spinner />
          <CellText>{t('profile.verifying_file_structure')}</CellText>
        </React.Fragment>
      );
    } else if (validationResults.filename)
      return (
        <React.Fragment>
          <InsertDriveFile size={24} />
          <CellText>{validationResults.filename}</CellText>
        </React.Fragment>
      );
    return null;
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleOnDrop,
    multiple: false,
    accept: ['.csv', '.txt', '.xls', '.xlsx'],
  });
  const validationStatus =
    (validationResults.valid && 'success') ||
    (validationResults.error && 'error');

  return (
    <div>
      <InlineNotification
        message={
          validationStatus === 'error'
            ? translateError(validationResults.error)
            : t('profile.valid_structure')
        }
        onClose={onClearValidationState}
        outline={true}
        type={validationStatus}
        visible={!!validationStatus}
      />
      <Wrapper>
        <div {...getRootProps({ disabled: isValidating() })}>
          <input {...getInputProps({ disabled: isValidating() })} />
          <Button>{t('profile.validate_configuration')}</Button>
        </div>
        {validationResults.filename && (
          <LinkButton disabled={isValidating()} onClick={handleReValidate}>
            <Autorenew size={24} />
            {t('profile.validate_again')}
          </LinkButton>
        )}
      </Wrapper>
      <div
        style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}
      >
        {getValidationState()}
      </div>
    </div>
  );
}

export default withTranslation()(ProfileValidator);
