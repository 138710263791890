import React from 'react';
import { Spinner } from '@increase/typed-components';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

function FullPageSpinner() {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
}

export default FullPageSpinner;
