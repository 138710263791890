import { CaretDown } from 'styled-icons/fa-solid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Popover from 'react-popover';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Button } from '@increase/typed-components';
import ComponentLabel from './ComponentLabel';
import TextInput from './TextInput';
import { withTranslation } from 'react-i18next';
import RangeFilter from './RangeFilter';

const StyledPopOverBody = styled(Flex)`
  & > div {
    padding: 12px;
  }
  span {
    font-family: Inter, sans-serif;
    color: #243568;
    font-size: 13px;
  }
  .separator {
    width: 100%;
    height: 1px;
    background-color: #f1f1f1;
    margin: 0;
    padding: 0;
  }
  .buttons {
    div {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;

const TYPES = ['text', 'range'];

class FilterPopOverBody extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  onInputChange(value) {
    this.setState({ value });
  }

  getSearchWidget() {
    const { type } = this.props;
    switch (type) {
      case 'range':
        return (
          <RangeFilter
            onChange={this.onInputChange.bind(this)}
            value={this.state.value}
          />
        );
      default:
        return (
          <TextInput
            onChange={this.onInputChange.bind(this)}
            value={this.state.value}
          />
        );
    }
  }

  render() {
    const { onSave, onClear, t } = this.props;
    return (
      <StyledPopOverBody flexDirection={'column'}>
        <ComponentLabel label={t('payments.filters.search_by')}>
          {this.getSearchWidget()}
        </ComponentLabel>
        <Box className="separator" />
        <Box>
          <Flex className={'buttons'} justifyContent={'space-between'}>
            <Box pr={'3px'}>
              <Button onClick={() => onSave(this.state.value)}>
                {t('payments.apply_filter')}
              </Button>
            </Box>
            <Box pl={'3px'}>
              <Button onClick={onClear}>{t('payments.clean_filter')}</Button>
            </Box>
          </Flex>
        </Box>
      </StyledPopOverBody>
    );
  }
}

FilterPopOverBody.propTypes = {
  onClear: PropTypes.func,
  onSave: PropTypes.func,
  type: PropTypes.oneOf(TYPES),
  value: PropTypes.string,
};

// eslint-disable-next-line no-class-assign
FilterPopOverBody = withTranslation()(FilterPopOverBody);

const PopoverContainer = styled.div`
  ${(props) => props.isDirty && `background-color: #80808057; width: 12%;`}
`;

class FilterTableHead extends React.Component {
  state = { isOpen: false, filterValue: this.props.filterValue };

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.filterValue !== props.filterValue) {
      return {
        filterValue: props.filterValue,
      };
    }
    return null;
  }

  toggle(toState = null) {
    this.setState((prevState) => ({
      isOpen: toState === null ? !prevState.isOpen : toState,
    }));
  }

  cancel() {
    this.toggle(false);
  }

  save(value) {
    this.toggle(false);
    this.props.onFilter(value);
  }

  clear() {
    const { type } = this.props;
    switch (type) {
      case 'range':
        this.props.onFilter({ min: null, max: null });
        break;
      default:
        this.props.onFilter('');
    }
    this.cancel();
  }

  isDirty() {
    const { filterValue } = this.state;
    const { type } = this.props;
    switch (type) {
      case 'range':
        return (
          Boolean(filterValue) &&
          (Boolean(filterValue.min) || Boolean(filterValue.max))
        );
      default:
        return Boolean(filterValue);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <th>
        <Flex alignItems="center">
          <span style={{ marginRight: '0.25rem' }}>{children}</span>
          <PopoverContainer isDirty={this.isDirty()}>
            <Popover
              body={
                <FilterPopOverBody
                  onClear={this.clear.bind(this)}
                  onSave={this.save.bind(this)}
                  type={this.props.type}
                  value={this.state.filterValue}
                />
              }
              enterExitTransitionDurationMs={500}
              isOpen={this.state.isOpen}
              onOuterAction={this.cancel.bind(this)}
              preferPlace={'below'}
            >
              <CaretDown onClick={this.toggle.bind(this, null)} />
            </Popover>
          </PopoverContainer>
        </Flex>
      </th>
    );
  }
}

FilterTableHead.propTypes = {
  children: PropTypes.node.isRequired,
  filterValue: PropTypes.string,
  onFilter: PropTypes.func,
  type: PropTypes.string,
};

FilterTableHead.defaultProps = {
  type: 'text',
};

export default FilterTableHead;
