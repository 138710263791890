import styled from 'styled-components';
import Table from './Table';

const StyledTableSelector = styled(Table)`
  tr svg {
    width: 15px;
    height: 15px;
  }
  tr.conciliated {
    color: #00d246;
    svg {
      display: none;
    }
  }
  tr.selected td {
    background-color: ${({ theme }) => theme.colorsNew.match.light};
  }
  tr.disabled td {
    opacity: 0.4;
    text-decoration: line-through;
  }
`;

export { StyledTableSelector };
