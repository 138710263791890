import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TotalBar = styled.div`
  height: 8px;
  border: 0.5px solid rgba(24, 32, 38, 0.16);
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-sizing: unset;
  padding: 0.5px;
`;

const Progress = styled.div`
  transition: width 500ms ease-in;
  height: 7px;
  border: 1px solid white;
  border-radius: 3.5px;
  width: ${(props) => `${props.progress}%`};
  background-color: #4de07e;
`;

function ProgressBar({ progress }) {
  return (
    <TotalBar>
      <Progress align="left" progress={progress} />
    </TotalBar>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
