import styled from 'styled-components';

const LinkButton = styled.button`
  background: none;
  border: none;
  font-weight: normal;
  letter-spacing: -0.003em;
  text-decoration-line: underline;
  font-feature-settings: 'tnum' on, 'lnum' on;
  font-size: 0.815rem;
  color: ${({ type }) => (type === 'alert' ? '#e71321' : '#0F6BFF')};
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  :disabled {
    color: #a3a3a3;
  }
`;

export default LinkButton;
