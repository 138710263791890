import React from 'react';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import { Pagination, Button } from '@increase/typed-components';
import PendingRecordsTable from './PendingRecordsTable';
import { DATA_POOL_KEYS } from '../../../reducers/dataPoolsReducer';

const DualTableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin: 1rem 0;
  overflow: hidden;
  flex-direction: ${(props) => (props.horizontal ? 'column' : 'row')};
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.horizontal ? '100%' : 'calc(50% - 0.5rem)')};
  height: ${(props) => (props.horizontal ? '50%' : 'auto')};
  background: #fff;
  padding: 0.65rem 1rem;
  border: 1px solid #d8d8d8;
  border-radius: 9px;
  &:first-of-type {
    margin-right: ${(props) => (props.horizontal ? '0' : '0.5rem')};
    margin-bottom: ${(props) => (props.horizontal ? '0.5rem' : '0')};
  }
  &:last-of-type {
    margin-left: ${(props) => (props.horizontal ? '0' : '0.5rem')};
    margin-top: ${(props) => (props.horizontal ? '0.5rem' : '0')};
  }
`;

const TableHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pool-name {
    font-size: 1.25rem;
    font-weight: 700;
    color: initial;
  }
  .selected-lines {
    font-size: 0.75rem;
    font-weight: 700;
    margin: 0;
  }
`;

const TableWrapperScroll = styled.div`
  flex: 1;
  overflow: auto;
`;

export const PendingRecordsTables = (props) => {
  const {
    disableReportGeneration,
    reconciliationType,
    columnsA,
    columnsB,
    fetchingA,
    fetchingB,
    filtersA,
    filtersB,
    horizontal,
    matchedItemsA,
    matchedItemsB,
    onFilterChangeA,
    onFilterChangeB,
    onPageASelect,
    onPageBSelect,
    onSelectChangeA,
    onSelectChangeB,
    onGenerateReport,
    pageA,
    pageB,
    pagesA,
    pagesB,
    rowsA,
    rowsB,
    selectedA,
    selectedB,
    vsCard,
    generalFilters,
    fetching,
    t,
  } = props;
  const dataPoolAName = props.dataPoolAName || t('data_pool_a');
  const dataPoolBName = vsCard
    ? t(
        `profile_creator.initial_configuration.data_pool_labels.${reconciliationType}`
      )
    : props.dataPoolBName;

  return (
    <DualTableContainer horizontal={horizontal}>
      <TableWrapper horizontal={horizontal}>
        <TableHeader>
          <h3 className="pool-name">{dataPoolAName || t('data_pool_a')}</h3>
          <p className="selected-lines">
            {t('pending_operations.selected_lines')} {selectedA.length}
          </p>
        </TableHeader>
        <TableWrapperScroll>
          <PendingRecordsTable
            data={rowsA}
            filters={filtersA}
            headers={columnsA}
            loading={fetching || fetchingA}
            matchedItems={matchedItemsA}
            onFilterChange={onFilterChangeA}
            onSelectChange={onSelectChangeA}
            selectedItems={selectedA}
            showConciliatedDateColumn={generalFilters.conciliated}
            tableType={'file'}
            vsCard={vsCard}
          />
        </TableWrapperScroll>
        <Flex alignItems="center" justifyContent="space-between" mt={'0.65rem'}>
          <Button
            buttonType="outline"
            disabled={disableReportGeneration || rowsA.length === 0}
            onClick={() => onGenerateReport(DATA_POOL_KEYS.DATA_POOL_A)}
            size="small"
          >
            {t('pending_operations.generate_report')}
          </Button>
          <Pagination
            currentPage={pageA}
            onSelectPage={onPageASelect}
            totalPages={pagesA}
          />
        </Flex>
      </TableWrapper>
      <TableWrapper horizontal={horizontal}>
        <TableHeader>
          <h3 className="pool-name">{dataPoolBName || t('data_pool_b')}</h3>
          <p className="selected-lines">
            {t('pending_operations.selected_lines')} {selectedB.length}
          </p>
        </TableHeader>
        <TableWrapperScroll>
          <PendingRecordsTable
            data={rowsB}
            filters={filtersB}
            headers={columnsB}
            loading={fetching || fetchingB}
            matchedItems={matchedItemsB}
            onFilterChange={onFilterChangeB}
            onSelectChange={onSelectChangeB}
            selectedItems={selectedB}
            showConciliatedDateColumn={generalFilters.conciliated}
            tableType={reconciliationType}
            vsCard={vsCard}
          />
        </TableWrapperScroll>
        <Flex alignItems="center" justifyContent="space-between" mt="0.65rem">
          <Button
            buttonType="outline"
            disabled={disableReportGeneration || rowsB.length === 0}
            onClick={() => onGenerateReport(DATA_POOL_KEYS.DATA_POOL_B)}
            size="small"
          >
            {t('pending_operations.generate_report')}
          </Button>
          <Pagination
            currentPage={pageB}
            onSelectPage={onPageBSelect}
            totalPages={pagesB}
          />
        </Flex>
      </TableWrapper>
    </DualTableContainer>
  );
};
