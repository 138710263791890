import styled from 'styled-components';

const IconButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 4px;
  cursor: pointer;
  opacity: 1;
  & svg {
    color: ${({ color }) => color || '#44494D'};
  }
  :disabled {
    cursor: unset;
    opacity: 0.36;
  }
  :active,
  :focus {
    outline: none;
  }
  &:hover svg {
    /* Sky */
    ${({ disabled }) => !disabled && 'color: #0f6bff;'}
  }
`;

export default IconButton;
