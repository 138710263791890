import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from '../../common/Table';
import { FileDownload } from 'styled-icons/material/FileDownload';
import { Search } from 'styled-icons/material/Search';
import { History } from 'styled-icons/material/History';
import { Spinner } from '@increase/typed-components';

import IconButton from '../../common/IconButton';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Report from '../../../models/Report';
import PopOver from '../../common/PopOver';
import { ErrorOutline } from 'styled-icons/material/ErrorOutline';
import { InputLabel } from '../../common/Typography';
import { translateError } from '../../../errors';

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LastDownload = styled.span`
  white-space: normal;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* IncreaseBlack */
  color: #182026;
`;

function StatusIcon({ report, onDetailClicked }) {
  switch (report.status) {
    case Report.STATUS.PENDING:
    case Report.STATUS.RECONCILING:
      return (
        <div style={{ padding: '4px', color: '#00D246' }}>
          <History size={24} />
        </div>
      );
    case Report.STATUS.CONCILIATED:
      return (
        <IconButton
          disabled={
            report.reconciliationType ===
            Report.RECONCILIATION_TYPES.MANUAL_CONCILIATED
          }
          onClick={onDetailClicked.bind(null, report)}
        >
          <Search size={24} />
        </IconButton>
      );
    case Report.STATUS.FAILED:
      return (
        <div style={{ padding: '4px', color: '#e71321' }}>
          <ReactTooltip effect="solid" place="left" />
          <ErrorOutline data-tip={report.error} size={24} />
        </div>
      );
    default:
      throw new Error(`Unknown report status: ${report.status}`);
  }
}
const SubTable = styled(Table)`
  background-color: transparent;
  color: #44494d;
  thead {
    border-bottom: 3px solid #44494d;
  }
  table-layout: fixed;
  & .subtable-filename {
    width: 40%;
  }
  & .subtable-datapool {
    width: 15%;
  }
  & .subtable-lastdownload {
    width: 25%;
  }
  & .subtable-actions {
    width: 20%;
    text-align: right;
  }
`;
const ErrorsTable = ({ filesErrors, t }) => {
  return (
    <SubTable>
      <thead>
        <tr>
          <th>{t('filename')}</th>
          <th>{t('profile.file_structure_short')}</th>
          <th>{t('description')}</th>
        </tr>
      </thead>
      <tbody>
        {filesErrors.map((fileError) => {
          return (
            <tr key={fileError.id}>
              <td>{fileError.name}</td>
              <td>{fileError.structureName}</td>
              <td>{translateError(fileError.error)}</td>
            </tr>
          );
        })}
      </tbody>
    </SubTable>
  );
};
const ExpandableRow = styled.tr`
  .expandable-content {
    padding: 0 9px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;
  }
  .expandable-content.expanded {
    max-height: 400px;
  }
  & > td {
    padding: 0;
  }
  &&&:hover {
    background-color: unset;
  }
`;
function ReportsTableRow({
  report,
  onDetailClicked,
  showAdvOptions,
  onDownloadClicked,
  onDeleteConciliation,
  disableExtraActions,
}) {
  const { t } = useTranslation();
  const [isExpanded, setExpand] = useState(false);
  const typeMessage = report.toDelete
    ? t('deleting')
    : report.deleted
    ? t('deleted')
    : t(report.reconciliationType);

  function expand() {
    if (
      (!report.deleted && (report.pending() || report.finished())) ||
      (report.failed() && report.filesErrorsList.length > 0)
    )
      setExpand(!isExpanded);
  }

  return (
    <React.Fragment>
      <tr
        className={classnames({
          failed: report.failed(),
          deleting: report.toDelete,
          deleted: report.deleted,
        })}
        onClick={expand}
      >
        <td>{report.creationDate}</td>
        <td>
          {report.name}
          {showAdvOptions && ` - ${report.id}`}
        </td>
        <td>
          <InputLabel
            className={classnames({
              deleting: report.toDelete,
              deleted: report.deleted,
            })}
            weight="bold"
          >
            {typeMessage}
          </InputLabel>
        </td>
        <td className="actions">
          <ActionsContainer alignItems="center" justifyContent="center">
            <StatusIcon onDetailClicked={onDetailClicked} report={report} />
            {showAdvOptions && (
              <PopOver
                disabled={disableExtraActions}
                expandPosition="bottom-left"
                fixParentOverflow={true}
                items={[
                  {
                    name: 'Eliminar conciliación',
                    callback: onDeleteConciliation,
                  },
                ]}
              />
            )}
          </ActionsContainer>
        </td>
      </tr>
      <ExpandableRow expanded={isExpanded}>
        <td colSpan="4">
          <div
            className={classnames({
              'expandable-content': true,
              expanded: isExpanded,
            })}
          >
            {(report.finished() || report.pending()) && (
              <SubTable>
                <colgroup>
                  <col className="subtable-filename" />
                  <col className="subtable-datapool" />
                  <col className="subtable-lastdownload" />
                  <col className="subtable-actions" />
                </colgroup>
                <thead>
                  <tr>
                    <th>{t('filename')}</th>
                    <th>{t('datapool')}</th>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {report.reportFileList.map((reportFile) => {
                    return (
                      <tr key={reportFile.id}>
                        <td>{reportFile.name}</td>
                        <td>{t(reportFile.dataPool)}</td>
                        <td>
                          <LastDownload>{`${t('last_download')}\r\n${
                            reportFile.lastDownloadAt || t('never')
                          }`}</LastDownload>
                        </td>
                        <td>
                          {report.finished() && (
                            <IconButton
                              disabled={!report.finished()}
                              onClick={onDownloadClicked.bind(
                                null,
                                report.id,
                                reportFile.id
                              )}
                            >
                              <FileDownload size={24} />
                            </IconButton>
                          )}
                          {report.pending() && (
                            <div style={{ padding: '5px' }}>
                              <Spinner size={16} />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                  {report.pending() && (
                    <tr>
                      <td colSpan="4">{t('report_pending')}</td>
                    </tr>
                  )}
                </tbody>
              </SubTable>
            )}
            {report.failed() && report.filesErrorsList.length > 0 && (
              <ErrorsTable filesErrors={report.filesErrorsList} t={t} />
            )}
          </div>
        </td>
      </ExpandableRow>
    </React.Fragment>
  );
}

ReportsTableRow.propTypes = {
  onDeleteConciliation: PropTypes.func,
  onDetailClicked: PropTypes.func,
  onDownloadClicked: PropTypes.func,
  report: PropTypes.object,
  showAdvOptions: PropTypes.bool,
};

const CustomTable = styled(Table)`
  table-layout: fixed;
  background-color: none;
  tr:nth-child(2n) {
    background-color: unset;
  }
  > tbody > tr:nth-child(4n-4),
  > tbody > tr:nth-child(4n-5) {
    background-color: #f5f5f5;
  }
  & > tbody > tr {
    cursor: pointer;
  }
  tr.error td {
    /* Alerta */
    color: #e71321;
  }
  & td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .disabled > * {
      opacity: 0.5;
    }
  }
  td.actions {
    overflow: visible;
  }
  & .conciliation-date {
    width: 10%;
  }
  & .conciliation-description {
    width: 65%;
  }
  & .conciliation-type {
    width: 15%;
  }
  & .conciliation-actions {
    width: 10%;
    min-width: 64px;
  }
  & tr.failed .conciliationResults {
    color: green;
  }
  & .deleting,
  & .deleted {
    color: #d8d8d8;
  }
`;

function ConciliationsTable({
  loading,
  data,
  onDownloadClicked,
  onDetailClicked,
  onDeleteConciliation,
  masquerading,
}) {
  const { t } = useTranslation();
  const lastSuccessfulConciliation = data.find((item) =>
    [
      Report.RECONCILIATION_TYPES.RECONCILIATION,
      Report.RECONCILIATION_TYPES.RECONCILIATION_RETRY,
      Report.RECONCILIATION_TYPES.MANUAL_CONCILIATED,
    ].includes(item.reconciliationType)
  );

  return (
    <CustomTable loading={loading}>
      <colgroup>
        <col className="conciliation-date" />
        <col className="conciliation-description" />
        <col className="conciliation-type" />
        <col className="conciliation-actions" />
      </colgroup>
      <thead>
        <tr>
          <th>{t('date')}</th>
          <th>{t('description')}</th>
          <th>{t('process_type')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => {
          return (
            ((!masquerading && !row.masqueraded) || masquerading) && (
              <ReportsTableRow
                disableExtraActions={
                  (lastSuccessfulConciliation &&
                    row.id !== lastSuccessfulConciliation.id &&
                    row.reconciliationType !== 'simulated' &&
                    row.status !== Report.STATUS.FAILED) ||
                  row.deleted ||
                  row.toDelete
                }
                key={i}
                onDeleteConciliation={() => onDeleteConciliation(i)}
                onDetailClicked={onDetailClicked}
                onDownloadClicked={onDownloadClicked}
                report={row}
                showAdvOptions={masquerading}
                t={t}
              />
            )
          );
        })}
      </tbody>
    </CustomTable>
  );
}

ConciliationsTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  masquerading: PropTypes.bool,
  onDeleteConciliation: PropTypes.func,
  onDetailClicked: PropTypes.func,
  onDownloadClicked: PropTypes.func,
};

export default ConciliationsTable;
