import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ScanningField from './ScanningField';
import LinkButton from './LinkButton';
import { H2 } from './Typography';

const ScanningContainer = styled.div`
  width: 470px;
  min-height: 370px;
  /* IncreaseWhite */
  background-color: #fff;
  /* IncreaseWhite Medium */
  border: 1px solid #d8d8d8;
  border-radius: 9px;
  margin-right: 1rem;
  padding: 1rem;
  flex-shrink: 0;
`;

const ScanningHeader = styled(H2)`
  margin-bottom: 0.675rem;
  ${LinkButton} {
    margin-left: 1rem;
  }
`;

class Scanning extends React.Component {
  render() {
    const {
      onDelete,
      comparationTypes,
      editMode,
      onToggleRule,
      onChange,
      fields,
      scanningFields,
      scanningNumber,
      t,
    } = this.props;
    return (
      <ScanningContainer>
        <ScanningHeader>
          {t('profile.scanning.scanning_number')}
          {scanningNumber}
          {editMode && (
            <LinkButton onClick={onDelete} type="alert">
              {t('profile.scanning.delete')}
            </LinkButton>
          )}
        </ScanningHeader>
        {Object.entries(fields).map(([idx, field], i) => (
          <ScanningField
            comparationTypes={comparationTypes}
            editMode={editMode}
            field={field}
            key={i}
            onChange={onChange && onChange.bind(null, idx)}
            onToggle={onToggleRule && onToggleRule.bind(null, idx)}
            scanningField={scanningFields[idx]}
          />
        ))}
      </ScanningContainer>
    );
  }
}

Scanning.propTypes = {
  comparationTypes: PropTypes.array,
  editMode: PropTypes.bool,
  fields: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onToggleRule: PropTypes.func,
  scanningNumber: PropTypes.number,
};

export default Scanning;
